import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Tooltip, Stack, Typography, Link } from '@mui/material';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// routes
import { PATH_AUTH } from '../../routes/paths';
// layouts
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuthContext();

  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Typography variant="h4">Sign in</Typography>

        {/* <Tooltip placement="left" title={method}>
          <Box
            alt={method}
            component="img"
            src={`/assets/icons/auth/ic_${method}.png`}
            sx={{ width: 32, height: 32, position: 'absolute', right: 0 }}
          />
        </Tooltip> */}
      </Stack>

      {/* <Alert severity="info" sx={{ mb: 3 }}>
        Use email : <strong>admin@admin.com</strong> / password :<strong> password</strong>
      </Alert> */}

      <AuthLoginForm />
    </LoginLayout>
  );
}
