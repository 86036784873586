import { useState } from 'react';
import * as Yup from 'yup';
import { Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment, alpha } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../routes/paths';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// components
import Iconify from '../../components/iconify';
import FormProvider, { RHFTextField } from '../../components/hook-form';

// ----------------------------------------------------------------------

export default function AuthLoginForm() {
  const { login } = useAuthContext();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    // email: "admin@beet.com",
    // password: "password",
    // email: "admin@admin.com",
    // password: "password",
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await login(data.email, data.password);
    } catch (error) {
      reset();
      setError('afterSubmit', {
        ...error,
        message: error.message,
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message || errors.afterSubmit.errors}</Alert>
        )}

        <RHFTextField label="Email address" name="email" />

        <RHFTextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          label="Password"
          name="password"
          type={showPassword ? 'text' : 'password'}
        />
      </Stack>

      <Stack alignItems="flex-end" sx={{ my: 2 }}>
        <Link color="inherit" component={RouterLink} to={PATH_AUTH.resetPassword} variant="body2">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        color="inherit"
        fullWidth
        loading={isSubmitSuccessful || isSubmitting}
        size="large"
        sx={{
          bgcolor: 'primary.main',
          color: (theme) => (theme.palette.mode === 'light' ? 'secondary.main' : 'secondary.main'),
          '&:hover': {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.8),
            color: (theme) =>
              theme.palette.mode === 'light'
                ? alpha(theme.palette.secondary.main, 0.8)
                : alpha(theme.palette.secondary.main, 0.8),
          },
        }}
        type="submit"
        variant="contained"
      >
        Login
      </LoadingButton>
    </FormProvider>
  );
}
