import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import { isValidToken } from 'src/auth/utils';
// utils
import axios from '../../../utils/axios';
import localStorageAvailable from '../../../utils/localStorageAvailable';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  consultant: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      service: action.payload.service,
    };
  }
  if (action.type === 'SET_SERVICE') {
    return {
      ...state,
      service: action.payload.service,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const ServiceContext = createContext(null);

// ----------------------------------------------------------------------

ServiceProvider.propTypes = {
  children: PropTypes.node,
};

ServiceProvider.defaultProps = {
  children: null,
};

export function ServiceProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const service = storageAvailable ? localStorage.getItem('services') : '';

      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

      const user = storageAvailable ? localStorage.getItem('user') : '';

      if (accessToken && isValidToken(accessToken) && !user && !service) {
        const response = await axios.get('/services');

        const service = response.data;

        localStorage.setItem('services', JSON.stringify(service));

        dispatch({
          type: 'SET_SERVICE',
          payload: {
            service,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            service: JSON.parse(service),
          },
        });
      }
    } catch (error) {
      dispatch({
        type: 'INITIAL',
        payload: {
          service: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const getServices = useCallback(async () => {
    const response = await axios.get('/services');

    const service = response.data;

    localStorage.setItem('services', JSON.stringify(service));

    dispatch({
      type: 'SET_SERVICE',
      payload: {
        service,
      },
    });
  }, []);

  const getChannelServices = useCallback(async (id) => {
    const response = await axios.get(`/channels/${id}/services`);

    const service_channels = response.data;

    localStorage.setItem('services', JSON.stringify(service_channels));

    dispatch({
      type: 'SET_SERVICE',
      payload: {
        service: service_channels,
      },
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      service: state.service,
      getServices,
      getChannelServices,
    }),
    [state.isInitialized, state.service, getServices, getChannelServices]
  );

  return <ServiceContext.Provider value={memoizedValue}>{children}</ServiceContext.Provider>;
}
