import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Avatar(theme) {
  return {
    MuiAvatar: {
      styleOverrides: {
        root: {
          ...theme.typography.avatar,
          backgroundImage: `url('/assets/images/default-avatar.jpg')`,
        },
        colorDefault: {
          color: theme.palette.common.white,
          backgroundColor: alpha(theme.palette.secondary.main, 0.8),
          backgroundColor: alpha(theme.palette.info.main, 0.1),
          // backgroundColor: theme.palette.grey[600],
        },
      },
    },
    MuiAvatarGroup: {
      defaultProps: {
        max: 4,
      },
      styleOverrides: {
        root: {
          justifyContent: 'flex-end',
        },
        avatar: {
          borderColor: theme.palette.background.paper,
          '&:first-of-type': {
            fontSize: 14,
          },
        },
      },
    },
  };
}
