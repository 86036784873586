import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import { CustomAvatar } from '../../../components/custom-avatar';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 1.5),
  borderBlock: `1px solid ${theme.palette.divider}`,
  // borderRadius: Number(theme.shape.borderRadius) * 1.5,
  // backgroundColor: alpha(theme.palette.grey[500], 0.12),
  // marginTop: '-6px',
  // marginTop: '54px',
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const { user } = useAuthContext();

  return (
    <Link color="inherit" component={RouterLink} to={PATH_DASHBOARD.consultant.profile(user?.id)} underline="none">
      <StyledRoot>
        <CustomAvatar
          alt={user?.first_name}
          name={user?.first_name}
          src={user?.image_url} // Needs to be consultant
        />

        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography noWrap variant="subtitle2" sx={{ color: 'textLight.secondary' }}>
            {user?.first_name} {user?.last_name}
          </Typography>

          <Typography
            noWrap
            sx={{
              // color: 'text.secondary',
              color: 'textLight.secondary',
            }}
            variant="body2"
          >
            {user?.email}
          </Typography>
        </Box>
      </StyledRoot>
    </Link>
  );
}
