import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  tasks: [],
  task: null,
};

const slice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getTasksSuccess(state, action) {
      state.isLoading = false;
      state.tasks = action.payload;
    },

    // GET PRODUCT
    getTaskSuccess(state, action) {
      state.isLoading = false;
      state.task = action.payload;
    },

    detachConsultantFromTaskSuccess(state, action) {
      state.isLoading = false;
      state.task = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getTasks() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/tasks');
      dispatch(slice.actions.getTasksSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getClientTasks(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/clients/${id}/tasks`);
      dispatch(slice.actions.getTasksSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createTask(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.post('/tasks/register', {}, { params: data }).then((response) => {
        dispatch(slice.actions.getTasksSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function updateTask(data, taskId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    console.log(data);

    try {
      return await axios.put(`/tasks/update/${taskId}`, {}, { params: data }).then((response) => {
        dispatch(slice.actions.getTasksSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function updateTasks(batchData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    console.log('updateTasks batchData:', batchData);

    try {
      return await axios.patch(`/tasks`, { params: batchData }).then((response) => {
        dispatch(slice.actions.getTasksSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function addClientToTask(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.post('/tasks/attach/client', {}, { params: data }).then((response) => {
        dispatch(slice.actions.getTasksSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function updateClientTask(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.post('/tasks/attach', {}, { params: data }).then((response) => {
        dispatch(slice.actions.getTasksSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function updateConsultantTask(data, task_id, consultant_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios
        .put(`/tasks/${task_id}/consultant/${consultant_id}/update`, {}, { params: data })
        .then((response) => {
          dispatch(slice.actions.getTasksSuccess(response.data));
          return response;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function addConsultantToTask(data, task_id, consultant_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios
        .post(`/tasks/${task_id}/attach/consultant/${consultant_id}`, {}, { params: data })
        .then((response) => {
          dispatch(slice.actions.getTasksSuccess(response.data));
          return response;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function detachConsultantFromTask(task_id, consultant_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/tasks/${task_id}/detach/consultant/${consultant_id}`);
      if (response.status === 200) {
        dispatch(slice.actions.detachConsultantFromTaskSuccess(response.data.data));
        return response;
      } else {
        dispatch(slice.actions.hasError(new Error('Unexpected status code')));
        return null;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteTask(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.delete(`/tasks/delete/${id}`).then((response) => {
        dispatch(slice.actions.getTaskSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function getConsultantsTasks(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/consultants/${id}/tasks`);
      dispatch(slice.actions.getTasksSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
