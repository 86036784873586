import PropTypes from 'prop-types';
import { m, AnimatePresence } from 'framer-motion';
// @mui
import { Dialog, Box, Paper } from '@mui/material';
//
import { varFade } from './variants';

// ----------------------------------------------------------------------

DialogAnimate.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  sx: PropTypes.instanceOf(Object),
  variants: PropTypes.instanceOf(Object),
};

DialogAnimate.defaultProps = {
  children: null,
  onClose: null,
  open: false,
  sx: {},
  variants: null,
};

export default function DialogAnimate({ open = false, variants, onClose, children, sx, ...other }) {
  return (
    <AnimatePresence>
      {open ? (
        <Dialog
          // eslint-disable-next-line react/no-unstable-nested-components
          PaperComponent={(props) => (
            <Box
              component={m.div}
              {...(variants ||
                varFade({
                  distance: 120,
                  durationIn: 0.32,
                  durationOut: 0.24,
                  easeIn: 'easeInOut',
                }).inUp)}
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box onClick={onClose} sx={{ width: '100%', height: '100%', position: 'fixed' }} />
              <Paper sx={sx} {...props}>
                {props.children}
              </Paper>
            </Box>
          )}
          fullWidth
          maxWidth="xs"
          onClose={onClose}
          open={open}
          {...other}
        >
          {children}
        </Dialog>
      ) : null}
    </AnimatePresence>
  );
}
