import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import { isValidToken } from 'src/auth/utils';
// utils
import axios from '../../../utils/axios';
import localStorageAvailable from '../../../utils/localStorageAvailable';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  channel: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      channel: action.payload.channel,
    };
  }
  if (action.type === 'SET_CHANNEL') {
    return {
      ...state,
      channel: action.payload.channel,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const ChannelContext = createContext(null);

// ----------------------------------------------------------------------

ChannelProvider.propTypes = {
  children: PropTypes.node,
};

ChannelProvider.defaultProps = {
  children: null,
};

export function ChannelProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const channel = storageAvailable ? localStorage.getItem('channel') : '';

      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

      const user = storageAvailable ? localStorage.getItem('user') : '';

      if (accessToken && isValidToken(accessToken) && !user && !channel) {
        const response = await axios.get('/channels');

        const channel = response.data;

        localStorage.setItem('channel', JSON.stringify(channel));

        dispatch({
          type: 'SET_CHANNEL',
          payload: {
            channel,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            channel: JSON.parse(channel),
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          channel: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const getChannels = useCallback(async () => {
    const response = await axios.get('/channels');

    const channel = response.data;

    localStorage.setItem('channel', JSON.stringify(channel));

    dispatch({
      type: 'SET_CHANNEL',
      payload: {
        channel,
      },
    });
  }, []);

  const getChannel = useCallback(async (name) => {
    const response = await axios.get(`/channels/${name}`);

    const channel = response.data;

    localStorage.setItem('channel', JSON.stringify(channel));
    dispatch({
      type: 'SET_CHANNEL',
      payload: {
        channel,
      },
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      channel: state.channel,
      getChannels,
      getChannel,
    }),
    [state.isInitialized, state.channel, getChannels, getChannel]
  );

  return <ChannelContext.Provider value={memoizedValue}>{children}</ChannelContext.Provider>;
}
