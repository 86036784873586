import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  Timelogs: [],
  timelog: null,
};

const slice = createSlice({
  name: 'timelog',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getTimelogsSuccess(state, action) {
      state.isLoading = false;
      state.Timelogs = action.payload;
    },

    getTimelogSuccess(state, action) {
      state.isLoading = false;
      state.timelog = action.payload;
    },

    deleteTimelogSuccess(state, action) {
      state.isLoading = false;
      state.Timelogs = state.Timelogs.filter((log) => log.id !== action.payload);
    },
  },
});

export default slice.reducer;

export function getTimelogs() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/time-logs');
      dispatch(slice.actions.getTimelogsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createTimelog(timelog) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/time-logs/register', {}, { params: timelog });
      dispatch(slice.actions.getTimelogSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateTimelog(timelog) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/time-logs/update/${timelog.id}`, {}, { params: timelog });
      dispatch(slice.actions.getTimelogSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteTimelog(timelogId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/time-logs/delete/${timelogId}`);
      dispatch(slice.actions.deleteTimelogSuccess(timelogId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
