import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import { isValidToken } from 'src/auth/utils';
// utils
import axios from '../../../utils/axios';
import localStorageAvailable from '../../../utils/localStorageAvailable';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  consultant: null,
  client: null,
  clients: [],
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      client: action.payload.client,
    };
  }
  if (action.type === 'SET_CLIENT') {
    return {
      ...state,
      client: action.payload.client,
    };
  }
  if (action.type === 'SET_CLIENTS') {
    return {
      ...state,
      clients: action.payload.client,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const ClientContext = createContext(null);

// ----------------------------------------------------------------------

ClientProvider.propTypes = {
  children: PropTypes.node,
};

ClientProvider.defaultProps = {
  children: null,
};

export function ClientProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const client = storageAvailable ? localStorage.getItem('client') : '';

      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

      const user = storageAvailable ? localStorage.getItem('user') : '';

      if (accessToken && isValidToken(accessToken) && !user && !client) {
        const response = await axios.get('/clients');

        const client = response.data;

        localStorage.setItem('client', JSON.stringify(client));

        dispatch({
          type: 'SET_CLIENT',
          payload: {
            client,
            /* user: {
              photoURL:'https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_default.jpg',
              firstName: 'Dagmawi',
              lastName: 'Yehenew',
              displayName: 'Dagmawi Yehenew',
              role: ['admin','hr','dd'],
              isPublic: true,
              id: '8864c717-587d-472a-929a-8e5f298024da-0',
              phoneNumber: '0736593894',
            }, */
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            client: JSON.parse(client),
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          client: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const getClients = useCallback(async () => {
    const response = await axios.get('/clients');

    const client = response.data;

    localStorage.setItem('client', JSON.stringify(client));

    dispatch({
      type: 'SET_CLIENTS',
      payload: {
        client,
      },
    });
  }, []);

  const getClient = useCallback(async (name) => {
    const response = await axios.get(`/clients/${name}`);

    const client = response.data;

    localStorage.setItem('client', JSON.stringify(client));

    dispatch({
      type: 'SET_CLIENT',
      payload: {
        client,
      },
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      client: state.client,
      getClients,
      getClient,
    }),
    [state.isInitialized, state.client, getClients, getClient]
  );

  return <ClientContext.Provider value={memoizedValue}>{children}</ClientContext.Provider>;
}
