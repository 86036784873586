import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const IconZoomOut = ({ size = 21, color = 'currentColor', style, ...restProps }) => {
  const theme = useTheme();

  const getColor = (colorName) => {
    const colorParts = colorName.split('.');
    let colorValue = theme.palette[colorParts[0]];
    for (let i = 1; i < colorParts.length; i++) {
      colorValue = colorValue[colorParts[i]];
    }
    return colorValue;
  };

  const strokeColor = color === 'currentColor' ? color : getColor(color);

  return (
    <Box sx={style} display="flex">
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 21 21">
        <g
          fill="none"
          fillRule="evenodd"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(3 3)"
        >
          <circle cx="5.5" cy="5.5" r="5" />
          <path d="M7.5 5.5h-4zm7.071 9l-5.45-5.381" />
        </g>
      </svg>
    </Box>
  );
};

export default IconZoomOut;
