import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { BILLING_PERIOD_BREAKPOINT_DAY } from 'src/portal-settings';
import { getNextPeriod, getPreviousPeriod } from 'src/utils/plannedUtils';
import { is } from 'date-fns/locale';
import { useUserHaveRole } from 'src/hooks/useUserHaveRole';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  clients: [],
  client: null,
  clientTeams: [],
  clientAccounts: [],
};

const slice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CANCEL LOADING
    cancelLoading(state) {
      state.isLoading = false;
    },

    // GET Clients
    getClientsSuccess(state, action) {
      state.isLoading = false;
      state.clients = action.payload;
    },

    // GET Client
    getClientSuccess(state, action) {
      state.isLoading = false;
      state.client = action.payload;
    },
    getClientSuccessForm(state, action) {
      state.isLoading = false;
      state.client = action.payload;
    },

    getClientChannelsSuccess(state, action) {
      state.isLoading = false;
      state.client = action.payload;
    },

    getClientTeamsSuccess(state, action) {
      state.isLoading = false;
      state.clientTeams = action.payload;
    },

    getClientInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.client = action.payload;
    },

    getClientAccountsSuccess(state, action) {
      state.isLoading = false;
      state.clientAccounts = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getClients(optionalPeriod, cancelToken) {
  const isPastBillingPeriodBreakpoint = new Date().getDate() > BILLING_PERIOD_BREAKPOINT_DAY;
  const period = !optionalPeriod
    ? null
    : isPastBillingPeriodBreakpoint
    ? getNextPeriod(optionalPeriod)
    : optionalPeriod;

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/clients', {
        params: { invoicing_details: period && 1, period: period ? period : '' },
        cancelToken: cancelToken ? cancelToken.token : null,
      });
      dispatch(slice.actions.getClientsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.cancelLoading());
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}

// ----------------------------------------------------------------------
export function createClient(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.post('/clients/register', {}, { params: data }).then((response) => {
        dispatch(slice.actions.getClientSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function getClient(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/clients/${name}`);

      dispatch(slice.actions.getClientSuccessForm(response.data));
      //return response;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getClientTeam(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/clients/${name}/teams`);

      dispatch(slice.actions.getClientTeamsSuccess(response.data));
      //return response;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateClient(data, name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.put(`/clients/update/${name}`, {}, { params: data }).then((response) => {
        dispatch(slice.actions.getClientSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteClient(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.delete(`/clients/delete/${id}`).then((response) => {
        dispatch(slice.actions.getClientSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function getClientAccounts(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/clients/${id}/accounts`);
      dispatch(slice.actions.getClientAccountsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
