import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  organisations: [],
  organisation: null,
};

const slice = createSlice({
  name: 'organisation',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getOrganisationsSuccess(state, action) {
      state.isLoading = false;
      state.organisations = action.payload;
    },

    // GET PRODUCT
    getOrganisationSuccess(state, action) {
      state.isLoading = false;
      state.organisation = action.payload;
    },

    createOrganisationSuccess(state, action) {
      state.isLoading = false;
      state.organisations = [...state.organisations, action.payload];
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getOrganisations() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/organisations');
      dispatch(slice.actions.getOrganisationsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createOrganisation(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/organisations/register', {}, { params: data });
      dispatch(slice.actions.createOrganisationSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateOrganisation(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put('/organisations', data);
      dispatch(slice.actions.updateOrganisationSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
