import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  budgets: [],
  _spentBudget: [],
  budget: null,
  budgetSummary: null,
  clientBudgetSummary: null,
};

const slice = createSlice({
  name: 'budget',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BUDGETS
    getBudgetsSuccess(state, action) {
      state.isLoading = false;
      state.budgets = action.payload;
    },

    // GET BUDGET
    getBudgetSuccess(state, action) {
      state.isLoading = false;
      state.budget = action.payload;
    },

    // DELETE BUDGET
    deleteBudgetSuccess(state, action) {
      state.isLoading = false;
      state.budget = action.payload;
    },

    // GET SPENT BUDGET
    getSpentBudgetSuccess(state, action) {
      state.isLoading = false;
      state._spentBudget = action.payload;
    },

    // GET RECEIVED BUDGET
    getReceivedBudgetSuccess(state, action) {
      state.isLoading = false;
      state._receivedBudget = action.payload;
    },

    // GET BUDGET SUMMARY
    getBudgetSummarySuccess(state, action) {
      state.isLoading = false;
      state.budgetSummary = action.payload;
    },

    // GET CLIENT BUDGET SUMMARY
    getClientBudgetSummarySuccess(state, action) {
      state.isLoading = false;
      state.clientBudgetSummary = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getBudgets() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/budgets');
      dispatch(slice.actions.getBudgetsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getClientBudgets(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/clients/${id}/client-budgets`);
      dispatch(slice.actions.getBudgetsSuccess(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addClientToBudget(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.post('/client-budgets/register', {}, { params: data }).then((response) => {
        dispatch(slice.actions.getBudgetSuccess(response.data.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function updateClientToBudget(data, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.put(`/client-budgets/update/${id}`, {}, { params: data }).then((response) => {
        dispatch(slice.actions.getBudgetSuccess(response.data.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteClientToBudget(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.delete(`/client-budgets/delete/${id}`).then((response) => {
        dispatch(slice.actions.deleteBudgetSuccess(response.data.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function getClientBudgetsNew(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/clients/${id}/client-budgets`);
      dispatch(slice.actions.getBudgetSuccess(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSpentBudgets(period) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/mediabudgets/spent`, { params: { period } });
      dispatch(slice.actions.getSpentBudgetSuccess(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getReceivedBudgets() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/mediabudgets/received`);
      dispatch(slice.actions.getReceivedBudgetSuccess(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getBudgetSummary() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/mediabudgets/summary`);
      dispatch(slice.actions.getBudgetSummarySuccess(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getClientSpentBudgets(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/mediabudgets/spent/client/${id}`);
      dispatch(slice.actions.getSpentBudgetSuccess(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getClientReceivedBudgets(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/mediabudgets/received/client/${id}`);
      dispatch(slice.actions.getReceivedBudgetSuccess(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getClientBudgetSummary(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/mediabudgets/summary/client/${id}`);
      dispatch(slice.actions.getClientBudgetSummarySuccess(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
