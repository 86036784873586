import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  useScrollTrigger,
  Card,
  Container,
  Box,
  useMediaQuery,
  Collapse,
} from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// config
import { HEADER, NAV } from '../../../config-global';
// components
import Logo from '../../../components/logo';
import Iconify from '../../../components/iconify';
import { useSettingsContext } from '../../../components/settings';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import Slide from '@mui/material/Slide';
import { PeriodPicker } from './PeriodPicker';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useSelector } from 'react-redux';

// UTILITIES -------------------------------------------------------------------
function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

// COMPONENT DEFINITION --------------------------------------------------------
HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
};
Header.propTypes = {
  onOpenNav: PropTypes.func.isRequired,
  isPeriodPickerFullYear: PropTypes.bool,
  isRootlytics: PropTypes.bool,
};

Header.defaultProps = {
  isPeriodPickerFullYear: false,
  isRootlytics: false,
};

export default function Header({ onOpenNav, isPeriodPickerFullYear, isRootlytics }) {
  const theme = useTheme();
  const { themeLayout, themeStretch } = useSettingsContext();
  const { havePeriod } = useSelector((state) => state.period);

  const isNavHorizontal = themeLayout === 'horizontal';
  const isNavMini = themeLayout === 'mini';
  const isDesktop = useResponsive('up', 'lg');
  const isLargeDesktop = useMediaQuery(`(min-width:${theme.breakpoints.values.xl + 64}px)`);
  const isOffset = useOffSetTop(0) && !isNavHorizontal;

  // This trigger will determine if the AppBar should be visible or hidden.
  const trigger = useScrollTrigger({
    threshold: 300,
    disableHysteresis: true,
  });

  const renderContent = (
    <>
      <Container
        maxWidth={isRootlytics ? 'xxl' : themeStretch ? false : 'lg'}
        sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}
      >
        {!isDesktop && (
          <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary', mr: 3 }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>
        )}

        <Collapse in={havePeriod} orientation="horizontal" unmountOnExit>
          <PeriodPicker fullYear={isPeriodPickerFullYear} isRootlytics={isRootlytics} />
        </Collapse>

        <Stack
          alignItems="center"
          direction="row"
          flexGrow={1}
          justifyContent={havePeriod ? 'flex-end' : 'space-between'}
          spacing={{ xs: 1, sm: 2 }}
        >
          {isDesktop && isNavHorizontal ? <Logo sx={{ mr: 2.5 }} /> : null}

          {!isRootlytics && (
            <Box
              sx={{
                position: isLargeDesktop && !isRootlytics && 'absolute',
                left: isRootlytics ? 6 * 8 : isLargeDesktop ? 5 * 8 : 0,
              }}
            >
              <Searchbar />
            </Box>
          )}

          {/* </Stack> */}
          {/* <LanguagePopover /> */}
          {/* <NotificationsPopover /> */}
          {/* <ContactsPopover /> */}

          <Box sx={{ position: isLargeDesktop && 'absolute', right: isLargeDesktop && 5 * 8 }}>
            <AccountPopover />
          </Box>
        </Stack>
      </Container>
    </>
  );

  const appBarStyle = {
    boxShadow: 'none',
    height: HEADER.H_MOBILE,
    zIndex: theme.zIndex.appBar + 1,
    ...bgBlur({
      color: theme.palette.background.default,
    }),
    transition: theme.transitions.create(['height'], {
      duration: theme.transitions.duration.shorter,
    }),
    ...(isDesktop && {
      width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
      height: HEADER.H_DASHBOARD_DESKTOP,
      ...(isOffset && {
        height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
      }),
      ...(isNavHorizontal && {
        width: 1,
        bgcolor: !isRootlytics ? 'background.default' : 'transparent',
        height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
        borderBottom: `dashed 1px ${theme.palette.divider}`,
      }),
      ...(isNavMini && {
        width: `calc(100% - ${isRootlytics ? NAV.W_ROOTLYTICS : NAV.W_DASHBOARD_MINI + 1}px)`,
      }),
      transform: trigger ? 'translateY(-100%)' : 'translateY(0%)',
      transition: theme.transitions.create(['transform', 'height'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
  };

  return (
    <AppBar sx={appBarStyle}>
      <Toolbar
        sx={{
          height: 1,
          // px: { lg: 5 },
          px: { lg: !isRootlytics && 2, md: 0, sm: 0, xs: 0 },
          position: 'relative',
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
