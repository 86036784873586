// ----------------------------------------------------------------------

export default function Tooltip(theme) {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          position: 'relative',
          color: theme.palette.text.primary,
          background: 'none',
          zIndex: 1,
          color: theme.palette.common.white,
          borderRadius: '4px',
          boxShadow: theme.customShadows.tooltip,
          padding: theme.spacing(1.2, 1.5),
          ...theme.typography.tooltip,
          backgroundColor: isLight ? theme.palette.secondary.main : theme.palette.primary.darker,
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
            // backgroundColor: isLight ? theme.palette.grey[300] : theme.palette.primary.darker,
            // backgroundColor: isLight ? theme.palette.secondary.main : theme.palette.primary.darker,
            filter: 'opacity(88%)',
            backdropFilter: 'blur(5px)',
            borderRadius: '4px',
          },
        },
        arrow: {
          // color: isLight ? theme.palette.grey[300] : theme.palette.primary.darker,
          color: isLight ? theme.palette.secondary.main : theme.palette.primary.darker,
          background: 'none',
          filter: 'opacity(88%)',
          backdropFilter: 'blur(5px)',
        },
      },
    },
  };
}
