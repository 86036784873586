import { useContext } from 'react';
import { AuthContext } from './JwtContext';
import jwt_decode from 'jwt-decode';
// import { AuthContext } from './Auth0Context';
// import { AuthContext } from './FirebaseContext';
// import { AuthContext } from './AwsCognitoContext';
// ----------------------------------------------------------------------

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error('useAuthContext context must be use inside AuthProvider');

  // Function to check if token has been expired
  function isTokenExpired(token) {
    if (token) {
      const decodedToken = jwt_decode(token);
      const secretKey = process.env.REACT_APP_API_KEY;

      try {
        // const decoded = jwt.verify(token, secretKey);
      } catch (err) {
        // If token is not valid, return true.
        return true;
      }

      const currentTime = Date.now() / 1000; // Covert time to seconds

      // If token expiry date is less than current time, it has expired.
      return decodedToken.exp < currentTime;
    }
    // If there is no token, it's expired.
    return true;
  }

  // Return authentication context together with the isTokenExpired-function
  return { ...context, isTokenExpired };
};
