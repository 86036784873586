import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Input(theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            '& svg': {
              color: theme.palette.text.disabled,
            },
          },
          '&.MuiInputBase-formControl': {
            '&::before': {
              transition:
                'border-bottom-color 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
            },
            '&::after': {
              transition:
                'border-bottom-color 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
            },
          },
          '&.MuiInputBase-formControl:hover:not(.Mui-disabled, .Mui-error)': {
            '&::before': {
              borderBottomColor: theme.palette.primary.main,
            },
            '&::after': {
              borderBottomColor: theme.palette.primary.main,
            },
          },
          '&.Mui-focused:not(.Mui-disabled, .Mui-error)': {
            '&::before': {
              borderBottomColor: theme.palette.primary.main,
            },
            '&::after': {
              borderBottomColor: theme.palette.primary.main,
            },
          },
          '& .MuiSelect-select.MuiInputBase-input.MuiInput-input:focus': {
            backgroundColor: 'transparent',
          },
        },
        input: {
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled,
          },
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottom: '0.5px solid ' + theme.palette.underline,
          },
          '&:after': {
            borderBottom: '0.5px solid ' + theme.palette.primary.main,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root.Mui-focused': {
            color: theme.palette.text.primary,
          },
        },
      },
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius,
          backgroundColor: alpha(theme.palette.grey[500], 0.08),
          '&:hover': {
            backgroundColor: alpha(theme.palette.grey[500], 0.16),
          },
          '&.Mui-focused': {
            backgroundColor: alpha(theme.palette.grey[500], 0.16),
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
        underline: {
          '&:before, :after': {
            display: 'none',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: alpha(theme.palette.grey[500], 0.32),
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
              borderColor: theme.palette.text.primary,
            },
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.action.disabledBackground,
            },
          },
        },
      },
    },
  };
}
