import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const IconWechatLogoLight = ({ size = 21, color = 'currentColor', style, ...restProps }) => {
  const theme = useTheme();

  const getColor = (colorName) => {
    const colorParts = colorName.split('.');
    let colorValue = theme.palette[colorParts[0]];
    for (let i = 1; i < colorParts.length; i++) {
      colorValue = colorValue[colorParts[i]];
    }
    return colorValue;
  };

  const strokeColor = color === 'currentColor' ? color : getColor(color);

  return (
    <Box sx={style} display="flex">
      <svg width={size} height={size} viewBox="0 0 256 256">
        <path
          d="M160,224a72,72,0,1,1,63.23-37.53l8.53,29.87a6,6,0,0,1-7.42,7.42l-29.87-8.53A71.71,71.71,0,0,1,160,224Z"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
        <path
          d="M92.06,175.89a71.51,71.51,0,0,1-30.53-8.66l-29.87,8.53a6,6,0,0,1-7.42-7.42l8.53-29.87A72,72,0,1,1,163.94,80.11"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
        <circle fill={strokeColor} cx="132" cy="140" r="10" />
        <circle fill={strokeColor} cx="188" cy="140" r="10" />
      </svg>
    </Box>
  );
};

export default IconWechatLogoLight;
