import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Tooltip, Link, ListItemText } from '@mui/material';
// locales
import { useLocales } from '../../../locales';
// auth
import RoleBasedGuard from '../../../auth/RoleBasedGuard';
//
import Iconify from '../../iconify';
//
import { StyledItem, StyledIcon, StyledDotIcon } from './styles';

// ----------------------------------------------------------------------

NavItem.propTypes = {
  active: PropTypes.bool,
  depth: PropTypes.number,
  isExternalLink: PropTypes.bool,
  item: PropTypes.instanceOf(Object),
  open: PropTypes.bool,
};

NavItem.defaultProps = {
  active: false,
  depth: 0,
  isExternalLink: false,
  item: {},
  open: false,
};

export default function NavItem({ item, depth, open, active, isExternalLink, ...other }) {
  const { translate } = useLocales();

  const { title, path, icon, info, children, disabled, caption, roles } = item;

  const subItem = depth !== 1;

  const renderContent = (
    <StyledItem active={active} caption={!!caption} depth={depth} disabled={disabled} {...other}>
      {icon ? <StyledIcon active={active}>{icon}</StyledIcon> : null}

      {subItem ? (
        <StyledIcon active={active}>
          <StyledDotIcon active={active ? subItem : null} />
        </StyledIcon>
      ) : null}

      <ListItemText
        primary={`${translate(title)}`}
        primaryTypographyProps={{
          noWrap: true,
          component: 'span',
          variant: active ? 'subtitle2' : 'body2',
        }}
        secondary={
          caption ? (
            <Tooltip placement="top-start" title={`${translate(caption)}`}>
              <span>{`${translate(caption)}`}</span>
            </Tooltip>
          ) : null
        }
        secondaryTypographyProps={{
          noWrap: true,
          variant: 'caption',
        }}
      />

      {info ? (
        <Box component="span" sx={{ lineHeight: 0 }}>
          {info}
        </Box>
      ) : null}

      {!!children && (
        <Iconify
          icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
          sx={{ ml: 1, flexShrink: 0 }}
          width={16}
        />
      )}
    </StyledItem>
  );

  const renderItem = () => {
    // ExternalLink
    if (isExternalLink)
      return (
        <Link href={path} rel="noopener" target="_blank" underline="none">
          {renderContent}
        </Link>
      );

    // Has child
    if (children) {
      return renderContent;
    }

    // Default
    return (
      <Link component={RouterLink} to={path} underline="none">
        {renderContent}
      </Link>
    );
  };

  return <RoleBasedGuard roles={roles}> {renderItem()} </RoleBasedGuard>;
}
