import { formatDateToYYYYMM01 } from './dateUtils';

// UTILS START -----------------------------------------------------------------// src/utils/plannedUtils.js
export const sumTaskLoggedHours = (timeLogs) => {
  return timeLogs?.reduce((total, log) => total + Number(log.reported_hours), 0);
};

export const sumChannelLoggedHours = (tasks) => {
  return tasks?.reduce((totalHours, task) => {
    const taskHours = sumTaskLoggedHours(task.time_logs);
    return totalHours + taskHours;
  }, 0);
};

export const sumConsultantLoggedHours = (channels) => {
  return channels?.reduce((totalHours, channel) => {
    const channelHours = sumChannelLoggedHours(channel.tasks);
    return totalHours + channelHours;
  }, 0);
};

export const sumChannelBudgetedHours = (channels) => {
  return channels?.reduce((totalHours, channel) => {
    const channelHours = Number(channel.budgeted_hours || channel.channel_hours || 0);
    if (isNaN(channelHours)) {
      console.warn(`Invalid channel hours for channel: ${JSON.stringify(channel)}`);
      return totalHours;
    }
    return totalHours + channelHours;
  }, 0);
};

export const sumChannelBudgetedHoursInternal = (channels) => {
  return channels?.reduce((totalHours, channel) => {
    if (channel.client.default_pricing_model === 4) {
      const channelHours = Number(channel.budgeted_hours || channel.channel_hours || 0);
      if (isNaN(channelHours)) {
        console.warn(`Invalid channel hours for channel: ${JSON.stringify(channel)}`);
        return totalHours;
      }
      return totalHours + channelHours;
    }
    return totalHours;
  }, 0);
};

export const sumConsultantBudgetedHours = (channels) => {
  return channels?.reduce((totalHours, channel) => {
    const channelHours =
      channel.tasks?.reduce((taskTotal, task) => {
        const taskHours = Number(task.budgeted_hours) || 0;
        if (isNaN(taskHours)) {
          console.warn(`Invalid task hours for task: ${JSON.stringify(task)}`);
          return taskTotal;
        }
        return taskTotal + taskHours;
      }, 0) || 0;

    return totalHours + channelHours;
  }, 0);
};

export const sumConsultantBudgetedHoursInternal = (channels) => {
  return channels?.reduce((totalHours, channel) => {
    if (channel.client.default_pricing_model === 4) {
      const channelHours =
        channel.tasks?.reduce((taskTotal, task) => {
          const taskHours = Number(task.budgeted_hours) || 0;
          if (isNaN(taskHours)) {
            console.warn(`Invalid task hours for task: ${JSON.stringify(task)}`);
            return taskTotal;
          }
          console.log(`Processing task: ${JSON.stringify(task)}, Task Hours: ${taskHours}`);
          return taskTotal + taskHours;
        }, 0) || 0;

      console.log(`Processing channel: ${JSON.stringify(channel)}, Channel Task Hours: ${channelHours}`);
      return totalHours + channelHours;
    }
    return totalHours;
  }, 0);
};

/**
 * getPreviousPeriod - Get the previous period relative to a provided period.
 * @param {Date|string} [dateInput] - Optional. The reference date. Can be a Date object or a string.
 * @returns {string} A string representing the first day of the previous month in "YYYY-MM-01" format.
 * @throws {Error} Throws an error if the dateInput is provided but is neither a Date object nor a string.
 */
export function getPreviousPeriod(dateInput) {
  let date;
  if (dateInput) {
    if (typeof dateInput === 'string') {
      // Assuming the input is in YYYY-MM-01 format, or any string format that Date can parse
      date = new Date(dateInput);
    } else if (dateInput instanceof Date) {
      date = new Date(dateInput);
    } else {
      throw new Error('Invalid date input: must be a Date object or a string in YYYY-MM-01 format');
    }
  } else {
    date = new Date(); // No input means use current date
  }

  const previousMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
  return formatDateToYYYYMM01(previousMonth);
}

/**
 * getNextPeriod - Get the next period relative to a provided period.
 * @param {Date|string} [dateInput] - Optional. The reference date. Can be a Date object or a string.
 * @returns {string} A string representing the first day of the next month in "YYYY-MM-01" format.
 * @throws {Error} Throws an error if the dateInput is provided but is neither a Date object nor a string.
 */
export function getNextPeriod(dateInput) {
  let date;
  if (dateInput) {
    if (typeof dateInput === 'string') {
      date = new Date(dateInput);
    } else if (dateInput instanceof Date) {
      date = new Date(dateInput);
    } else {
      throw new Error('Invalid date input: must be a Date object or a string in YYYY-MM-DD format');
    }
  } else {
    date = new Date(); // Use current date if no input provided
  }

  // Adjusting the year and month to get the first day of the next month
  const nextMonth =
    date.getMonth() === 11
      ? new Date(date.getFullYear() + 1, 0, 1)
      : new Date(date.getFullYear(), date.getMonth() + 1, 1);
  return formatDateToYYYYMM01(nextMonth);
}
