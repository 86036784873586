import PropTypes from 'prop-types';
// @mui
import { List, Stack } from '@mui/material';
// locales
import { useLocales } from '../../../locales';
//
import { StyledSubheader } from './styles';
import NavList from './NavList';
import { useAuthContext } from 'src/auth/useAuthContext';
import { NAV } from 'src/config-global';
import { useSettingsContext } from 'src/components/settings';

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  data: PropTypes.instanceOf(Array),
  sx: PropTypes.instanceOf(Object),
};

NavSectionVertical.defaultProps = {
  data: [],
  sx: {},
};

export default function NavSectionVertical({ data, sx, ...other }) {
  const { translate } = useLocales();

  const { themeLayout } = useSettingsContext();

  const size =
    (themeLayout === 'mini' && NAV.W_DASHBOARD_MINI) || (themeLayout === 'vertical' && NAV.W_DASHBOARD) || 128;

  const fixedStyle = {
    // zIndex: 9,
    position: 'fixed',
    width: size,
    bottom: '-4px',
    background: 'linear-gradient(0deg, #672e45de 40%, #672e45d6 70%, transparent)',
    pb: 2,
  };

  return (
    <Stack sx={sx} {...other}>
      {data.map((group) => {
        const key = group.subheader || group.items[0].title;

        return (
          <List
            disablePadding
            key={key}
            sx={{
              px: 2,
              ...(group.fixed ? fixedStyle : {}),
            }}
          >
            {group.subheader && group.items?.length ? (
              <StyledSubheader disableSticky>{`${translate(group.subheader)}`}</StyledSubheader>
            ) : null}

            {group.items.map((list) => (
              <NavList data={list} depth={1} hasChild={!!list.children} key={list.title + list.path} />
            ))}
          </List>
        );
      })}
    </Stack>
  );
}
