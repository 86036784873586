import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  licenses: [],
  license: null,
};

const slice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET licenses
    getLicensesSuccess(state, action) {
      state.isLoading = false;
      state.licenses = action.payload;
    },

    // GET license
    getLicenseSuccess(state, action) {
      state.isLoading = false;
      state.license = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getLicenses() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/licenses');
      dispatch(slice.actions.getLicensesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getLicense(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/licenses/${id}`);
      dispatch(slice.actions.getLicenseSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createLicense(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.post('/licenses/register', {}, { params: data }).then((response) => {
        dispatch(slice.actions.getLicensesSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function updateLicense(data, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.put(`/licenses/update/${id}`, {}, { params: data }).then((response) => {
        dispatch(slice.actions.getLicensesSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteLicense(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.delete(`/licenses/delete/${id}`).then((response) => {
        dispatch(slice.actions.getLicensesSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function getClientLicense(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/clients/${id}/license`);
      dispatch(slice.actions.getLicenseSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addClientToLicenses(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios
        .post(`/licenses/${data.license_id}/attach/client/${data.client_id}`, {}, { params: data })
        .then((response) => {
          dispatch(slice.actions.getLicenseSuccess(response.data));
          return response;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function updateClientToLicenses(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios
        .put(`/licenses/${data.license_id}/client/${data.client_id}/update`, {}, { params: data })
        .then((response) => {
          dispatch(slice.actions.getLicenseSuccess(response.data));
          return response;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function detachClientLicenses(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios
        .post(`/licenses/${data.id}/detach/client/${data.clientId}`, {}, { params: data })
        .then((response) => {
          dispatch(slice.actions.getLicenseSuccess(response.data));
          dispatch(slice.actions.hasError(null));

          return response;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
