export default function Divider(theme) {
  return {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.dividerDark,
          '&::before': {
            borderColor: theme.palette.dividerDark,
          },
          '&::after': {
            borderColor: theme.palette.dividerDark,
          },
        },
      },
    },
  };
}
