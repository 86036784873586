import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const IconBuildingsLight = ({ size = 21, color = 'currentColor', style, ...restProps }) => {
  const theme = useTheme();

  const getColor = (colorName) => {
    const colorParts = colorName.split('.');
    let colorValue = theme.palette[colorParts[0]];
    for (let i = 1; i < colorParts.length; i++) {
      colorValue = colorValue[colorParts[i]];
    }
    return colorValue;
  };

  const strokeColor = color === 'currentColor' ? color : getColor(color);

  return (
    <Box sx={style} display="flex">
      <svg width={size} height={size} viewBox="0 0 256 256">
        <path
          d="M136,216V32a8,8,0,0,0-12.44-6.65l-80,53.33A8,8,0,0,0,40,85.35V216"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
        <path
          d="M136,88h72a8,8,0,0,1,8,8V216"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
        <line
          x1="16"
          y1="216"
          x2="240"
          y2="216"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
        <line
          x1="104"
          y1="112"
          x2="104"
          y2="128"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
        <line
          x1="72"
          y1="112"
          x2="72"
          y2="128"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
        <line
          x1="72"
          y1="168"
          x2="72"
          y2="184"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
        <line
          x1="104"
          y1="168"
          x2="104"
          y2="184"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
      </svg>
    </Box>
  );
};

export default IconBuildingsLight;
