import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import AbsencesReducer from './slices/absence';
import AccountsReducer from './slices/accounts';
import BlogReducer from './slices/blog';
import BudgetsReducer from './slices/budget';
import calendarReducer from './slices/calendar';
import ChannelsReducer from './slices/channel';
import chatReducer from './slices/chat';
import ClientReducer from './slices/clients';
import ContactsReducer from './slices/contacts';
import ConsultantsReducer from './slices/consultant';
import DedicatedReducer from './slices/dedicated';
import FilesReducer from './slices/files';
import InvoiceReducer from './slices/invoice';
import kanbanReducer from './slices/kanban';
import LicenseReducer from './slices/license';
import mailReducer from './slices/mail';
import OrganisationReducer from './slices/organisation';
import PartnerReducer from './slices/partner';
import partnerSupplierNumbersReducer from './slices/partnerSupplierNumbers';
import PeriodReucer from './slices/period';
import productReducer from './slices/product';
import ReportReducer from './slices/report';
import RootlyticsReducer from './slices/rootlytics';
import ServiceReducer from './slices/service';
import TaskReducer from './slices/task';
import TeamsReducer from './slices/team';
import TimeLogReducer from './slices/timeLog';
import TimeLogsReducer from './slices/timeLogs';
import UserReducer from './slices/user';
import UsersReducer from './slices/user';
import NotesReducer from './slices/notes';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  absences: AbsencesReducer,
  accounts: AccountsReducer,
  blog: BlogReducer,
  budget: BudgetsReducer,
  budgetSummary: BudgetsReducer,
  calendar: calendarReducer,
  channel: ChannelsReducer,
  chat: chatReducer,
  client: ClientReducer,
  consultant: ConsultantsReducer,
  contact: ContactsReducer,
  dedicated: DedicatedReducer,
  files: FilesReducer,
  invoice: InvoiceReducer,
  kanban: kanbanReducer,
  license: LicenseReducer,
  mail: mailReducer,
  organisation: OrganisationReducer,
  partner: PartnerReducer,
  partnerSupplierNumbers: partnerSupplierNumbersReducer,
  period: PeriodReucer,
  product: persistReducer(productPersistConfig, productReducer),
  reports: ReportReducer,
  rootlytics: RootlyticsReducer,
  service: ServiceReducer,
  tasks: TaskReducer,
  team: TeamsReducer,
  timeLog: TimeLogReducer,
  timeLogs: TimeLogsReducer,
  user: UserReducer,
  users: UsersReducer,
  notes: NotesReducer,
});

export default rootReducer;
