import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Checkbox, FormLabel, FormGroup, FormControl, FormHelperText, FormControlLabel } from '@mui/material';

// ----------------------------------------------------------------------

RHFCheckbox.propTypes = {
  helperText: PropTypes.node,
  name: PropTypes.string,
};

RHFCheckbox.defaultProps = {
  helperText: '',
  name: '',
};

export function RHFCheckbox({ name, helperText, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <div>
          <FormControlLabel control={<Checkbox {...field} checked={field.value} />} {...other} />

          {!!error || helperText ? (
            <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
          ) : null}
        </div>
      )}
    />
  );
}

// ----------------------------------------------------------------------

RHFMultiCheckbox.propTypes = {
  helperText: PropTypes.node,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  row: PropTypes.bool,
  spacing: PropTypes.number,
};

RHFMultiCheckbox.defaultProps = {
  helperText: '',
  label: '',
  name: '',
  options: [],
  row: false,
  spacing: 0,
};

export function RHFMultiCheckbox({ row, name, label, options, spacing, helperText, ...other }) {
  const { control } = useFormContext();

  const getSelected = (selectedItems, item) =>
    selectedItems.includes(item) ? selectedItems.filter((value) => value !== item) : [...selectedItems, item];

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl component="fieldset" variant="standard">
          {label ? (
            <FormLabel component="legend" sx={{ typography: 'body2' }}>
              {label}
            </FormLabel>
          ) : null}

          <FormGroup
            sx={{
              ...(row && {
                flexDirection: 'row',
              }),
              '& .MuiFormControlLabel-root': {
                '&:not(:last-of-type)': {
                  mb: spacing || 0,
                },
                ...(row && {
                  mr: 0,
                  '&:not(:last-of-type)': {
                    mr: spacing || 2,
                  },
                }),
              },
            }}
          >
            {options.map((option) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.value.includes(option.value)}
                    onChange={() => field.onChange(getSelected(field.value, option.value))}
                  />
                }
                key={option.value}
                label={option.label}
                {...other}
              />
            ))}
          </FormGroup>

          {!!error || helperText ? (
            <FormHelperText error={!!error} sx={{ mx: 0 }}>
              {error ? error?.message : helperText}
            </FormHelperText>
          ) : null}
        </FormControl>
      )}
    />
  );
}
