// ----------------------------------------------------------------------

export default function TabScrollButton(theme) {
  return {
    MuiTabScrollButton: {
      styleOverrides: {
        root: {
          '&.MuiTabs-scrollButtons': {
            width: 'auto',
            '&:first-of-type': {
              paddingRight: '16px',
            },
            '&:last-of-type': {
              paddingLeft: '16px',
            },
          },
          '&.Mui-disabled': {
            opacity: 1,
            color: theme.palette.text.disabled,
          },
        },
      },
    },
  };
}
