import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const IconGraphIncrease = ({ size = 21, color = 'currentColor', style, ...restProps }) => {
  const theme = useTheme();

  const getColor = (colorName) => {
    const colorParts = colorName.split('.');
    let colorValue = theme.palette[colorParts[0]];
    for (let i = 1; i < colorParts.length; i++) {
      colorValue = colorValue[colorParts[i]];
    }
    return colorValue;
  };

  const strokeColor = color === 'currentColor' ? color : getColor(color);

  return (
    <Box sx={style} display="flex">
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 21 21">
        <g fill="none" fillRule="evenodd" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round">
          <path d="M4.5 3.5v11a2 2 0 0 0 2 2h11" />
          <path d="m6.5 12.5l3-3l2 2l5-5" />
          <path d="M16.5 9.5v-3h-3" />
        </g>
      </svg>
    </Box>
  );
};

export default IconGraphIncrease;
