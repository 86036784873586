import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS

const TEXT = {
  primary: '#444444',
  secondary: '#8e8e8e',
  disabled: '#acacac',
};

const TEXT_DARK = {
  primary: '#ffffffe6',
  secondary: '#ffffffb3',
  disabled: '#ffffff66',
};

const GREY = {
  // 0: '#f2f5f5',
  0: '#f6f8f8', // 4%
  // 200: '#e9edef',
  200: '#eef1f2', // 8%
  300: '#dbe0e8',
  400: '#d1d4d9',
  500: '#a6b0b8',
  600: '#808c99',
  700: '#59707d',
  800: '#2e5665',
  900: '#274b59',
};

const GREY_TRANSPARENT = {
  oo2: alpha(GREY[900], 0.02),
  oo3: alpha(GREY[900], 0.03),
  oo4: alpha(GREY[900], 0.04),
  oo6: alpha(GREY[900], 0.06),
  oo8: alpha(GREY[900], 0.08),
  o10: alpha(GREY[900], 0.1),
  o12: alpha(GREY[900], 0.12),
  o14: alpha(GREY[900], 0.14),
  o16: alpha(GREY[900], 0.16),
  o24: alpha(GREY[900], 0.24),
  o32: alpha(GREY[900], 0.32),
  o40: alpha(GREY[900], 0.4),
  o48: alpha(GREY[900], 0.48),
  o56: alpha(GREY[900], 0.56),
  o64: alpha(GREY[900], 0.64),
  o80: alpha(GREY[900], 0.8),
};

const PRIMARY = {
  lighter: '#fdf8ef',
  light: '#f2d198',
  main: '#efc06e',
  dark: '#e2ad60',
  darker: '#724d0c',
  contrastText: '#ffffff',
};

const SECONDARY = {
  lighter: '#fbf5f6',
  light: '#c7839f',
  main: '#672e45',
  dark: '#502b3a',
  darker: '#502b3a',
  contrastText: '#ffffff',
};

const INFO = {
  lighter: '#dbe0e8',
  light: '#a6b0b8',
  main: '#2e5665',
  dark: '#274b59',
  darker: '#274b59',
  contrastText: '#ffffff',
};

const SUCCESS = {
  lighter: '#d6fadc',
  light: '#98d4a4',
  main: '#639d70',
  dark: '#457e53',
  darker: '#265f37',
  contrastText: '#ffffff',
};

const WARNING = {
  lighter: '#f5e3c3',
  light: '#f2d198',
  main: '#efc06e',
  dark: '#e2ad60',
  darker: '#724d0c',
  contrastText: '#724d0c',
};

const ERROR = {
  lighter: '#f6e0e1',
  light: '#e0a4a6',
  main: '#b54b58',
  dark: '#9f3644',
  darker: '#672e45',
  transparent20: '#FF563020',
  transparent40: '#FF563040',
  transparent60: '#FF563060',
  transparent80: '#FF563080',
  contrastText: '#fff',
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  greyTransparent: GREY_TRANSPARENT,
  dividerLight: alpha(GREY[500], 0.1),
  divider: alpha(GREY[500], 0.24),
  dividerDark: alpha(GREY[800], 0.3),
  underline: GREY[500],
  action: {
    active: GREY[600],
    activeNotSelected: alpha(INFO.main, 0.3),
    hover: alpha(GREY[500], 0.08),
    hover2: alpha(GREY[500], 0.16),
    hoverRow: GREY_TRANSPARENT.oo2,
    selected: alpha(GREY[500], 0.16),
    selected2: PRIMARY.dark,
    selectedOpacity: 0.11,
    disabled: alpha(GREY[500], 0.48),
    disabledOnWhite: alpha(GREY[500], 0.24),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default function palette(themeMode) {
  const light = {
    ...COMMON,
    mode: 'light',
    text: TEXT,
    textLight: TEXT_DARK,
    // background: { paper: '#fff', default: alpha(GREY[800], 0.08) , neutral: GREY[200] },
    background: { paper: '#fff', default: GREY[0], neutral: GREY[200] },
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
  };

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: TEXT_DARK,
    textDark: TEXT,
    background: {
      paper: SECONDARY['main'],
      default: SECONDARY['dark'],
      neutral: alpha(SECONDARY['dark'], 0.5),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  };

  return themeMode === 'light' ? light : dark;
}
