/**
 * CustomDivider Component
 *
 * This component extends the MuiDivider from Material-UI. It allows more customizations
 * for contrast, spacing, and orientation.
 *
 * @param {Object} props - The props for the component.
 * @param {'default'|'light'|'strong'|'none'} [props.contrast='default'] - The contrast style for the divider.
 * @param {number} [props.spacing=0] - The spacing around the divider.
 * @param {'horizontal'|'vertical'} [props.orientation='horizontal'] - The orientation of the divider.
 * @param {React.ReactNode} [props.children=null] - Optional children to be rendered inside the divider.
 * @returns {React.ReactNode}
 */

import React from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import { Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    borderColor: theme.palette.divider + '!important',
    borderColor: theme.palette.divider + '!important',
    '&::before': {
      borderColor: theme.palette.divider + '!important',
    },
    '&::after': {
      borderColor: theme.palette.divider + '!important',
    },
  },
  light: {
    borderColor: theme.palette.dividerLight + '!important',
    '&::before': {
      borderColor: theme.palette.dividerLight + '!important',
    },
    '&::after': {
      borderColor: theme.palette.dividerLight + '!important',
    },
  },
  strong: {
    borderColor: theme.palette.dividerDark + '!important',
    '&::before': {
      borderColor: theme.palette.dividerDark + '!important',
    },
    '&::after': {
      borderColor: theme.palette.dividerDark + '!important',
    },
  },
  none: {
    border: 'none!important',
    '&::before': {
      border: 'none!important',
    },
    '&::after': {
      border: 'none!important',
    },
  },
}));

const CustomDivider = ({ contrast, spacing, orientation, children, ...rest }) => {
  const theme = useTheme();
  const classes = useStyles();

  let contrastClass = classes.root; // default to root class
  if (contrast === 'light') contrastClass = classes.light;
  else if (contrast === 'strong') contrastClass = classes.strong;
  else if (contrast === 'none') contrastClass = classes.none;

  const isSpacingNumber = typeof spacing === 'number';
  const baseSpacing = isSpacingNumber ? theme.spacing(spacing) : spacing;

  const spacingStyle =
    orientation === 'vertical'
      ? {
          marginLeft: baseSpacing,
          marginRight: baseSpacing,
        }
      : {
          marginTop: baseSpacing,
          marginBottom: baseSpacing,
        };

  return (
    <>
      {children ? (
        <Divider
          className={clsx({ [contrastClass]: contrastClass })}
          style={{
            width: orientation != 'vertical' && '100%',
            // alignItems: orientation === 'vertical' && 'flex-start',
            ...(spacing && spacingStyle),
          }}
          orientation={orientation}
          {...rest}
        >
          <Typography variant="caption">{children}</Typography>
        </Divider>
      ) : (
        <Divider
          className={clsx({ [contrastClass]: contrastClass })}
          style={{
            width: orientation != 'vertical' && '100%',
            ...(spacing && spacingStyle),
          }}
          orientation={orientation}
          {...rest}
        />
      )}
    </>
  );
};

CustomDivider.propTypes = {
  contrast: PropTypes.oneOf(['light', 'strong', 'none', 'default', '']),
  spacing: PropTypes.number,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  children: PropTypes.node,
};

CustomDivider.defaultProps = {
  contrast: '',
  spacing: 0,
  orientation: 'horizontal',
  children: null,
};

export default CustomDivider;
