import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  dedicatedList: [],
  dedicated: {},
};

const slice = createSlice({
  name: 'dedicated',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getDedicatedListSuccess(state, action) {
      state.isLoading = false;
      state.dedicatedList = action.payload;
    },

    // GET PRODUCT
    getDedicatedSuccess(state, action) {
      state.isLoading = false;
      state.dedicated = action.payload;
    },

    // DELETE DEDICATED
    deleteDedicatedSuccess(state, action) {
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getDedicatedList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/dedicateds');
      dispatch(slice.actions.getDedicatedListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDedicatedById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/dedicateds/${id}`);
      dispatch(slice.actions.getDedicatedSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getClientDedicatedList(client_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/clients/${client_id}/dedicateds`);
      dispatch(slice.actions.getDedicatedListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateDedicated(dedicated, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/dedicateds/update/${id}`, {}, { params: dedicated });
      dispatch(slice.actions.getDedicatedSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createDedicated(dedicated) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/dedicateds/register', {}, { params: dedicated });
      dispatch(slice.actions.getDedicatedSuccess(response.data.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createPlanFromTemplate(clientId, period) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        '/dedicateds/register-new-month/',
        {},
        {
          params: {
            client_id: clientId,
            period: period,
          },
        }
      );
      dispatch(slice.actions.getDedicatedSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createDedicatedTemplate(clientId, templatePeriod, period) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        '/dedicateds/register-new-month/',
        {},
        {
          params: {
            client_id: clientId,
            period_as_default: templatePeriod,
            period: period,
          },
        }
      );
      dispatch(slice.actions.getDedicatedSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function attachConsultantToDedicated(dedicated, consultant_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `/dedicateds/${dedicated.id}/attach/consultant/${consultant_id}`,
        {},
        { params: dedicated }
      );
      dispatch(slice.actions.getDedicatedSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function detachConsultantToDedicated(dedicated, consultant_id) {
  return async (dispatch) => {
    console.log('detachConsultantToDedicated', dedicated, consultant_id);
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/dedicateds/${dedicated.id}/detach/consultant/${consultant_id}`);
      dispatch(slice.actions.getDedicatedSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

/*------------------------------------------------------------------------------*/

export function updateConsultantDedicated(dedicated, consultant_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `/dedicateds/${dedicated.id}/consultant/${consultant_id}/update`,
        {},
        {
          params: {
            budgeted_hours: dedicated.budgeted_hours,
            service_id: dedicated.service_id,
            task_id: dedicated.task_id,
          },
        }
      );
      dispatch(slice.actions.getDedicatedSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error; // Propagate the error to the calling function
    }
  };
}

export function deleteDedicated(dedicatedId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/dedicateds/delete/${dedicatedId}`);
      if (response.status === 204) {
        dispatch(slice.actions.deleteDedicatedSuccess(dedicatedId));
        return response;
      } else {
        dispatch(slice.actions.hasError(new Error('Unexpected status code')));
        return null;
      }
    } catch (error) {
      console.log('Caught an error in deleteDedicated:', error);
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}
