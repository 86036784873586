import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const IconLightbulbOn = ({ size = 21, color = 'currentColor', style, ...restProps }) => {
  const theme = useTheme();

  const getColor = (colorName) => {
    const colorParts = colorName.split('.');
    let colorValue = theme.palette[colorParts[0]];
    for (let i = 1; i < colorParts.length; i++) {
      colorValue = colorValue[colorParts[i]];
    }
    return colorValue;
  };

  const strokeColor = color === 'currentColor' ? color : getColor(color);

  return (
    <Box sx={style} display="flex">
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 21 21">
        <path
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.5 18.5h4M10.5 5a4.5 4.5 0 0 1 2.001 8.532l-.001.968a2 2 0 1 1-4 0v-.968A4.5 4.5 0 0 1 10.5 5zm0-2.5v-1m5 3l1-1m-11 1l-1-1m11 10l1 1m-11-1l-1 1m-1-6h-1m16 0h-1"
        />
      </svg>
    </Box>
  );
};

export default IconLightbulbOn;
