// ----------------------------------------------------------------------

import { alpha } from '@mui/system';

export default function Skeleton(theme) {
  return {
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
      },
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.neutral,
          backgroundColor: alpha(theme.palette.background.paper, 0.9),
          // backgroundColor: theme.palette.background.paper,
        },
      },
    },
  };
}
