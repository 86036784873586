import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Tooltip, Link, ListItemText } from '@mui/material';
// locales
import { useLocales } from '../../../locales';
// auth
import RoleBasedGuard from '../../../auth/RoleBasedGuard';
//
import Iconify from '../../iconify';
import { StyledItem, StyledIcon } from './styles';

// ----------------------------------------------------------------------

const NavItem = forwardRef(({ item, depth, open, active, isExternalLink, ...other }, ref) => {
  const { translate } = useLocales();

  const { title, path, icon, children, disabled, caption, roles } = item;

  const subItem = depth !== 1;

  const renderContent = (
    <StyledItem active={active} depth={depth} disabled={disabled} open={open} ref={ref} {...other}>
      {icon ? <StyledIcon active={active}>{icon}</StyledIcon> : null}

      <ListItemText
        primary={`${translate(title)}`}
        primaryTypographyProps={{
          noWrap: true,
          sx: {
            width: 72,
            fontSize: 10,
            lineHeight: '16px',
            textAlign: 'center',
            ...(active && {
              fontWeight: 'fontWeightMedium',
            }),
            ...(subItem && {
              fontSize: 14,
              width: 'auto',
              textAlign: 'left',
            }),
          },
        }}
      />

      {caption ? (
        <Tooltip arrow placement="right" title={`${translate(caption)}`}>
          <Iconify
            icon="eva:info-outline"
            sx={{
              top: 11,
              left: 6,
              position: 'absolute',
            }}
            width={16}
          />
        </Tooltip>
      ) : null}

      {!!children && (
        <Iconify
          icon="eva:chevron-right-fill"
          sx={{
            top: 11,
            right: 6,
            position: 'absolute',
          }}
          width={16}
        />
      )}
    </StyledItem>
  );

  const renderItem = () => {
    // ExternalLink
    if (isExternalLink)
      return (
        <Link href={path} rel="noopener" target="_blank" underline="none">
          {renderContent}
        </Link>
      );

    // Default
    return (
      <Link component={RouterLink} to={path} underline="none">
        {renderContent}
      </Link>
    );
  };

  return <RoleBasedGuard roles={roles}> {renderItem()} </RoleBasedGuard>;
});

NavItem.propTypes = {
  active: PropTypes.bool,
  depth: PropTypes.number,
  isExternalLink: PropTypes.bool,
  item: PropTypes.instanceOf(Object),
  open: PropTypes.bool,
};

NavItem.defaultProps = {
  active: false,
  depth: 0,
  isExternalLink: false,
  item: {},
  open: false,
};

export default NavItem;
