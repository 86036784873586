import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const IconBehanceLogoLight = ({ size = 21, color = 'currentColor', style, ...restProps }) => {
  const theme = useTheme();

  const getColor = (colorName) => {
    const colorParts = colorName.split('.');
    let colorValue = theme.palette[colorParts[0]];
    for (let i = 1; i < colorParts.length; i++) {
      colorValue = colorValue[colorParts[i]];
    }
    return colorValue;
  };

  const strokeColor = color === 'currentColor' ? color : getColor(color);

  return (
    <Box sx={style} display="flex">
      <svg width={size} height={size} viewBox="0 0 256 256">
        <path
          d="M32,124H94a34,34,0,0,1,0,68H32V64H90a30,30,0,0,1,0,60"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
        <line
          x1="168"
          y1="80"
          x2="232"
          y2="80"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
        <path
          d="M232,176a40,40,0,1,1,8-24H160"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
      </svg>
    </Box>
  );
};

export default IconBehanceLogoLight;
