import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  users: [],
  user: null,
  isVerified: false,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Forgot Password
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // Forgot Password
    getUserSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },

    // Forgot Password
    forgotPassword(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },

    // Verify Code
    verifyCode(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },
  },
});

// Export Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/users');
      dispatch(slice.actions.getUsersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUser(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/users/${id}`);
      dispatch(slice.actions.getUserSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createUser(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.post('/auth/register', {}, { params: data }).then((response) => {
        dispatch(slice.actions.getUserSuccess(response.data));
        dispatch(slice.actions.hasError(null));

        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function updateUser(data, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.put(`/users/update/${id}`, data).then((response) => {
        dispatch(slice.actions.getUserSuccess(response.data));
        dispatch(slice.actions.hasError(null));

        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function forgotPassword(email) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/auth/password', {}, { params: { email } });
      dispatch(slice.actions.forgotPassword(response.data));
    } catch (error) {
      console.log('lol', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function verifyCode(code) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/auth/password/pincode/verifie', {}, { params: { code } });
      dispatch(slice.actions.verifyCode(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function resetPassword({ code, password, password_confirmation }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        '/auth/password/reset',
        {},
        { params: { code, password, password_confirmation } }
      );
      dispatch(slice.actions.verifyCode(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function changePassword(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.post('/users/new_password', {}, { params: data }).then((response) => {
        dispatch(slice.actions.getUserSuccess(response.data));
        dispatch(slice.actions.hasError(null));

        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteUser(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.delete(`/users/delete/${id}`).then((response) => {
        dispatch(slice.actions.getUserSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteUserRole(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.post(`/users/${data.id}/role/delete`, {}, { params: data }).then((response) => {
        dispatch(slice.actions.getUserSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
