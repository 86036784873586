import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const IconCubeFocusLight = ({ size = 21, color = 'currentColor', style, ...restProps }) => {
  const theme = useTheme();

  const getColor = (colorName) => {
    const colorParts = colorName.split('.');
    let colorValue = theme.palette[colorParts[0]];
    for (let i = 1; i < colorParts.length; i++) {
      colorValue = colorValue[colorParts[i]];
    }
    return colorValue;
  };

  const strokeColor = color === 'currentColor' ? color : getColor(color);

  return (
    <Box sx={style} display="flex">
      <svg width={size} height={size} viewBox="0 0 256 256">
        <polyline
          points="72.41 96.57 128 128.67 183.59 96.57"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
        <path
          d="M130.24,64.6l51.33,29.65A5,5,0,0,1,184,98.57v58.86a5,5,0,0,1-2.43,4.32L130.24,191.4a4.48,4.48,0,0,1-4.48,0L74.43,161.75A5,5,0,0,1,72,157.43V98.57a5,5,0,0,1,2.43-4.32L125.76,64.6A4.48,4.48,0,0,1,130.24,64.6Z"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
        <line
          x1="128"
          y1="128.67"
          x2="128"
          y2="192"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
        <polyline
          points="184 48 224 48 224 88"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
        <polyline
          points="72 208 32 208 32 168"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
        <polyline
          points="224 168 224 208 184 208"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
        <polyline
          points="32 88 32 48 72 48"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
      </svg>
    </Box>
  );
};

export default IconCubeFocusLight;
