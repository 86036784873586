import PropTypes from 'prop-types';
// @mui
import { IconButton } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// config
import { NAV } from '../../../config-global';
// components
import Iconify from '../../../components/iconify';
import { useSettingsContext } from '../../../components/settings';
import { IconChevronLeft, IconChevronRight } from 'src/components/icon-components';
import { useLocation } from 'react-router';

// ----------------------------------------------------------------------

NavToggleButton.propTypes = {
  sx: PropTypes.instanceOf(Object),
};

NavToggleButton.defaultProps = {
  sx: {},
};

export default function NavToggleButton({ sx, ...other }) {
  const { themeLayout, onToggleLayout } = useSettingsContext();

  const isDesktop = useResponsive('up', 'lg');

  const location = useLocation();

  if (!isDesktop || location.pathname.includes('rootlytics')) {
    return null;
  }

  return (
    <IconButton
      onClick={onToggleLayout}
      size="small"
      sx={{
        p: 0.5,
        top: 32,
        position: 'fixed',
        left: NAV.W_DASHBOARD - 12,
        bgcolor: 'background.neutral',
        // bgcolor: 'primary.main',
        // color: 'text.primary',
        zIndex: (theme) => theme.zIndex.appBar + 1,
        border: (theme) => `dashed 1px ${theme.palette.divider}`,
        '&:hover': {
          // bgcolor: 'background.default',
          bgcolor: 'primary.main',
          color: 'text.primary',
        },
        ...sx,
      }}
      {...other}
    >
      {themeLayout === 'vertical' ? <IconChevronLeft size={16} /> : <IconChevronRight size={16} />}
      {/* <Iconify
        icon={
          themeLayout === 'vertical'
            ? 'eva:arrow-ios-back-fill'
            : 'eva:arrow-ios-forward-fill'
        }
        width={16}
      /> */}
    </IconButton>
  );
}
