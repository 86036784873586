import { format, getTime, formatDistanceToNow, parseISO } from 'date-fns';
import { enGB, sv } from 'date-fns/locale';

// ----------------------------------------------------------------------

export function fDateDiff(start, end) {
  // To calculate the time difference of two dates
  const day = new Date(end).getTime() - new Date(start).getTime();

  const Difference_In_Days = 1 + day / (1000 * 3600 * 24);

  return Difference_In_Days;
}

export function fDateRange(fromDate, toDate, newFormat) {
  try {
    if (!fromDate || !toDate) return '';

    const parsedFromDate = parseISO(fromDate);
    const parsedToDate = parseISO(toDate);

    const [fromDateYM, toDateYM] = [parsedFromDate, parsedToDate].map((d) => format(d, 'MMM yyyy'));
    const [fromDateDay, toDateDay] = [parsedFromDate, parsedToDate].map((d) => format(d, 'd'));

    const isSameDay = parsedFromDate.getTime() === parsedToDate.getTime();
    const isSameYear = parsedFromDate.getFullYear() === parsedToDate.getFullYear();
    const isSameMonth = fromDateYM === toDateYM;

    return isSameDay
      ? fDate(parsedFromDate, newFormat)
      : isSameMonth
      ? isSameYear
        ? `${fromDateDay} - ${fDate(parsedToDate, newFormat)}`
        : `${fromDateDay} ${fromDateYM} - ${toDateDay} ${toDateYM}`
      : `${fromDateDay} ${fromDateYM} - ${toDateDay} ${toDateYM}`;
  } catch (err) {
    console.error('Error in fFrToDate:', err);
    return '';
  }
}

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateToISO(date, newFormat) {
  const fm = newFormat || 'yyyy-MM-dd';
  return date ? format(new Date(date), fm) : '';
}

export function fMonth(date, newFormat) {
  const fm = newFormat || 'MMM';

  return date ? format(new Date(date), fm) : '';
}

export function fMonthName(date, newFormat) {
  const fm = newFormat || 'MMMM';

  return date ? format(new Date(date), fm) : '';
}

export function fMonthDigits(date, newFormat) {
  const fm = newFormat || 'MM';
  return date ? format(new Date(date), fm) : '';
}

export function fYear(date, newFormat) {
  const fm = newFormat || 'MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDay(date, newFormat) {
  const fm = newFormat || 'dd MMM';

  return date ? format(new Date(date), fm) : '';
}

export function fYearRev(date, newFormat) {
  const fm = newFormat || 'yyyy MMM';

  return date ? format(new Date(date), fm) : '';
}

export function fMonthNameYear(date, newFormat) {
  const fm = newFormat || 'MMMM, yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fsDate(date, newFormat) {
  const fm = newFormat || 'yyyy-MM-dd';

  return date ? format(new Date(date), fm) : '';
}

export function frDate(date, newFormat) {
  const fm = newFormat || 'MM-dd-yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat, locale = enGB) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm, { locale }) : '';
}

export function fDayTime(date, newFormat, locale = enGB) {
  const fm = newFormat || 'dd MMM p';

  return date ? format(new Date(date), fm, { locale }) : '';
}

// EE do, p
export function fFullDateTime(date, newFormat, locale = enGB) {
  const fm = newFormat || 'EEEE do, p';

  return date ? format(new Date(date), fm, { locale }) : '';
}

export function fFullDate(date, newFormat, locale = enGB) {
  const fm = newFormat || 'dd MMMM yyyy';

  return date ? format(new Date(date), fm, { locale }) : '';
}

export function fTimestamp(date, locale = enGB) {
  return date ? getTime(new Date(date), { locale }) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}
