export const CURRENCIES = [
  {
    id: 85,
    currency_code: 'SEK',
    currency_name: 'Swedish Krona',
    currency_symbol: 'kr',
    currency_symbol_align: 'right',
    currency_order: 10,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 31,
    currency_code: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    currency_symbol_align: 'left',
    currency_order: 20,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 97,
    currency_code: 'USD',
    currency_name: 'US Dollar',
    currency_symbol: '$',
    currency_symbol_align: 'left',
    currency_order: 40,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 34,
    currency_code: 'GBP',
    currency_name: 'Pound Sterling',
    currency_symbol: '£',
    currency_symbol_align: 'left',
    currency_order: 50,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 68,
    currency_code: 'NOK',
    currency_name: 'Norwegian Krone',
    currency_symbol: 'kr',
    currency_symbol_align: 'right',
    currency_order: 50,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 28,
    currency_code: 'DKK',
    currency_name: 'Danish Krone',
    currency_symbol: 'kr',
    currency_symbol_align: 'right',
    currency_order: 60,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 1,
    currency_code: 'AFN',
    currency_name: 'Afghani',
    currency_symbol: '؋',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 2,
    currency_code: 'ALL',
    currency_name: 'Lek',
    currency_symbol: 'Lek',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 3,
    currency_code: 'ANG',
    currency_name: 'Netherlands Antillian Guilder',
    currency_symbol: 'ƒ',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 4,
    currency_code: 'ARS',
    currency_name: 'Argentine Peso',
    currency_symbol: '$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 5,
    currency_code: 'AUD',
    currency_name: 'Australian Dollar',
    currency_symbol: '$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 6,
    currency_code: 'AWG',
    currency_name: 'Aruban Guilder',
    currency_symbol: 'ƒ',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 7,
    currency_code: 'AZN',
    currency_name: 'Azerbaijanian Manat',
    currency_symbol: 'ман',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 8,
    currency_code: 'BAM',
    currency_name: 'Convertible Marks',
    currency_symbol: 'KM',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 9,
    currency_code: 'BDT',
    currency_name: 'Bangladeshi Taka',
    currency_symbol: '৳',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 10,
    currency_code: 'BBD',
    currency_name: 'Barbados Dollar',
    currency_symbol: '$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 11,
    currency_code: 'BGN',
    currency_name: 'Bulgarian Lev',
    currency_symbol: 'лв',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 12,
    currency_code: 'BMD',
    currency_name: 'Bermudian Dollar',
    currency_symbol: '$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 13,
    currency_code: 'BND',
    currency_name: 'Brunei Dollar',
    currency_symbol: '$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 14,
    currency_code: 'BOB',
    currency_name: 'BOV Boliviano Mvdol',
    currency_symbol: '$b',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 15,
    currency_code: 'BRL',
    currency_name: 'Brazilian Real',
    currency_symbol: 'R$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 16,
    currency_code: 'BSD',
    currency_name: 'Bahamian Dollar',
    currency_symbol: '$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 17,
    currency_code: 'BWP',
    currency_name: 'Pula',
    currency_symbol: 'P',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 18,
    currency_code: 'BYR',
    currency_name: 'Belarussian Ruble',
    currency_symbol: '₽',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 19,
    currency_code: 'BZD',
    currency_name: 'Belize Dollar',
    currency_symbol: 'BZ$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 20,
    currency_code: 'CAD',
    currency_name: 'Canadian Dollar',
    currency_symbol: '$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 21,
    currency_code: 'CHF',
    currency_name: 'Swiss Franc',
    currency_symbol: 'CHF',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 22,
    currency_code: 'CLP',
    currency_name: 'CLF Chilean Peso Unidades de fomento',
    currency_symbol: '$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 23,
    currency_code: 'CNY',
    currency_name: 'Yuan Renminbi',
    currency_symbol: '¥',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 24,
    currency_code: 'COP',
    currency_name: 'COU Colombian Peso Unidad de Valor Real',
    currency_symbol: '$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 25,
    currency_code: 'CRC',
    currency_name: 'Costa Rican Colon',
    currency_symbol: '₡',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 26,
    currency_code: 'CUP',
    currency_name: 'CUC Cuban Peso Peso Convertible',
    currency_symbol: '₱',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 27,
    currency_code: 'CZK',
    currency_name: 'Czech Koruna',
    currency_symbol: 'Kč',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 29,
    currency_code: 'DOP',
    currency_name: 'Dominican Peso',
    currency_symbol: 'RD$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 30,
    currency_code: 'EGP',
    currency_name: 'Egyptian Pound',
    currency_symbol: '£',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 32,
    currency_code: 'FJD',
    currency_name: 'Fiji Dollar',
    currency_symbol: '$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 33,
    currency_code: 'FKP',
    currency_name: 'Falkland Islands Pound',
    currency_symbol: '£',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 35,
    currency_code: 'GIP',
    currency_name: 'Gibraltar Pound',
    currency_symbol: '£',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 36,
    currency_code: 'GTQ',
    currency_name: 'Quetzal',
    currency_symbol: 'Q',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 37,
    currency_code: 'GYD',
    currency_name: 'Guyana Dollar',
    currency_symbol: '$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 38,
    currency_code: 'HKD',
    currency_name: 'Hong Kong Dollar',
    currency_symbol: '$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 39,
    currency_code: 'HNL',
    currency_name: 'Lempira',
    currency_symbol: 'L',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 40,
    currency_code: 'HRK',
    currency_name: 'Croatian Kuna',
    currency_symbol: 'kn',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 41,
    currency_code: 'HUF',
    currency_name: 'Forint',
    currency_symbol: 'Ft',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 42,
    currency_code: 'IDR',
    currency_name: 'Rupiah',
    currency_symbol: 'Rp',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 43,
    currency_code: 'ILS',
    currency_name: 'New Israeli Sheqel',
    currency_symbol: '₪',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 44,
    currency_code: 'IRR',
    currency_name: 'Iranian Rial',
    currency_symbol: '﷼',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 45,
    currency_code: 'ISK',
    currency_name: 'Iceland Krona',
    currency_symbol: 'kr',
    currency_symbol_align: 'right',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 46,
    currency_code: 'JMD',
    currency_name: 'Jamaican Dollar',
    currency_symbol: 'J$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 47,
    currency_code: 'JPY',
    currency_name: 'Yen',
    currency_symbol: '¥',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 48,
    currency_code: 'KGS',
    currency_name: 'Som',
    currency_symbol: 'лв',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 49,
    currency_code: 'KHR',
    currency_name: 'Riel',
    currency_symbol: '៛',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 50,
    currency_code: 'KPW',
    currency_name: 'North Korean Won',
    currency_symbol: '₩',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 51,
    currency_code: 'KRW',
    currency_name: 'Won',
    currency_symbol: '₩',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 52,
    currency_code: 'KYD',
    currency_name: 'Cayman Islands Dollar',
    currency_symbol: '$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 53,
    currency_code: 'KZT',
    currency_name: 'Tenge',
    currency_symbol: 'лв',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 54,
    currency_code: 'LAK',
    currency_name: 'Kip',
    currency_symbol: '₭',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 55,
    currency_code: 'LBP',
    currency_name: 'Lebanese Pound',
    currency_symbol: '£',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 56,
    currency_code: 'LKR',
    currency_name: 'Sri Lanka Rupee',
    currency_symbol: '₨',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 57,
    currency_code: 'LRD',
    currency_name: 'Liberian Dollar',
    currency_symbol: '$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 58,
    currency_code: 'LTL',
    currency_name: 'Lithuanian Litas',
    currency_symbol: 'Lt',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 59,
    currency_code: 'LVL',
    currency_name: 'Latvian Lats',
    currency_symbol: 'Ls',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 60,
    currency_code: 'MKD',
    currency_name: 'Denar',
    currency_symbol: 'ден',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 61,
    currency_code: 'MNT',
    currency_name: 'Tugrik',
    currency_symbol: '₮',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 62,
    currency_code: 'MUR',
    currency_name: 'Mauritius Rupee',
    currency_symbol: '₨',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 63,
    currency_code: 'MXN',
    currency_name: 'MXV Mexican Peso Mexican Unidad de Inversion (UDI)',
    currency_symbol: '$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 64,
    currency_code: 'MYR',
    currency_name: 'Malaysian Ringgit',
    currency_symbol: 'RM',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 65,
    currency_code: 'MZN',
    currency_name: 'Metical',
    currency_symbol: 'MT',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 66,
    currency_code: 'NGN',
    currency_name: 'Naira',
    currency_symbol: '₦',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 67,
    currency_code: 'NIO',
    currency_name: 'Cordoba Oro',
    currency_symbol: 'C$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 69,
    currency_code: 'NPR',
    currency_name: 'Nepalese Rupee',
    currency_symbol: '₨',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 70,
    currency_code: 'NZD',
    currency_name: 'New Zealand Dollar',
    currency_symbol: '$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 71,
    currency_code: 'OMR',
    currency_name: 'Rial Omani',
    currency_symbol: '﷼',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 72,
    currency_code: 'PAB',
    currency_name: 'USD Balboa US Dollar',
    currency_symbol: 'B/.',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 73,
    currency_code: 'PEN',
    currency_name: 'Nuevo Sol',
    currency_symbol: 'S/.',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 74,
    currency_code: 'PHP',
    currency_name: 'Philippine Peso',
    currency_symbol: 'Php',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 75,
    currency_code: 'PKR',
    currency_name: 'Pakistan Rupee',
    currency_symbol: '₨',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 76,
    currency_code: 'PLN',
    currency_name: 'Zloty',
    currency_symbol: 'zł',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 77,
    currency_code: 'PYG',
    currency_name: 'Guarani',
    currency_symbol: 'Gs',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 78,
    currency_code: 'QAR',
    currency_name: 'Qatari Rial',
    currency_symbol: '﷼',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 79,
    currency_code: 'RON',
    currency_name: 'New Leu',
    currency_symbol: 'lei',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 80,
    currency_code: 'RSD',
    currency_name: 'Serbian Dinar',
    currency_symbol: 'Дин.',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 81,
    currency_code: 'RUB',
    currency_name: 'Russian Ruble',
    currency_symbol: 'руб',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 82,
    currency_code: 'SAR',
    currency_name: 'Saudi Riyal',
    currency_symbol: '﷼',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 83,
    currency_code: 'SBD',
    currency_name: 'Solomon Islands Dollar',
    currency_symbol: '$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 84,
    currency_code: 'SCR',
    currency_name: 'Seychelles Rupee',
    currency_symbol: '₨',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 86,
    currency_code: 'SGD',
    currency_name: 'Singapore Dollar',
    currency_symbol: '$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 87,
    currency_code: 'SHP',
    currency_name: 'Saint Helena Pound',
    currency_symbol: '£',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 88,
    currency_code: 'SOS',
    currency_name: 'Somali Shilling',
    currency_symbol: 'S',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 89,
    currency_code: 'SRD',
    currency_name: 'Surinam Dollar',
    currency_symbol: '$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 90,
    currency_code: 'SVC',
    currency_name: 'USD El Salvador Colon US Dollar',
    currency_symbol: '$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 91,
    currency_code: 'SYP',
    currency_name: 'Syrian Pound',
    currency_symbol: '£',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 92,
    currency_code: 'THB',
    currency_name: 'Baht',
    currency_symbol: '฿',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 93,
    currency_code: 'TRY',
    currency_name: 'Turkish Lira',
    currency_symbol: 'TL',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 94,
    currency_code: 'TTD',
    currency_name: 'Trinidad and Tobago Dollar',
    currency_symbol: 'TT$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 95,
    currency_code: 'TWD',
    currency_name: 'New Taiwan Dollar',
    currency_symbol: 'NT$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 96,
    currency_code: 'UAH',
    currency_name: 'Hryvnia',
    currency_symbol: '₴',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 98,
    currency_code: 'UYU',
    currency_name: 'UYI Uruguay Peso en Unidades Indexadas',
    currency_symbol: '$U',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 99,
    currency_code: 'UZS',
    currency_name: 'Uzbekistan Sum',
    currency_symbol: 'лв',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 100,
    currency_code: 'VEF',
    currency_name: 'Bolivar Fuerte',
    currency_symbol: 'Bs',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 101,
    currency_code: 'VND',
    currency_name: 'Dong',
    currency_symbol: '₫',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 102,
    currency_code: 'XCD',
    currency_name: 'East Caribbean Dollar',
    currency_symbol: '$',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 103,
    currency_code: 'YER',
    currency_name: 'Yemeni Rial',
    currency_symbol: '﷼',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
  {
    id: 104,
    currency_code: 'ZAR',
    currency_name: 'Rand',
    currency_symbol: 'R',
    currency_symbol_align: 'left',
    currency_order: 1000,
    created_at: '2023-10-16 17:49:17',
    updated_at: '2023-10-16 17:49:17',
  },
];
