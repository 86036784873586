// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/beet';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  home: '/',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

// export const PATH_PLATFORM = {
//   root: '/platform',
//   releases: '/platform/releases',
// };

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  app: path(ROOTS_DASHBOARD, '/home'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),

  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  analytics: path(ROOTS_DASHBOARD, '/analytics'),
  banking: path(ROOTS_DASHBOARD, '/banking'),
  file: path(ROOTS_DASHBOARD, '/file'),

  personal: {
    report: path(ROOTS_DASHBOARD, '/report'),
    absences: path(ROOTS_DASHBOARD, '/absences'),
  },

  organization: {
    news: path(ROOTS_DASHBOARD, '/news'),
    client: path(ROOTS_DASHBOARD, '/clients'),
    consultant: path(ROOTS_DASHBOARD, '/consultant'),
    team: path(ROOTS_DASHBOARD, '/team'),
    channel: path(ROOTS_DASHBOARD, '/channel'),
    monthlyReports: path(ROOTS_DASHBOARD, '/monthly-reports'),
  },

  client: {
    root: path(ROOTS_DASHBOARD, '/clients'),
    new: path(ROOTS_DASHBOARD, '/clients/new'),
    list: path(ROOTS_DASHBOARD, '/clients/list'),
    cards: path(ROOTS_DASHBOARD, '/clients/cards'),
    default: (id) => path(ROOTS_DASHBOARD, `/clients/${id}/profile`),
    account: (id) => path(ROOTS_DASHBOARD, `/clients/${id}/account`),
    profile: (id) => path(ROOTS_DASHBOARD, `/clients/${id}/profile`),
    edit: (id) => path(ROOTS_DASHBOARD, `/clients/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/clients/reece-chung/edit'),
  },

  consultant: {
    root: path(ROOTS_DASHBOARD, '/consultant'),
    new: path(ROOTS_DASHBOARD, '/consultant/new'),
    list: path(ROOTS_DASHBOARD, '/consultant/list'),
    cards: path(ROOTS_DASHBOARD, '/consultant/cards'),
    profile: (id) => path(ROOTS_DASHBOARD, `/consultant/${id}/profile`),
    account: (id) => path(ROOTS_DASHBOARD, `/consultant/${id}/account`),
    edit: (id) => path(ROOTS_DASHBOARD, `/consultant/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/consultant/reece-chung/edit'),
  },

  team: {
    root: path(ROOTS_DASHBOARD, '/team'),
    list: path(ROOTS_DASHBOARD, '/team/list'),
    new: path(ROOTS_DASHBOARD, '/team/new'),
    profile: (id) => path(ROOTS_DASHBOARD, `/team/${id}/profile`),
    task: (id) => path(ROOTS_DASHBOARD, `/team/${id}/task`),
    account: (id) => path(ROOTS_DASHBOARD, `/team/${id}/account`),
    client: (id) => path(ROOTS_DASHBOARD, `/team/${id}/clients`),
    edit: (id) => path(ROOTS_DASHBOARD, `/team/${id}/edit`),
    timelog: (id) => path(ROOTS_DASHBOARD, `/team/${id}/timelog`),
  },

  // task: {
  //   root: path(ROOTS_DASHBOARD, '/task'),
  //   list: path(ROOTS_DASHBOARD, '/task/list'),
  //   new: path(ROOTS_DASHBOARD, '/task/new'),
  //   edit: path(ROOTS_DASHBOARD, '/task/edit'),
  // },

  news: {
    root: path(ROOTS_DASHBOARD, '/news'),
    new: path(ROOTS_DASHBOARD, '/news/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/news/post/${id}/edit`),
    view: (id) => path(ROOTS_DASHBOARD, `/news/post/${id}`),
  },

  finances: {
    root: path(ROOTS_DASHBOARD, '/finances'),
  },

  invoice: {
    root: path(ROOTS_DASHBOARD, '/finances/invoice'),
    list: path(ROOTS_DASHBOARD, '/finances/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/finances/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/finances/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/finances/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/finances/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/finances/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },

  rootlytics: {
    root: path(ROOTS_DASHBOARD, '/finances/rootlytics'),
  },

  budget: {
    root: path(ROOTS_DASHBOARD, '/finances/budget'),
    list: path(ROOTS_DASHBOARD, '/finances/budget/list'),
    new: path(ROOTS_DASHBOARD, '/finances/budget/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/finances/budget/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/finances/budget/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/finances/budget/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/finances/budget/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },

  administrate: {
    root: path(ROOTS_DASHBOARD, '/administrate'),

    user: {
      root: path(ROOTS_DASHBOARD, '/administrate/user'),
      new: path(ROOTS_DASHBOARD, '/administrate/user/new'),
      list: path(ROOTS_DASHBOARD, '/administrate/user/list'),
      account: path(ROOTS_DASHBOARD, '/administrate/user/account'),
      edit: (id) => path(ROOTS_DASHBOARD, `/administrate/user/${id}/edit`),
    },

    partner: {
      root: path(ROOTS_DASHBOARD, '/administrate/partner'),
      new: path(ROOTS_DASHBOARD, '/administrate/partner/new'),
      list: path(ROOTS_DASHBOARD, '/administrate/partner/list'),
      cards: path(ROOTS_DASHBOARD, '/administrate/partner/cards'),
      licenses: path(ROOTS_DASHBOARD, '/administrate/partner/licenses'),
      account: path(ROOTS_DASHBOARD, '/administrate/partner/account'),
      edit: (id) => path(ROOTS_DASHBOARD, `/administrate/partner/${id}/edit`),
    },

    channel: {
      root: path(ROOTS_DASHBOARD, '/administrate/channel'),
      list: path(ROOTS_DASHBOARD, '/administrate/channel/list'),
      new: path(ROOTS_DASHBOARD, '/administrate/channel/new'),
      profile: (id) => path(ROOTS_DASHBOARD, `/administrate/channel/${id}/profile`),
      task: (id) => path(ROOTS_DASHBOARD, `/administrate/channel/${id}/task`),
      account: (id) => path(ROOTS_DASHBOARD, `/administrate/channel/${id}/account`),
      edit: (id) => path(ROOTS_DASHBOARD, `/administrate/channel/${id}/edit`),
      timelog: (id) => path(ROOTS_DASHBOARD, `/administrate/channel/${id}/timelog`),
    },

    license: {
      root: path(ROOTS_DASHBOARD, '/administrate/license'),
      list: path(ROOTS_DASHBOARD, '/administrate/license/list'),
      new: path(ROOTS_DASHBOARD, '/administrate/license/new'),
      edit: (id) => path(ROOTS_DASHBOARD, `/administrate/license/${id}/edit`),
    },
  },

  // user: {
  //   root: path(ROOTS_DASHBOARD, '/user'),
  //   new: path(ROOTS_DASHBOARD, '/user/new'),
  //   list: path(ROOTS_DASHBOARD, '/user/list'),
  //   cards: path(ROOTS_DASHBOARD, '/user/cards'),
  //   profile: path(ROOTS_DASHBOARD, '/user/profile'),
  //   account: path(ROOTS_DASHBOARD, '/user/account'),
  //   edit: (id) => path(ROOTS_DASHBOARD, `/user/${id}/edit`),
  //   demoEdit: path(ROOTS_DASHBOARD, '/user/reece-chung/edit'),
  // },

  // absences: {
  //   root: path(ROOTS_DASHBOARD, '/absences'),
  //   records: path(ROOTS_DASHBOARD, '/absences/records'),
  // },

  // timelog: {
  //   root: path(ROOTS_DASHBOARD, '/timelog'),
  //   records: path(ROOTS_DASHBOARD, '/timelog/records'),
  //   logs: path(ROOTS_DASHBOARD, '/timelog/logs'),
  // },
  // board: {
  //   root: path(ROOTS_DASHBOARD, '/board'),
  //   client: path(ROOTS_DASHBOARD, '/board/client'),
  //   profile: path(ROOTS_DASHBOARD, '/board/profile'),
  //   task: path(ROOTS_DASHBOARD, '/board/task'),
  //   timelog: path(ROOTS_DASHBOARD, '/board/timelog'),
  //   list: path(ROOTS_DASHBOARD, '/board/timelog/records'),
  //   absences: path(ROOTS_DASHBOARD, '/board/timelog/absences'),
  //   report: path(ROOTS_DASHBOARD, '/board/timelog/report'),
  //   calendar: path(ROOTS_DASHBOARD, '/board/timelog/calendar'),
  // },

  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    // posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/blog/post/${id}`),
  },

  whatsNew: path(ROOTS_DASHBOARD, '/whats-new'),

  fileManager: {
    root: path(ROOTS_DASHBOARD, '/files-manager'),
    view: (title) => path(ROOTS_DASHBOARD, `/files-manager/${title}`),
  },
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';
