// ----------------------------------------------------------------------

export function remToPx(value) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({ sm, md, lg }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}

// ----------------------------------------------------------------------

const FONT_PRIMARY = 'Plantin, georgia, "times new roman"';
// const FONT_PRIMARY = 'Public Sans, sans-serif'; // Google Font
const FONT_SECONDARY = 'Montserrat, Arial';

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 400,
  fontWeightSemiBold: 600,
  fontWeightBold: 600,
  h1: {
    fontWeight: 400,
    lineHeight: 80 / 64,
    fontSize: pxToRem(40),
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
  },
  h2: {
    fontWeight: 400,
    lineHeight: 64 / 48,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
  },
  h3: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
  },
  h4: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
  },
  h5: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
  },
  h6: {
    fontWeight: 400,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
  },
  subtitle1: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: 400,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(15),
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  meta: {
    lineHeight: 20 / 14,
    fontSize: pxToRem(13),
  },
  inputLabel: {
    transform: 'translate(0px, -9px) scale(0.75)',
  },
  avatar: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
    textTransform: 'uppercase',
  },
  captionLong: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 400,
    letterSpacing: 'initial',
    lineHeight: 1.2,
    fontSize: pxToRem(13),
    fontStyle: 'italic',
    textTransform: 'none',
  }, // Used by: FormHelperText,
  tooltip: {
    lineHeight: 1.3,
    // fontSize: pxToRem(11.5),
    fontSize: pxToRem(12),
  },
  caption: {
    fontFamily: FONT_SECONDARY,
    letterSpacing: '0.17em',
    lineHeight: 1.5,
    // fontSize: pxToRem(14),
    fontSize: pxToRem(10),
    textTransform: 'uppercase',
  },
  label: {
    fontFamily: FONT_SECONDARY,
    letterSpacing: '0.06em',
    // letterSpacing: 'initial',
    lineHeight: 1.5,
    fontWeight: 600,
    fontSize: pxToRem(10),
    textTransform: 'uppercase',
  },
  labelSM: {
    fontFamily: FONT_SECONDARY,
    // letterSpacing: '0.06em',
    letterSpacing: '0.17em',
    lineHeight: 1,
    fontSize: pxToRem(8.5),
    textTransform: 'uppercase',
  },
  labelXS: {
    fontFamily: FONT_SECONDARY,
    // letterSpacing: '0.06em',
    letterSpacing: '0.17em',
    lineHeight: 1,
    fontSize: pxToRem(7.9),
    textTransform: 'uppercase',
  },
  overline: {
    letterSpacing: '0.17em',
    lineHeight: 1.5,
    fontFamily: FONT_SECONDARY,
    fontWeight: 600,
    fontSize: pxToRem(10),
    textTransform: 'uppercase',
  },
  tableHead: {
    fontFamily: FONT_SECONDARY,
    letterSpacing: '0.06em',
    lineHeight: 1.5,
    fontWeight: 600,
    fontSize: pxToRem(9.5),
    textTransform: 'uppercase',
  }, // Used by: TableHead, Chip
  tableHeadLarge: {
    fontFamily: FONT_SECONDARY,
    letterSpacing: '0.06em',
    lineHeight: 1.5,
    fontWeight: 600,
    fontSize: pxToRem(10),
    textTransform: 'uppercase',
  }, // Used by: TableHead, Chip
  button: {
    letterSpacing: '0.17em',
    lineHeight: 24 / 14,
    fontFamily: FONT_SECONDARY,
    fontWeight: 600,
    fontSize: pxToRem(10),
    // fontSize: pxToRem(12),
    textTransform: 'uppercase',
  },
  buttonShortcutText: {
    letterSpacing: '0.06em',
    lineHeight: 24 / 14,
    fontFamily: FONT_SECONDARY,
    fontWeight: 600,
    fontSize: pxToRem(10),
    textTransform: 'uppercase',
  },
  buttonLG: {
    letterSpacing: '0.17em',
    lineHeight: 24 / 14,
    fontFamily: FONT_SECONDARY,
    fontWeight: 600,
    fontSize: pxToRem(11),
    textTransform: 'uppercase',
  },
  buttonXL: {
    letterSpacing: '0.17em',
    lineHeight: 24 / 14,
    fontFamily: FONT_SECONDARY,
    fontWeight: 600,
    fontSize: pxToRem(12),
    textTransform: 'uppercase',
  },
  buttonXXL: {
    letterSpacing: '0.17em',
    lineHeight: 24 / 14,
    fontFamily: FONT_SECONDARY,
    fontWeight: 600,
    fontSize: pxToRem(13),
    textTransform: 'uppercase',
  },
  labelNumber: {
    lineHeight: 24 / 14,
    fontFamily: FONT_SECONDARY,
    fontWeight: 600,
    // fontSize: pxToRem(10.5),
  },
  numberSecondary: {
    lineHeight: 24 / 14,
    fontFamily: FONT_SECONDARY,
    fontWeight: 600,
    fontSize: pxToRem(14),
  },
  tab: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 600,
    fontSize: pxToRem(10.5),
  },
  switchLabel: {
    // Not used
    fontFamily: FONT_SECONDARY,
    letterSpacing: '0.06em',
    // letterSpacing: 'initial',
    lineHeight: 1.5,
    // fontWeight: 200,
    fontSize: pxToRem(9),
    textTransform: 'uppercase',
  },
};

export default typography;
