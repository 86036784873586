import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const IconDocument = ({ size = 21, color = 'currentColor', style, ...restProps }) => {
  const theme = useTheme();

  const getColor = (colorName) => {
    const colorParts = colorName.split('.');
    let colorValue = theme.palette[colorParts[0]];
    for (let i = 1; i < colorParts.length; i++) {
      colorValue = colorValue[colorParts[i]];
    }
    return colorValue;
  };

  const strokeColor = color === 'currentColor' ? color : getColor(color);

  return (
    <Box sx={style} display="flex">
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 21 21">
        <g fill="none" fillRule="evenodd" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round">
          <path d="M16.5 15.5v-7l-5-5h-5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zm-10-5h5m-5 2h7m-7 2h3" />
          <path d="M11.5 3.5v3a2 2 0 0 0 2 2h3" />
        </g>
      </svg>
    </Box>
  );
};

export default IconDocument;
