// src/utils/dateUtils.js
import { capitalizeFirstLetter } from './textUtils';

/**
 * Utility functions to handle dates.
 */

/**
 * formatDateToYYYYMM01
 * Formats a JavaScript Date object to a string in the format of "YYYY-MM-01".
 * This function ensures the month is zero-padded to two digits if necessary.
 *
 * @param {Date} date - The date to format.
 * @returns {string} The formatted date string in "YYYY-MM-01" format.
 */
export function formatDateToYYYYMM01(date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // JavaScript months are 0-indexed
  return `${year}-${month}-01`;
}

export function formatYYYYMM01ToDate(YYYYMM01) {
  const [year, month] = YYYYMM01.split('-');
  return new Date(year, month - 1);
}

export function getLastDayOfMonth(date = new Date()) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

export function getPreviousMonth(month) {
  return month === 0 ? 11 : month - 1;
}

export function getPreviousMonthOfCurrent() {
  const today = new Date();
  const previousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  return previousMonth;
}

/**
 * Get the previous month and year from the current date.
 *
 * @returns {Object} - An object containing the previous month index (0-11) and the year of the previous month.
 */
export function getPreviousMonthAndYear() {
  const currentDate = new Date();
  let previousMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  let yearOfPreviousMonth = currentYear;

  if (previousMonth == 0) {
    previousMonth = 12;
    yearOfPreviousMonth = currentYear - 1;
  }

  return {
    previousMonth: previousMonth,
    yearOfPreviousMonth: yearOfPreviousMonth,
  };
}

// export function getNextMonthAndYear(date) {
//   const currentDate = new Date();
//   let nextMonth = currentDate.getMonth();
//   const currentYear = currentDate.getFullYear();

//   let yearOfNextMonth = currentYear;

//   if (nextMonth == 11) {
//     nextMonth = -1;
//     yearOfNextMonth = currentYear + 1;
//   }

//   return {
//     nextMonth: nextMonth,
//     yearOfNextMonth: yearOfNextMonth,
//   };
// }

export function getYearsList() {
  const currentYear = new Date().getFullYear();
  return [currentYear - 2, currentYear - 1, currentYear, currentYear + 1];
}

/**
 * Format the month number into a month name.
 *
 * @param {number} monthNumber - The month number (1-12).
 * @returns {string} - The formatted month name.
 */
export function formatMonth(monthNumber) {
  const date = new Date(0);
  date.setMonth(monthNumber - 1); // JavaScript month is 0-indexed (0-11), so we subtract 1
  const monthString = date.toLocaleString('en-US', { month: 'short' });
  return capitalizeFirstLetter(monthString);
}
