import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const IconShootingStarLight = ({ size = 21, color = 'currentColor', style, ...restProps }) => {
  const theme = useTheme();

  const getColor = (colorName) => {
    const colorParts = colorName.split('.');
    let colorValue = theme.palette[colorParts[0]];
    for (let i = 1; i < colorParts.length; i++) {
      colorValue = colorValue[colorParts[i]];
    }
    return colorValue;
  };

  const strokeColor = color === 'currentColor' ? color : getColor(color);

  return (
    <Box sx={style} display="flex">
      <svg width={size} height={size} viewBox="0 0 256 256">
        <path
          d="M168.8,27.14,184,62.57a5.21,5.21,0,0,0,4.36,3.12L227.23,69a5.12,5.12,0,0,1,3,9L200.7,103.17a5.09,5.09,0,0,0-1.66,5l8.84,37.46a5.2,5.2,0,0,1-7.77,5.57l-33.42-19.87a5.29,5.29,0,0,0-5.38,0l-33.42,19.87a5.2,5.2,0,0,1-7.77-5.57L129,108.22a5.09,5.09,0,0,0-1.66-5L97.81,78a5.12,5.12,0,0,1,3-9l38.88-3.29A5.21,5.21,0,0,0,144,62.57L159.2,27.14A5.24,5.24,0,0,1,168.8,27.14Z"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
        <line
          x1="80"
          y1="120"
          x2="24"
          y2="176"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
        <line
          x1="96"
          y1="176"
          x2="40"
          y2="232"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
        <line
          x1="168"
          y1="176"
          x2="112"
          y2="232"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
      </svg>
    </Box>
  );
};

export default IconShootingStarLight;
