import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// DOCUMENTATION ---------------------------------------------------------------
/*
 * List all partners supplier numbers:
 * GET /partner-supplier-numbers
 *
 * Register new partner:
 * POST /partner-supplier-numbers/register
 *
 * Get partner by ID:
 * GET /partner-supplier-numbers/{id}
 *
 * Update partners by ID:
 * PUT /partner-supplier-numbers/update/{id}
 *
 * Delete partners:
 * DELETE /partner-supplier-numbers/delete/{id}
 */

// -----------------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  partnerSuppliers: [],
  partnerSupplier: null,
};

const slice = createSlice({
  name: 'partnerSuppliers',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MULTIPLE
    getPartnerSuppliersSuccess(state, action) {
      state.isLoading = false;
      state.partnerSuppliers = action.payload;
    },

    // GET SINGLE
    getPartnerSupplierSuccess(state, action) {
      state.isLoading = false;
      state.partnerSupplier = action.payload;
    },

    // DELETE
    deletePartnerSupplierSuccess(state, action) {
      state.isLoading = false;
      state.partnerSuppliers = state.partnerSuppliers.filter((item) => item.id !== action.payload.id);
    },

    // Reset partnerSupplier data
    resetPartnerSupplier(state) {
      state.partnerSupplier = null;
    },
    resetPartnerSuppliers(state) {
      state.partnerSuppliers = [];
    },
    resetPartnerSupplierError(state) {
      state.error = null;
    },
  },
});

// Reset actions
export const { resetPartnerSupplier, resetPartnerSuppliers, resetPartnerSupplierError } = slice.actions;

// Reducer
export default slice.reducer;

// THUNKS ACTIONS --------------------------------------------------------------

export function getPartnerSuppliers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/partner-supplier-numbers');
      dispatch(slice.actions.getPartnerSuppliersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPartnerSuppliersByPartnerId(partnerId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.get(`/partners/${partnerId}/partner_supplier_numbers`).then((response) => {
        dispatch(slice.actions.getPartnerSuppliersSuccess(response.data.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function getPartnerSupplier(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/partner-supplier-numbers/${id}`);
      dispatch(slice.actions.getPartnerSupplierSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createPartnerSupplier(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.post('/partner-supplier-numbers/register', values);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function updatePartnerSupplier({ values, id }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.put(`/partner-supplier-numbers/update/${id}`, values);
      return response;
    } catch (error) {
      console.log('updatePartnerSupplier error:', error);
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function deletePartnerSupplier(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/partner-supplier-numbers/delete/${id}`);
      console.log('deletePartnerSupplier response:', response);
      dispatch(slice.actions.deletePartnerSupplierSuccess(response));
    } catch (error) {
      console.error('deletePartnerSupplier error:', error);
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
