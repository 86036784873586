import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const IconCalendarMonth = ({ size = 21, color = 'currentColor', style, ...restProps }) => {
  const theme = useTheme();

  const getColor = (colorName) => {
    const colorParts = colorName.split('.');
    let colorValue = theme.palette[colorParts[0]];
    for (let i = 1; i < colorParts.length; i++) {
      colorValue = colorValue[colorParts[i]];
    }
    return colorValue;
  };

  const strokeColor = color === 'currentColor' ? color : getColor(color);

  return (
    <Box sx={style} display="flex">
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 21 21">
        <g fill="none" fillRule="evenodd">
          <path
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4.5 2.5h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2v-12a2 2 0 0 1 2-2zm-2 4h16"
          />
          <g fill={strokeColor} transform="translate(2 2)">
            <circle cx="8.5" cy="8.5" r="1" />
            <circle cx="4.5" cy="8.5" r="1" />
            <circle cx="12.5" cy="8.5" r="1" />
            <circle cx="8.5" cy="12.5" r="1" />
            <circle cx="4.5" cy="12.5" r="1" />
            <circle cx="12.5" cy="12.5" r="1" />
          </g>
        </g>
      </svg>
    </Box>
  );
};

export default IconCalendarMonth;
