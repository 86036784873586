import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  helperText: PropTypes.node,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
};

RHFTextField.defaultProps = {
  helperText: '',
  name: '',
  label: '',
  required: false,
};

export default function RHFTextField({ name, helperText, label, required, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          label={required ? `${label} *` : label}
          error={!!error}
          fullWidth
          helperText={error ? error?.message : helperText}
          value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          InputLabelProps={field.value ? { shrink: true } : {}}
          {...other}
          variant="standard"
        />
      )}
    />
  );
}
