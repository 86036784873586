export const months = [
  { name: 'Jan', value: '01' },
  { name: 'Feb', value: '02' },
  { name: 'Mar', value: '03' },
  { name: 'Apr', value: '04' },
  { name: 'May', value: '05' },
  { name: 'Jun', value: '06' },
  { name: 'Jul', value: '07' },
  { name: 'Aug', value: '08' },
  { name: 'Sep', value: '09' },
  { name: 'Oct', value: '10' },
  { name: 'Nov', value: '11' },
  { name: 'Dec', value: '12' },
];
