import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  invoices: [],
  invoice: null,
  invoiceSpecifications: {},
  loadingInvoiceSpecs: false,
};

const slice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING INVOICE SPECIFICATIONS
    startLoadingInvoiceSpecs(state) {
      state.loadingInvoiceSpecs = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CANCEL LOADING
    cancelLoading(state) {
      state.isLoading = false;
    },

    // GET INVOICES
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },

    // GET INVOICE
    getInvoiceSuccess(state, action) {
      state.isLoading = false;
      state.invoice = action.payload;
    },

    // EXPORT INVOICE
    exportInvoiceSuccess(state, action) {
      state.isLoading = false;
      state.invoice = action.payload;
    },

    // DELETE INVOICE
    deleteInvoiceSuccess(state, action) {
      const invoiceId = action.payload;
      state.invoices = state.invoices.filter((invoice) => invoice.id !== invoiceId);
    },

    // GET INVOICE CHANNELS
    getInvoiceSpecificationsSuccess(state, action) {
      state.loadingInvoiceSpecs = false;
      state.invoiceSpecifications = action.payload;
    },

    // GET INVOICE ACCOUNT
    getInvoiceAccountSuccess(state, action) {
      state.isLoading = false;
      state.invoice = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function createInvoice(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.post('/invoices/register', {}, { params: data }).then((response) => {
        dispatch(slice.actions.getInvoicesSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function createInvoiceSpecification(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.post('/invoice-specifications/register', {}, { params: data }).then((response) => {
        dispatch(slice.actions.getInvoicesSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function getInvoices(period, cancelToken) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios
        .get('/invoices', { params: { period }, cancelToken: cancelToken ? cancelToken.token : null })
        .then((response) => {
          dispatch(slice.actions.getInvoicesSuccess(response.data));
        });
    } catch (error) {
      dispatch(slice.actions.cancelLoading());
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getInvoiceById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.get(`/invoices/${id}`).then((response) => {
        dispatch(slice.actions.getInvoiceSuccess(response.data));
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInvoiceSpecifications(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingInvoiceSpecs());
    try {
      return await axios.get(`/invoices/${id}/invoice-specifications`).then((response) => {
        dispatch(slice.actions.getInvoiceSpecificationsSuccess(response.data));
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInvoiceAccount(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/invoices/${id}/account`);
      dispatch(slice.actions.getInvoiceAccountSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteInvoice(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/invoices/delete/${id}`);
      // dispatch(slice.actions.deleteInvoiceSuccess(response));
      return response;
    } catch (error) {
      console.log('Error during deletion:', error);
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function exportInvoice(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.post(`/invoices/${id}/send`, {}, {}).then((response) => {
        dispatch(slice.actions.exportInvoiceSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function getClientInvoice(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/clients/${id}/invoices`);

      dispatch(slice.actions.getInvoicesSuccess(response.data));
      //return response;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateInvoice(data, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.put(
        `/invoices/update/${id}`,
        {},
        {
          params: {
            client_id: data.client.id,
            contact_id: data.contact.id,
            account_id: 1,
            dedicated_ids: data.contact.id,
            internal_invoice_id: data.invoiceNumber,
            fortnox_document_number: null,
            fortnox_invoice_url: null,
            period: data.createDate,
            created_date: data.createDate,
            due_date: data.dueDate,
            amount: data.totalPrice,
            vat: data.taxes,
            discount: data.discount,
            total_amount: data.totalPrice,
            total_hours: data.totalPrice - ((data.taxes / 100) * data.totalPrice - data.totalPrice),
            notes: data.description || null,
            status: 1,
          },
        }
      );

      dispatch(slice.actions.getInvoicesSuccess(response.data));
      //return response;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateInvoiceSpecification(data, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.put(`/invoice-specifications/update/${id}`, {}, { params: data });

      dispatch(slice.actions.getInvoicesSuccess(response.data));
      //return response;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
