import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  timeLogs: {},
  timeLog: null,
  activeBillingStep: 0,
};

const slice = createSlice({
  name: 'timeLog',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CANCEL LOADING
    cancelLoading(state) {
      state.isLoading = false;
    },

    // GET Time log
    gettimeLogsSuccess(state, action) {
      state.isLoading = false;
      state.timeLogs = action.payload;
    },

    // GET Time log
    gettimeLoguccess(state, action) {
      state.isLoading = false;
      state.timeLog = action.payload;
    },

    // Clear Time Logs
    clearTimeLogs(state) {
      state.timeLogs = [];
    },

    backStep(state) {
      state.activeBillingStep -= 1;
    },

    nextStep(state) {
      state.activeBillingStep += 1;
    },

    gotoStep(state, action) {
      const step = action.payload;
      state.activeBillingStep = step;
    },
  },
});

// Reducer
export default slice.reducer;
export const { clearTimeLogs, gotoStep, backStep, nextStep } = slice.actions;

// ----------------------------------------------------------------------

export function getTimelogs() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/time-logs`);
      dispatch(slice.actions.gettimeLogsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createTimelog(timelog) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/time-logs/register', {}, { params: timelog });
      dispatch(slice.actions.gettimeLogsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTimeLog(period, clientId, cancelToken) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/clients/${clientId}/billable-time?period=${period}`, {
        cancelToken: cancelToken ? cancelToken.token : null,
      });
      dispatch(slice.actions.gettimeLogsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.cancelLoading());
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}
