import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import axios from '../../../utils/axios';
import localStorageAvailable from '../../../utils/localStorageAvailable';
import { isValidToken } from '../../../auth/utils';

const initialState = {
  isInitialized: false,
  consultants: null,
  consultant: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      consultants: action.payload.consultants,
      consultant: action.payload.consultant,
    };
  }
  if (action.type === 'SET_CONSULTANTS') {
    return {
      ...state,
      consultants: action.payload.consultants,
    };
  }
  if (action.type === 'SET_CONSULTANT') {
    return {
      ...state,
      consultant: action.payload.consultant,
    };
  }
  return state;
};

export const ConsultantContext = createContext(null);

ConsultantProvider.propTypes = {
  children: PropTypes.node,
};

ConsultantProvider.defaultProps = {
  children: null,
};

export function ConsultantProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const consultants = storageAvailable ? JSON.parse(localStorage.getItem('consultants')) : null;

      const consultant = storageAvailable ? JSON.parse(localStorage.getItem('consultant')) : null;

      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

      const user = storageAvailable ? localStorage.getItem('user') : '';

      if (accessToken && isValidToken(accessToken) && !user && !consultants && !consultant) {
        const consultantsResponse = await axios.get('/consultants?status=1');
        const consultants = consultantsResponse.data;

        localStorage.setItem('consultants', JSON.stringify(consultants));

        dispatch({
          type: 'SET_CONSULTANTS',
          payload: {
            consultants,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            consultants,
            consultant,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          consultants: null,
          consultant: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const getConsultants = useCallback(async () => {
    const response = await axios.get('/consultants?status=1');

    const consultants = response.data;

    localStorage.setItem('consultants', JSON.stringify(consultants));

    dispatch({
      type: 'SET_CONSULTANTS',
      payload: {
        consultants,
      },
    });
  }, []);

  const getConsultant = useCallback(async (name) => {
    const response = await axios.get(`/consultants/${name}`);

    const consultant = response.data;

    localStorage.setItem('consultant', JSON.stringify(consultant));

    dispatch({
      type: 'SET_CONSULTANT',
      payload: {
        consultant,
      },
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      consultants: state.consultants,
      consultant: state.consultant,
      getConsultants,
      getConsultant,
    }),
    [state.isInitialized, state.consultants, state.consultant, getConsultants, getConsultant]
  );

  return <ConsultantContext.Provider value={memoizedValue}>{children}</ConsultantContext.Provider>;
}
