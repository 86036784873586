import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Radio, FormLabel, RadioGroup, FormControl, FormHelperText, FormControlLabel } from '@mui/material';

// ----------------------------------------------------------------------

RHFRadioGroup.propTypes = {
  helperText: PropTypes.node,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  row: PropTypes.bool,
  spacing: PropTypes.number,
};

RHFRadioGroup.defaultProps = {
  helperText: '',
  label: '',
  name: '',
  options: [],
  row: false,
  spacing: 0,
};

export default function RHFRadioGroup({ row, name, label, options, spacing, helperText, ...other }) {
  const { control } = useFormContext();

  const labelledby = label ? `${name}-${label}` : '';

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl component="fieldset" variant="standard">
          {label ? (
            <FormLabel component="legend" id={labelledby} sx={{ typography: 'body2' }}>
              {label}
            </FormLabel>
          ) : null}

          <RadioGroup {...field} aria-labelledby={labelledby} row={row} {...other}>
            {options.map((option) => (
              <FormControlLabel
                control={<Radio />}
                key={option.value}
                label={option.label}
                sx={{
                  '&:not(:last-of-type)': {
                    mb: spacing || 0,
                  },
                  ...(row && {
                    mr: 0,
                    '&:not(:last-of-type)': {
                      mr: spacing || 2,
                    },
                  }),
                }}
                value={option.value}
              />
            ))}
          </RadioGroup>

          {!!error || helperText ? (
            <FormHelperText error={!!error} sx={{ mx: 0 }}>
              {error ? error?.message : helperText}
            </FormHelperText>
          ) : null}
        </FormControl>
      )}
    />
  );
}
