// IMPORTS
import { MENU_ITEMS, REPORTS } from 'src/sections/@dashboard/rootlytics/rootlyticsConfig';

/**
 * Utility function to dynamically assign the correct ID based on report type and sub-report.
 *
 * @param {string} subReport - The sub-report type (e.g., 'tools', 'client_team').
 * @param {string} reportType - The type of the report (e.g., 'team', 'agency').
 * @param {number|null} teamId - The ID of the team, if applicable.
 *
 * @returns {Object} An object containing the correct ID mappings based on the report type.
 */
export const getDynamicId = (subReport, reportType, teamId) => {
  const idMappings = {
    tools: { license_id: 0 },
    client_team: { team_id: teamId || 0 },
    channel_team: { channel_team_id: 0 },
    channel: { channel_id: 0 },
    service: { service_id: 0 },
    client: { client_id: 0 },
    consultant: { consultant_id: 0 },
  };

  return reportType === 'team' ? { team_id: teamId } : idMappings[subReport] || {};
};

/**
 * Utility function to check if a subReport exists for a given reportType.
 *
 * @param {string} subReport - The sub-report type.
 * @param {string} reportType - The type of the report.
 *
 * @returns {boolean} True if the subReport exists for the reportType.
 */
export const subReportExists = (subReport, reportType) => {
  return MENU_ITEMS.some((item) => item.value === subReport && item.reportTypes.includes(reportType));
};

/**
 * Utility function to find the first valid sub-report for a given reportType.
 *
 * @param {string} reportType - The type of the report.
 *
 * @returns {string} The first valid sub-report for the reportType or an empty string.
 */
export const firstValidSubReport = (reportType) => {
  return MENU_ITEMS.find((item) => item.reportTypes.includes(reportType))?.value || '';
};

/**
 * Generates a cache key based on the parameters and ensures the subReport is valid.
 *
 * @param {string} report - The report type.
 * @param {string} subReport - The sub-report type.
 * @param {string} reportType - The type of the report.
 * @param {string} periodYear - The year of the period.
 *
 * @returns {string} A stringified cache key used for caching data.
 */
export const generateCacheKey = (report, subReport, reportType, periodYear) => {
  const startDate = `${periodYear}-01-01`;
  const endDate = `${periodYear}-12-01`;

  const validSubReport = subReportExists(subReport, reportType) ? subReport : firstValidSubReport(reportType);

  const type = reportType === 'team' ? 'client_team' : validSubReport;

  const subType = reportType === 'team' ? (validSubReport === 'organisation' ? '' : validSubReport) : validSubReport;

  const dynamicParams = getDynamicId(subType, reportType, REPORTS.find((item) => item.value === report)?.teamId);

  return JSON.stringify({
    report,
    subReport: subType,
    reportType,
    type,
    startDate,
    endDate,
    ...dynamicParams,
  });
};

/**
 * Extracts parameters from the cacheKey.
 *
 * @param {string} cacheKey - The cache key string.
 *
 * @returns {Object} The extracted parameters for API requests.
 */
export const extractParamsFromCacheKey = (cacheKey) => {
  const { report, reportType, subReport, startDate, endDate, team_id, ...otherParams } = JSON.parse(cacheKey);

  // Return the parsed cache key data, excluding 'report' from dynamicParams
  return {
    reportType,
    subReport,
    startDate,
    endDate,
    dynamicParams: { team_id, ...otherParams },
  };
};

/**
 * Filters data by excluding specific fields.
 *
 * @param {Array} data - The dataset to be filtered.
 * @param {Array} excludeFields - An array of field names to exclude from each data object.
 *
 * @returns {Array} A new dataset with the specified fields removed from each object.
 */
export const filterDataByExclusion = (data, excludeFields) => {
  if (!excludeFields || excludeFields.length === 0) {
    return data;
  }

  return data.map((item) => {
    const filteredItem = { ...item };
    excludeFields.forEach((field) => {
      delete filteredItem[field];
    });
    return filteredItem;
  });
};

// EXPORTS ---------------------------------------------------------------------
export default {
  getDynamicId,
  subReportExists,
  firstValidSubReport,
  generateCacheKey,
  extractParamsFromCacheKey,
  filterDataByExclusion,
};
