import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  absencess: [],
  absences: null,
};

const slice = createSlice({
  name: 'absences',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getAbsencesSuccess(state, action) {
      state.isLoading = false;
      state.absences = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAbsences() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/absences');
      dispatch(slice.actions.getAbsencesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConsultantAbsences(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/clients/${id}/absencess`);
      dispatch(slice.actions.getAbsencesSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createAbsences(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.post('/absences/register', {}, { params: data }).then((response) => {
        dispatch(slice.actions.getAbsencesSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function updateAbsences(data, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError(null));

      return await axios.put(`/absences/update/${id}`, {}, { params: data }).then(
        (response) =>
          // dispatch(slice.actions.getAbsencesSuccess(response.data));
          response
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteAbsences(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.delete(`/absences/delete/${id}`).then((response) => {
        dispatch(slice.actions.getAbsencesSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
