import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import { Container, Typography } from '@mui/material';
// components
import { MotionContainer, varBounce } from '../components/animate';
// assets
import { ForbiddenIllustration } from '../assets/illustrations';
//
import { useAuthContext } from './useAuthContext';

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
  hasContent: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string),
};

RoleBasedGuard.defaultProps = {
  children: null,
  hasContent: false,
  roles: undefined,
};

export default function RoleBasedGuard({ hasContent, roles, children }) {
  // Logic here to get current user role
  const { role, user } = useAuthContext();

  let currentRole = false;

  // const currentRole = user?.role; // admin;
  for (var i = 0; i < user?.role?.length; i++) {
    if (typeof roles !== 'undefined' && roles.includes(user?.role[i])) {
      currentRole = true;
      break;
    }
  }
  //const currentRole = user?.role ?? 'user'; // admin;
  if (typeof roles !== 'undefined' && !currentRole) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography paragraph variant="h3">
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>You do not have permission to access this page</Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
      </Container>
    ) : null;
  }

  return <> {children} </>;
}
