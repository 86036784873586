import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  consultants: [],
  consultant: null,
  identity: null,
  consultantId: null,
  _consultantTask: [],
  _consultantNotes: [],
  _consultantDedicateds: [],
  // _consultantDedicatedsLoadedButEmpty: false,
  _consultantClients: [],
  _consultantTeams: [],
};

const slice = createSlice({
  name: 'consultant',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CANCEL LOADING
    cancelLoading(state) {
      state.isLoading = false;
    },

    getConsultantsSuccess(state, action) {
      state.isLoading = false;
      state.consultants = action.payload;
    },

    getConsultantSuccess(state, action) {
      state.isLoading = false;
      state.consultant = action.payload;
    },

    getConsultantIdentitySuccess(state, action) {
      state.isLoading = false;
      state.identity = action.payload;
      state.consultantId = action.payload.data.id;
    },

    getConsultantTaskSuccess(state, action) {
      state.isLoading = false;
      state._consultantTask = action.payload;
    },

    getConsultantNotesSuccess(state, action) {
      state.isLoading = false;
      state._consultantNotes = action.payload;
    },

    getConsultantDedicatedSuccess(state, action) {
      state.isLoading = false;
      state._consultantDedicateds = action.payload.data;
    },

    getConsultantClientsSuccess(state, action) {
      state.isLoading = false;
      state._consultantClients = action.payload;
    },

    getConsultantTeamsSuccess(state, action) {
      state.isLoading = false;
      state._consultantTeams = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getConsultants(filters = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/consultants', { params: filters });
      dispatch(slice.actions.getConsultantsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getConsultantsById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/consultants/${id}`);
      dispatch(slice.actions.getConsultantSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConsultantsIdentity(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/consultants/${id}/user`);
      dispatch(slice.actions.getConsultantIdentitySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConsultantByUserId(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/consultants/${id}/user`);
      dispatch(slice.actions.getConsultantSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createConsultant(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { file, user_id, short_name, title, phone, organisation_id, slack_id, type } = data;
      var formData = new FormData();
      formData.append('file', file);
      formData.append('user_id', user_id);
      formData.append('short_name', short_name);
      formData.append('title', title);
      formData.append('phone', phone);
      formData.append('organisation_id', organisation_id);
      formData.append('slack_id', slack_id);
      formData.append('type', type);

      return await axios
        .post('/consultants/register', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          dispatch(slice.actions.getConsultantSuccess(response.data));
          return response.data;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function updateConsultant(data, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { file, user_id, first_name, last_name, short_name, title, phone, organisation_id, slack_id, type } = data;
      var formData = new FormData();
      formData.append('file', file);
      formData.append('user_id', user_id);
      formData.append('first_name', first_name);
      formData.append('last_name', last_name);
      formData.append('short_name', short_name);
      formData.append('title', title);
      formData.append('phone', phone);
      formData.append('organisation_id', organisation_id);
      formData.append('slack_id', slack_id);
      formData.append('type', type);

      return await axios
        .post(`/consultants/update/${id}?_method=PUT`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          dispatch(slice.actions.getConsultantSuccess(response.data));
          return response.data;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConsultantsClients(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/consultants/${id}/clients`);
      dispatch(slice.actions.getConsultantClientsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConsultantsTeams(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/consultants/${id}/teams`);
      dispatch(slice.actions.getConsultantTeamsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConsultantsTasks(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/consultants/${id}/tasks`);
      dispatch(slice.actions.getConsultantTaskSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConsultantsNotes(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/consultants/${id}/notes`);
      dispatch(slice.actions.getConsultantNotesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createConsultantsNotes(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/notes/register`, {}, { params: data });
      dispatch(slice.actions.getConsultantNotesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateConsultantsNotes(data, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/notes/update/${id}`, {}, { params: data });
      dispatch(slice.actions.getConsultantNotesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteConsultantsNotes(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/notes/${id}/delete`);
      dispatch(slice.actions.getConsultantNotesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateConsultantTask(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios
        .put(
          `/tasks/${data.task_id}/consultant/${data.consultant_id}/update`,
          {},
          {
            params: data,
          }
        )
        .then((response) => {
          dispatch(slice.actions.getTasksSuccess(response.data));
          return response;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function getConsultantDedicateds(period, id, cancelToken) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    // Construct the base URL
    let url = `/consultants/${id}/dedicateds`;

    // Append the query parameter if period exists
    if (period) {
      url += `?period=${period}`;
    }

    try {
      const response = await axios.get(url, {
        cancelToken: cancelToken ? cancelToken.token : null,
      });
      dispatch(slice.actions.getConsultantDedicatedSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.cancelLoading());
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}
