// i18n
import './locales/i18n';

// scroll bar
import 'simplebar/src/simplebar.css';

import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// map
import './utils/mapboxgl';
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { enGB } from 'date-fns/locale';
// redux
import { store, persistor } from './redux/store';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// locales
import ThemeLocalization from './locales';
// components
import { StyledChart } from './components/chart';
import SnackbarProvider from './components/snackbar';
import ScrollToTop from './components/scroll-to-top';
import { MotionLazyContainer } from './components/animate';
import { ThemeSettings, SettingsProvider } from './components/settings';

// Check our docs
// https://docs.minimals.cc/authentication/js-version

import { AuthProvider } from './auth/JwtContext';
import { ConsultantProvider } from './assets/data/Context/ConsultantContext';
import { CurrencyProvider } from './assets/data/Context/CurrencyContext';
import { ClientProvider } from './assets/data/Context/ClientContext';
import { ServiceProvider } from './assets/data/Context/ServiceContext';
import { ChannelProvider } from './assets/data/Context/ChannelContext';
import { LicenseProvider } from './assets/data/Context/LicenseContext';
import { TeamProvider } from './assets/data/Context/TeamContext';
// import { AuthProvider } from './auth/Auth0Context';
// import { AuthProvider } from './auth/FirebaseContext';
// import { AuthProvider } from './auth/AwsCognitoContext';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <AuthProvider>
      <HelmetProvider>
        <ChannelProvider>
          <LicenseProvider>
            <CurrencyProvider>
              <ConsultantProvider>
                <ClientProvider>
                  <TeamProvider>
                    <ServiceProvider>
                      <ReduxProvider store={store}>
                        <PersistGate loading={null} persistor={persistor}>
                          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                            <SettingsProvider>
                              <BrowserRouter>
                                <ScrollToTop />
                                <MotionLazyContainer>
                                  <ThemeProvider>
                                    <ThemeSettings>
                                      <ThemeLocalization>
                                        <SnackbarProvider>
                                          <StyledChart />
                                          <Router />
                                        </SnackbarProvider>
                                      </ThemeLocalization>
                                    </ThemeSettings>
                                  </ThemeProvider>
                                </MotionLazyContainer>
                              </BrowserRouter>
                            </SettingsProvider>
                          </LocalizationProvider>
                        </PersistGate>
                      </ReduxProvider>
                    </ServiceProvider>
                  </TeamProvider>
                </ClientProvider>
              </ConsultantProvider>
            </CurrencyProvider>
          </LicenseProvider>
        </ChannelProvider>
      </HelmetProvider>
    </AuthProvider>
  );
}
