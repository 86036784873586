import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const IconChartDonutLight = ({ size = 21, color = 'currentColor', style, ...restProps }) => {
  const theme = useTheme();

  const getColor = (colorName) => {
    const colorParts = colorName.split('.');
    let colorValue = theme.palette[colorParts[0]];
    for (let i = 1; i < colorParts.length; i++) {
      colorValue = colorValue[colorParts[i]];
    }
    return colorValue;
  };

  const strokeColor = color === 'currentColor' ? color : getColor(color);

  return (
    <Box sx={style} display="flex">
      <svg width={size} height={size} viewBox="0 0 256 256">
        <line
          x1="128"
          y1="224"
          x2="128"
          y2="168"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
        <line
          x1="32"
          y1="128"
          x2="88"
          y2="128"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
        <path
          d="M94.55,104.76c-5.06,7.08-7,15.86-6.5,25.32a40,40,0,1,0,46.5-41.55A7.92,7.92,0,0,1,128,80.67V40a8,8,0,0,1,8.67-8c49.4,4.43,88,46.62,87.32,97.34-.72,51.7-42.92,93.9-94.62,94.62A96,96,0,0,1,32,128,94.8,94.8,0,0,1,54.2,66.9,8,8,0,0,1,66,66.34l27.6,28.2A8,8,0,0,1,94.55,104.76Z"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
      </svg>
    </Box>
  );
};

export default IconChartDonutLight;
