// prettier-ignore
export const STATUSES_TASKS = [
  { id: 0, value: 0, label: 'Inactive', name: 'draft',           color: 'error' },
  { id: 1, value: 1, label: 'Active',   name: 'todo',            color: 'warning' },
  { id: 2, value: 2, label: 'Reported', name: 'reported',        color: 'success' },
  { id: 3, value: 3, label: 'Invoiced', name: 'invoiced',        color: 'success' },
];

// billable_status
// 0: Tasks not reported,
// 1: All tasks reported,
// 2: Billable started,
// 3: Billable completed,
// 4: Invoice draft created
// export const STATUSES_TASKS_BILLING = [
//   { id: 0, value: 0, label: 'Billing not started', }, // Billing not started
//   { id: 1, value: 1, label: 'Billing started', }, // Billing started
//   { id: 2, value: 2, label: 'Billing finished', }, // Billing finished
// ];

// invoice_status
export const STATUSES_TASK_INVOICING = [
  { id: 0, value: 0, label: 'Not created' }, // Not created
  { id: 1, value: 1, label: 'Draft' }, // Draft
  { id: 2, value: 2, label: 'Exported' }, // Exported
];

// prettier-ignore
export const STATUSES_POSTS = [
  { id: 0, value: 'draft',    label: 'Draft',    color: 'info' },
  { id: 1, value: 'public',   label: 'Public',   color: 'success' },
];

// Invoice statuses from backend:
// public const INVOICE_NOT_CREATED_IN_PORTAL  = 0;
// public const INVOICE_CREATED_IN_PORTAL      = 1;
// public const INVOICE_CREATED_IN_FORTNOX     = 2;
// public const FORTNOX_INVOICE_CANCELLED      = 3;
// public const FORTNOX_INVOICE_SENT           = 4;
// public const INVOICING_DONE_WITH_0_SUM      = 5;
// public const FORTNOX_INVOICE_PAID           = 6;

// prettier-ignore
export const STATUSES_INVOICES = [
  // Outgoing Invoices
  { value: 0,   label: 'Not created',       suffix: '',    suffixColor: '',            color: 'default' },
  { value: 1,   label: 'Draft',             suffix: '',    suffixColor: '',            color: 'default'},
  { value: 2,   label: 'Exported',          suffix: '',    suffixColor: '',            color: 'success'},
  { value: 3,   label: 'Cancelled',         suffix: '',    suffixColor: '',            color: 'error' },
  { value: 4,   label: 'Sent',              suffix: '',    suffixColor: '',            color: 'success'},
  { value: 5,   label: 'Done (0 amount)',   suffix: '',    suffixColor: '',            color: 'success'},
  { value: 6,   label: 'Paid',              suffix: '',    suffixColor: '',            color: 'success'},
  // Spent Media Budget
  { value: 10,  label: 'Invoiced',          suffix: 'SPE', suffixColor: 'error.light', color: 'default'},
  { value: 11,  label: 'Cancelled',         suffix: 'SPE', suffixColor: 'error.light', color: 'error'},
  { value: 12,  label: 'Booked',            suffix: 'SPE', suffixColor: 'error.light', color: 'success'},
];

// prettier-ignore
const CLIENT_CONSULTING_PROGRESS_STATUSES_1 = [
  { id: 0,  value: 'tasks_no',          color: 'error',   label: 'No tasks',                    description: "Client has no tasks for the period."},
  { id: 1,  value: 'have_tasks',        color: 'warning', label: 'Tasks not activated',         description: "Some client tasks not activated for the period."}, // Client have tasks but at least some not activated
  { id: 2,  value: 'tasks_activated',   color: 'warning', label: 'Tasks not reported',          description: "Some client tasks not reported for the period."}, // All tasks activated, at least some not reported
  { id: 3,  value: 'tasks_reported',    color: 'warning', label: 'Ready for billing',           description: "All client tasks reported for the period."}, // All tasks reported
  { id: 4,  value: 'billing_started',   color: 'warning', label: 'Billing started',             description: "Client's consulting billing started for the period."},
  { id: 5,  value: 'billable_set',      color: 'success', label: 'Awaiting invoice creation',   description: "Billable values set for client for the period, awaiting invoice creation."}, // All tasks have billing set
  { id: 6,  value: 'invoice_created',   color: 'warning', label: 'Awaits export by finance',    description: "Client's consulting invoice created in Portal for the period."},
  { id: 7,  value: 'invoice_exported',  color: 'success', label: 'Invoice exported',            description: "Client's consulting invoice present in Fortnox for the period."},
  { id: 8,  value: 'fortnox_cancelled', color: 'error',   label: 'Invoice cancelled',           description: "Client's consulting invoice cancelled in Fortnox for the period."},
  { id: 9,  value: 'fortnox_sent',      color: 'success', label: 'Invoice sent to client',      description: "Client's consulting invoice sent in Fortnox for the period."},
  { id: 10, value: 'done_with_0_sum',   color: 'success', label: 'Period done without invoice', description: "Client's consulting marked as completed without invoice for the period"},
  { id: 11, value: 'fortnox_paid',      color: 'success', label: 'Invoice paid',                description: "Client's consulting invoice paid in Fortnox for the period."},
];

// Statuses for the consulting progress of the client's period
// prettier-ignore
export const CLIENT_CONSULTING_PROGRESS_STATUSES = [
  { value: 0,  color: 'error',   accountantColor: 'info',     label: 'No tasks',                     description: "Client has no tasks for the period."},
  { value: 1,  color: 'warning', accountantColor: 'info',     label: 'Tasks not activated',          description: "Some client tasks not activated for the period."}, // Client have tasks but at least some not activated
  { value: 2,  color: 'warning', accountantColor: 'info',     label: 'Tasks not reported',           description: "Some client tasks not reported for the period."}, // All tasks activated, at least some not reported
  { value: 3,  color: 'warning', accountantColor: 'info',     label: 'Ready for billing',            description: "All client tasks reported for the period."}, // All tasks reported
  { value: 4,  color: 'warning', accountantColor: 'info',     label: 'Billing started',              description: "Client's consulting billing started for the period."},
  { value: 5,  color: 'warning', accountantColor: 'info',     label: 'Awaiting invoice creation',    description: "Billable values set for client for the period, awaiting invoice creation."}, // All tasks have billing set
  { value: 6,  color: 'success', accountantColor: 'error',    label: 'Awaits export by finance',     description: "Client's consulting invoice created in Portal for the period."},
  { value: 7,  color: 'success', accountantColor: 'warning',  label: 'Invoice exported',             description: "Client's consulting invoice present in Fortnox for the period."},
  { value: 8,  color: 'error',   accountantColor: 'error',    label: 'Invoice cancelled',            description: "Client's consulting invoice cancelled in Fortnox for the period."},
  { value: 9,  color: 'success', accountantColor: 'info',     label: 'Invoice sent to client',       description: "Client's consulting invoice sent in Fortnox for the period."},
  { value: 10, color: 'success', accountantColor: 'success',  label: 'Period done without invoice',  description: "Client's consulting marked as completed without invoice for the period"},
  { value: 11, color: 'success', accountantColor: 'success',  label: 'Invoice paid',                 description: "Client's consulting invoice paid in Fortnox for the period."},
];

// Statuses for the media progress of the client's period
// prettier-ignore
export const CLIENT_MEDIA_PROGRESS_STATUSES = [
  { value: 0,  label: "We don't handle media",        description: ""}, // = if media_handled_by_client is not 0 (0 means Beet handles media)
  { value: 1,  label: 'No tasks with media',          description: "Client has no tasks with media budget for the period."},
  // The ones below only applies if the total sum of all media invoices matches the media budget
  { value: 2,  label: 'Ready for billing',            description: "Client have active tasks with media budget."},
  { value: 3,  label: 'Awaits export by finance',     description: "Client's media invoice created in Portal for the period."}, 
  { value: 4,  label: 'Invoice exported',             description: "Client's media invoice present in Fortnox for the period."},
  { value: 5,  label: 'Invoice cancelled',            description: "Client's media invoice cancelled in Fortnox for the period."},
  { value: 6,  label: 'Invoice sent to client',       description: "Client's media invoice sent in Fortnox for the period."},
  { value: 7,  label: 'Period done without invoice',  description: "Client's media marked as completed without invoice for the period"},
  { value: 8,  label: 'Invoice paid',                 description: "Client's media invoice paid in Fortnox for the period."},
];

// Additional media statuses
// - media_invoice_created: 0/1
// - media_invoice_created_but_not_exported: 0/1
