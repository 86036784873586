// utils/periodUtils.js

export const getInitialPeriodState = () => {
  const isRootlytics = window.location.pathname.includes('rootlytics');
  const queryParams = new URLSearchParams(window.location.search);

  // Get parameters from URL
  const rootlyticsYear = queryParams.get('rootlytics_year');
  const periodYearParam = queryParams.get('year');
  const periodMonthParam = queryParams.get('month');

  // Delete the irrelevant query parameters
  if (isRootlytics) {
    queryParams.delete('year');
  } else {
    queryParams.delete('rootlytics_year');
  }

  // Update the URL without reloading the page
  const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
  window.history.replaceState(null, '', newUrl);

  // Determine the correct year based on the page
  let periodYear;
  if (isRootlytics) {
    periodYear = rootlyticsYear || localStorage.getItem('rootlyticsYear') || new Date().getFullYear();
  } else {
    periodYear = periodYearParam || localStorage.getItem('periodYear') || new Date().getFullYear();
  }

  // Get the correct month (fallback to current month if not found)
  const periodMonth =
    periodMonthParam || localStorage.getItem('periodMonth') || (new Date().getMonth() + 1).toString().padStart(2, '0');

  return {
    periodYear: parseInt(periodYear),
    periodMonth: periodMonth.toString().padStart(2, '0'),
    period: `${periodYear}-${periodMonth}-01`,
  };
};
