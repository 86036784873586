import React from 'react';

// UTILITIES -------------------------------------------------------------------

/**
 * Capitalize the first letter of a string.
 *
 * @param {string} str - The input string.
 * @returns {string} - The input string with the first letter capitalized.
 */
export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

/**
 * Replace multiple characters in a string.
 *
 * @param {string} inputStr - The input string to process.
 * @param {Object|string} replacements - An object mapping characters to replacement React elements or a single character string to be replaced with a line break.
 * @returns {Array} - An array of strings and React elements representing the transformed input.
 *
 * @example
 * // Use:
 * const replacements = {
 *   '|': <span>-</span>,
 *   '!': <br />
 * };
 * <Typography variant="meta">
 *   {replaceMultiple(title, replacements)}
 * </Typography>
 */
export function replaceStringMultiple(inputStr, replacements) {
  const elements = [inputStr];

  if (typeof replacements === 'string') {
    const replacement = replacements;
    elements.splice(
      0,
      1,
      ...inputStr
        .split(replacement)
        .flatMap((part, index, array) =>
          index === array.length - 1 ? part : [part, React.cloneElement(<br />, { key: `${replacement}-${index}` })]
        )
    );
  } else {
    Object.entries(replacements).forEach(([char, repl]) => {
      for (let i = 0; i < elements.length; i++) {
        if (typeof elements[i] === 'string' && elements[i].includes(char)) {
          const splitParts = elements[i]
            .split(char)
            .flatMap((part, index, array) =>
              index === array.length - 1 ? part : [part, React.cloneElement(repl, { key: `${char}-${index}` })]
            );
          elements.splice(i, 1, ...splitParts);
          i += splitParts.length - 1;
        }
      }
    });
  }

  return elements;
}

/**
 * Replace characters in a string.
 *
 * @param {string} inputStr - The input string to process.
 * @param {string|Array} characters - The character(s) to be replaced in the string.
 * @param {ReactElement} replacementItem - The React element to replace the characters with.
 * @returns {Array} - An array of strings and React elements representing the transformed input.
 *
 * @example
 * // Use:
 * <Typography variant="meta">
 *   {replaceCharactersFromString(title, '|', <br />)}
 * </Typography>
 * // OR
 * <Typography variant="meta">
 *   {replaceCharactersFromString(title, ['|', '!'], <br />)}
 * </Typography>
 */
export function replaceCharactersFromString(inputStr, characters, replacementItem) {
  const elements = [inputStr];
  const chars = Array.isArray(characters) ? characters : [characters];

  chars.forEach((char) => {
    for (let i = 0; i < elements.length; i++) {
      if (typeof elements[i] === 'string' && elements[i].includes(char)) {
        const splitParts = elements[i]
          .split(char)
          .flatMap((part, index, array) =>
            index === array.length - 1 ? part : [part, React.cloneElement(replacementItem, { key: `${char}-${index}` })]
          );
        elements.splice(i, 1, ...splitParts);
        i += splitParts.length - 1;
      }
    }
  });

  return elements;
}

/**
 * Get text by position.
 *
 * @param {string} input - The input string containing text.
 * @param {string} position - The position to extract ('before' or 'after').
 * @param {string} [divider=','] - The divider to split the input string.
 * @returns {string} - The trimmed text based on the specified position.
 */
export function getTextByPosition(input, position, divider = ',') {
  if (!input || !input.includes(divider)) {
    return '';
  }

  const [before, after] = input.split(divider);

  if (position === 'before') {
    return before.trim();
  } else if (position === 'after') {
    return after.trim();
  }

  return '';
}

/**
 * Converts a string to camelCase.
 *
 * @param {string} str - The string to convert.
 * @returns {string} - The camelCase version of the string.
 */
export function toCamelCase(str) {
  return str.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
}
