import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  partners: [],
  partner: null,
};

const slice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload || 'An unexpected error occurred';
      // state.error = action.payload.message || 'An unexpected error occurred';
    },

    // GET MULTIPLE PARTNERS
    getPartnersSuccess(state, action) {
      state.isLoading = false;
      state.partners = action.payload;
    },

    // GET SINGLE PARTNER
    getPartnerSuccess(state, action) {
      state.isLoading = false;
      state.partner = action.payload;
    },

    // Reset partner data
    resetPartner(state) {
      state.partner = null;
    },

    resetPartners(state) {
      state.partners = [];
    },

    resetPartnerError(state) {
      state.error = null;
    },

    resetPartnerAll(state) {
      state.isLoading = false;
      state.error = null;
      state.partner = null;
      state.partnerSupplierNumbers = [];
    },

    resetPartnersAll(state) {
      state.isLoading = false;
      state.error = null;
      state.partners = [];
    },
  },
});

// Reset actions
export const { resetPartner, resetPartners, resetPartnerError, resetPartnerAll, resetPartnersAll } = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPartners() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/partners');
      dispatch(slice.actions.getPartnersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPartner(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.get(`/partners/${id}`).then((response) => {
        dispatch(slice.actions.getPartnerSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function createPartners(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.post('/partners/register', {}, { params: data }).then((response) => {
        console.log('response:', response);
        dispatch(slice.actions.getPartnerSuccess(response));
        return response;
      });
    } catch (error) {
      console.log('error (slice):', error);
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function updatePartners(data, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.put(`/partners/update/${id}`, {}, { params: data }).then((response) => {
        dispatch(slice.actions.getPartnerSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function deletePartner(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.delete(`/partners/delete/${id}`).then((response) => {
        dispatch(slice.actions.getPartnersSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
