import { IconSpinnerLoader } from 'src/components/icon-components';

export default function SpinnerLoader({ size = '25', color = 'action.active', speed = '1.7', style = {} }) {
  return (
    <IconSpinnerLoader
      size={size}
      color={color}
      style={{ animation: 'spin ' + speed + 's infinite linear', ...style }}
    />
  );
}
