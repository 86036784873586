import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// icons
import { Icon } from '@iconify/react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const Iconify = forwardRef(({ icon, width = 16, sx, ...other }, ref) => (
  <Box component={Icon} icon={icon} ref={ref} sx={{ width, height: width, ...sx }} {...other} />
));

Iconify.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  sx: PropTypes.instanceOf(Object),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Iconify.defaultProps = {
  icon: null,
  sx: {},
  width: 16,
};

export default Iconify;
