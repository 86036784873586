// src/components/chart/useChart.js
import merge from 'lodash/merge';
// @mui
import { useTheme, alpha } from '@mui/material/styles';
import Label from '../label';
import { textTransform } from '@mui/system';

// const customLegendItems = [
//   {
//     seriesName: 'Series 1',
//     color: 'primary',
//     text: 'Primary Series',
//   },
//   {
//     seriesName: 'Series 2',
//     color: 'secondary',
//     text: 'Secondary Series',
//   },
// ];
// ----------------------------------------------------------------------

export default function useChart(options) {
  const theme = useTheme();

  const LABEL_TOTAL = {
    show: true,
    label: 'Total',
    color: theme.palette.text.secondary,
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.subtitle2.fontWeight,
    lineHeight: theme.typography.subtitle2.lineHeight,
  };

  const LABEL_VALUE = {
    offsetY: 8,
    color: theme.palette.text.primary,
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.h3.fontWeight,
    lineHeight: theme.typography.h3.lineHeight,
  };

  const baseOptions = {
    // Colors
    colors: [
      theme.palette.warning.main,
      theme.palette.secondary.light,
      theme.palette.info.main,
      theme.palette.error.light,
      theme.palette.success.light,
      theme.palette.warning.darker,
      theme.palette.secondary.main,
      theme.palette.info.light,
      theme.palette.error.darker,
      theme.palette.success.darker,
    ],

    // Chart
    chart: {
      toolbar: { show: true },
      zoom: { enabled: false },
      // animations: { enabled: false },
      foreColor: theme.palette.text.disabled,
      fontFamily: theme.typography.fontFamily,
    },

    // States
    states: {
      hover: {
        filter: {
          type: 'darken', // darken, lighten, none
          value: 0.9,
        },
      },
      active: {
        filter: {
          type: 'none',
        },
      },
    },

    // Fill
    fill: {
      opacity: 1,
      gradient: {
        type: 'vertical',
        shadeIntensity: 0,
        opacityFrom: 0.4,
        opacityTo: 0,
        stops: [0, 100],
      },
    },

    // Datalabels
    dataLabels: { enabled: false },

    // Stroke
    stroke: {
      width: 3,
      curve: 'smooth',
      lineCap: 'round',
    },

    // Grid
    grid: {
      strokeDashArray: 3,
      borderColor: alpha(theme.palette.grey[800], 0.18),
    },

    // Yaxis
    yaxis: {
      labels: {
        style: {
          // fontSize: theme.typography.body2.fontSize,
          ...theme.typography.tableHead,
          textTransform: 'uppercase!important',
          cssClass: 'rootlytics-chart-label',
        },
      },
    },

    // Xaxis
    xaxis: {
      axisBorder: { show: false },
      axisTicks: { show: false },
      labels: {
        style: {
          // fontSize: theme.typography.body2.fontSize,
          ...theme.typography.tableHead,
          textTransform: 'uppercase!important',
          cssClass: 'rootlytics-chart-label',
        },
      },
    },

    // Markers
    markers: {
      size: 0,
      strokeColors: theme.palette.background.paper,
    },

    // Tooltip
    tooltip: {
      // shared: true,
      // intersect: false,
      // x: {
      //   show: false,
      // },
      // y: {
      //   formatter: undefined,
      //   title: {
      //     formatter: (seriesName) => seriesName,
      //   },
      // },
    },

    //   // custom: function ({ series, seriesIndex, dataPointIndex, w }) {
    //   //   return '<div class="arrow_box">' + '<span>' + series[seriesIndex][dataPointIndex] + '</span>' + '</div>';
    //   // },
    //   // z: {
    //   //   title: 'Test',
    //   // },
    //   // marker: {
    //   //   show: false,
    //   // },
    //   // items: {
    //   //   display: 'flex',
    //   // },
    // },

    // Legend
    legend: {
      show: true,
      fontSize: String(13),
      position: 'top',
      horizontalAlign: 'right',
      markers: {
        // shape: undefined, // circle, square, line, plus, cross
        // strokeWidth: 1.5,
        // size: 3,
        radius: 12,
      },
      fontWeight: 500,
      itemMargin: { horizontal: 12 },
      labels: {
        colors: theme.palette.text.secondary,
        useSeriesColors: false,
      },
    },

    // plotOptions
    plotOptions: {
      // Bar
      bar: {
        borderRadius: 4,
        columnWidth: '28%',
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
        states: {
          hover: {
            filter: {
              type: 'darken', // available options: 'none', 'lighten', 'darken'
              value: 1, // value of the filter (from 0 to 1)
            },
          },
        },
      },

      // Pie + Donut
      pie: {
        donut: {
          labels: {
            show: true,
            value: LABEL_VALUE,
            total: LABEL_TOTAL,
          },
        },
      },

      // Radialbar
      radialBar: {
        track: {
          strokeWidth: '100%',
          background: alpha(theme.palette.grey[500], 0.16),
        },
        dataLabels: {
          value: LABEL_VALUE,
          total: LABEL_TOTAL,
        },
      },

      // Radar
      radar: {
        polygons: {
          fill: { colors: ['transparent'] },
          strokeColors: theme.palette.divider,
          connectorColors: theme.palette.divider,
        },
      },

      // polarArea
      polarArea: {
        rings: {
          strokeColor: theme.palette.divider,
        },
        spokes: {
          connectorColors: theme.palette.divider,
        },
      },
    },

    // Responsive
    responsive: [
      {
        // sm
        breakpoint: theme.breakpoints.values.sm,
        options: {
          plotOptions: { bar: { columnWidth: '40%' } },
        },
      },
      {
        // md
        breakpoint: theme.breakpoints.values.md,
        options: {
          plotOptions: { bar: { columnWidth: '32%' } },
        },
      },
    ],
  };

  return merge(baseOptions, options);
}
