// ----------------------------------------------------------------------

export default function Typography(theme) {
  return {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'tableHead' },
          style: {
            ...theme.typography.tableHead,
          },
        },
      ],
      styleOverrides: {
        paragraph: {
          marginBottom: theme.spacing(2),
        },
        gutterBottom: {
          marginBottom: theme.spacing(1),
        },
      },
    },
  };
}
