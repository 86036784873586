import PropTypes from 'prop-types';
import { m, AnimatePresence } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import { IconButton, Stack, Typography } from '@mui/material';
// utils
import { fData } from '../../../utils/formatNumber';
//
import Iconify from '../../iconify';
import { varFade } from '../../animate';
import FileThumbnail, { fileData } from '../../file-thumbnail';

// ----------------------------------------------------------------------

MultiFilePreview.propTypes = {
  files: PropTypes.instanceOf(Array),
  onRemove: PropTypes.func,
  sx: PropTypes.instanceOf(Object),
  thumbnail: PropTypes.bool,
};

MultiFilePreview.defaultProps = {
  files: [],
  onRemove: null,
  sx: {},
  thumbnail: false,
};

export default function MultiFilePreview({ thumbnail, files, onRemove, sx }) {
  if (!files?.length) {
    return null;
  }

  return (
    <AnimatePresence initial={false}>
      {files.map((file) => {
        const { key, name = '', size = 0 } = fileData(file);

        const isNotFormatFile = typeof file === 'string';

        if (thumbnail) {
          return (
            <Stack
              component={m.div}
              key={key}
              {...varFade().inUp}
              alignItems="center"
              display="inline-flex"
              justifyContent="center"
              sx={{
                m: 0.5,
                width: 80,
                height: 80,
                borderRadius: 1.25,
                overflow: 'hidden',
                position: 'relative',
                border: (theme) => `solid 1px ${theme.palette.divider}`,
                ...sx,
              }}
            >
              <FileThumbnail
                file={file}
                imageView
                imgSx={{ position: 'absolute' }}
                sx={{ position: 'absolute' }}
                tooltip
              />

              {onRemove ? (
                <IconButton
                  onClick={() => onRemove(file)}
                  size="small"
                  sx={{
                    top: 4,
                    right: 4,
                    p: '1px',
                    position: 'absolute',
                    color: (theme) => alpha(theme.palette.common.white, 0.72),
                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                    '&:hover': {
                      bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                    },
                  }}
                >
                  <Iconify icon="eva:close-fill" width={16} />
                </IconButton>
              ) : null}
            </Stack>
          );
        }

        return (
          <Stack
            component={m.div}
            key={key}
            {...varFade().inUp}
            alignItems="center"
            direction="row"
            spacing={2}
            sx={{
              my: 1,
              px: 1,
              py: 0.75,
              borderRadius: 0.75,
              border: (theme) => `solid 1px ${theme.palette.divider}`,
              ...sx,
            }}
          >
            <FileThumbnail file={file} />

            <Stack flexGrow={1} sx={{ minWidth: 0 }}>
              <Typography noWrap variant="subtitle2">
                {isNotFormatFile ? file : name}
              </Typography>

              <Typography sx={{ color: 'text.secondary' }} variant="caption">
                {isNotFormatFile ? '' : fData(size)}
              </Typography>
            </Stack>

            {onRemove ? (
              <IconButton edge="end" onClick={() => onRemove(file)} size="small">
                <Iconify icon="eva:close-fill" />
              </IconButton>
            ) : null}
          </Stack>
        );
      })}
    </AnimatePresence>
  );
}
