import PropTypes from 'prop-types';
import { memo } from 'react';
// @mui
import { Box, Stack } from '@mui/material';
//
import NavList from './NavList';

// ----------------------------------------------------------------------

NavSectionMini.propTypes = {
  data: PropTypes.instanceOf(Array),
  sx: PropTypes.instanceOf(Object),
};

NavSectionMini.defaultProps = {
  data: [],
  sx: {},
};

function NavSectionMini({ data, sx, ...other }) {
  return (
    <Stack
      alignItems="center"
      spacing={0.5}
      sx={{
        px: 0.75,
        ...sx,
      }}
      {...other}
    >
      {data.map((group, index) => (
        <Items isLastGroup={index + 1 === data.length} items={group.items} key={Math.random() + 1} />
      ))}
    </Stack>
  );
}

export default memo(NavSectionMini);

// ----------------------------------------------------------------------

Items.propTypes = {
  isLastGroup: PropTypes.bool,
  items: PropTypes.instanceOf(Array),
};

Items.defaultProps = {
  isLastGroup: false,
  items: [],
};

function Items({ items, isLastGroup }) {
  return (
    <>
      {items.map((list) => (
        <NavList data={list} depth={1} hasChild={!!list.children} key={list.title + list.path} />
      ))}

      {!isLastGroup && (
        <Box
          sx={{
            width: 24,
            height: '1px',
            bgcolor: 'divider',
            my: '8px !important',
          }}
        />
      )}
    </>
  );
}
