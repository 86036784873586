/**
 * @file src/components/PeriodPicker.js
 *
 * @description
 * The `PeriodPicker` component allows users to select a specific period (year and month)
 * from a custom UI. It includes navigation between periods, resetting to the current period,
 * and displaying information regarding the selected period.
 */

// IMPORTS
// Core
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// Redux
import { useSelector, useDispatch } from 'react-redux';
// MUI
import { Stack, Box, Button, Card, Collapse, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { useTheme, alpha } from '@mui/system';
// Routing
import { useLocation, useNavigate } from 'react-router';
// Utilities
import { fMonth } from 'src/utils/formatTime';
import { getInitialPeriodState } from 'src/utils/periodUtils';
// Hooks
import useResponsive from 'src/hooks/useResponsive';
// Data
import { months } from 'src/assets/data';
// Redux Actions
import { setPeriod, setPeriodYear, setPeriodMonth } from 'src/redux/slices/period';
// Custom Components
import {
  IconCalendarBlankLight,
  IconCaretLeftLight,
  IconCaretRightLight,
  IconInfoLight,
} from 'src/components/icon-components-ph';
import CustomDivider from 'src/components/divider/CustomDivider';
import SpinnerLoader from 'src/components/loaders/SpinnerLoader';
import TooltipIconPH from 'src/components/tooltip-icon/TooltipIconPH';
import { IconReset } from 'src/components/icon-components';
// Sections
import PeriodForm from './PeriodForm';

// CONFIGURATION ---------------------------------------------------------------
const CONTENT_HEIGHT = 48;
const TOP_OFFSET = '6px';
const ICON_SIZE = '20px';

/**
 * @component PeriodPicker
 *
 * @description
 * The `PeriodPicker` component allows users to select a specific period (year and month)
 * from a custom UI. It includes navigation between periods, resetting to the current period,
 * and displaying information regarding the selected period.
 *
 * @param {boolean} fullYear - If true, the component will only allow selecting a year.
 *
 * @example
 * <PeriodPicker fullYear={true} />
 */

// PROPS -----------------------------------------------------------------------
PeriodPicker.propTypes = {
  fullYear: PropTypes.bool,
  isRootlytics: PropTypes.bool,
};
PeriodPicker.defaultProps = {
  fullYear: false,
  isRootlytics: false,
};

// ⭐️ MAIN FUNCTION ⭐️ **********************************************************
export function PeriodPicker({ fullYear, isRootlytics }) {
  const { periodYear, periodMonth, periodMessage } = useSelector((state) => state.period);
  const [anchorElYear, setAnchorElYear] = useState(null);
  const [anchorElMonth, setAnchorElMonth] = useState(null);
  const [periodFormAnchorEl, setPeriodFormAnchorEl] = useState(null);

  const { isLoading: isLoadingInvoices } = useSelector((state) => state.invoice);
  const { isLoading: isLoadingClients } = useSelector((state) => state.client);
  const { isLoading: isLoadingPlanned } = useSelector((state) => state.timeLog);
  const { isLoading: isLoadingConsultant } = useSelector((state) => state.consultant);
  const { isLoading: isLoadingReports } = useSelector((state) => state.reports);

  const [isLoading, setIsLoading] = useState(
    isLoadingInvoices || isLoadingClients || isLoadingPlanned || isLoadingConsultant || isLoadingReports
  );

  // REFS
  const cardRef = useRef(null);

  // CONTEXTS
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isHandheldScreen = useResponsive('down', 'md');

  // DERIVED CONSTANTS ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const isCurrentDate = periodYear == currentYear && (fullYear || periodMonth == currentMonth);
  const years = Array.from({ length: 4 }, (_, i) => currentYear - i + 1);
  const bgColor = theme.palette.secondary.main;
  const bgHoverColor = theme.palette.secondary.dark;
  const bgTransistion = 'background-color 0.2s ease-in-out';
  const lineColor = theme.palette.primary.main;
  const lineHoverColor = theme.palette.primary.main;
  const notThisMonthMessage = fullYear
    ? 'The selected year is not the current year'
    : 'The selected period is not the current period';

  const buttonStyle = {
    height: CONTENT_HEIGHT,
    p: 0,
    pt: TOP_OFFSET,
    transition: bgTransistion,
    '.MuiTypography-root': {
      borderBottom: '1.5px solid transparent',
      transition: 'border-color 0.2s ease-in-out',
    },
    background: 'transparent',
    '&:hover': {
      background: 'transparent',
      '.MuiTypography-root': {
        borderBottomColor: lineHoverColor,
      },
    },
  };

  const caretButtonStyle = {
    color: lineColor,
    '&&': {
      px: '5px',
      height: CONTENT_HEIGHT,
      transition: bgTransistion,
      background: 'transparent',
      '&:hover': {
        background: 'transparent',
        color: lineHoverColor,
        '& .MuiBox-root': { background: bgHoverColor },
      },
      '& .MuiBox-root': { transition: bgTransistion, borderRadius: '50%', padding: '3px' },
    },
  };

  const resetButtonStyle = {
    color: alpha(theme.palette.common.white, 0.74),
    '&:hover': {
      color: lineColor,
    },
  };

  // EFFECTS ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
  // Handle loading state based on various loading conditions
  useEffect(() => {
    setIsLoading(isLoadingInvoices || isLoadingClients || isLoadingPlanned || isLoadingConsultant || isLoadingReports);
  }, [isLoadingInvoices, isLoadingClients, isLoadingPlanned, isLoadingConsultant, isLoadingReports]);

  // This effect ensures that the periodYear and periodMonth are updated whenever the location changes
  useEffect(() => {
    // Initialize or reset periodYear and periodMonth based on the current page
    const initialState = getInitialPeriodState();
    dispatch(setPeriodYear(initialState.periodYear));
    dispatch(setPeriodMonth(initialState.periodMonth));
  }, [dispatch, location.pathname]);

  // Update URL query parameters and local storage based on selected period
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    // Determine which year parameter and storage item to use
    const yearParam = isRootlytics ? 'rootlytics_year' : 'year';
    const yearStorageItemName = isRootlytics ? 'rootlyticsYear' : 'periodYear';

    // Set the year in query parameters and localStorage
    queryParams.set(yearParam, periodYear);
    localStorage.setItem(yearStorageItemName, periodYear);

    // Handle the month parameter only if not dealing with a full year
    if (!fullYear) {
      queryParams.set('month', periodMonth);
      localStorage.setItem('periodMonth', periodMonth);
    } else {
      queryParams.delete('month'); // Clean up month param if it's a full year
    }

    // Remove the irrelevant year parameter
    if (isRootlytics) {
      queryParams.delete('year');
    } else {
      queryParams.delete('rootlytics_year');
    }

    // Update the URL with the correct query parameters
    navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
  }, [periodMonth, periodYear, isRootlytics, fullYear, navigate, location.pathname]);

  // HANDLERS  ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
  const handlePreviousPeriod = () => {
    let newYear = periodYear;
    let newMonth = parseInt(periodMonth, 10) - (fullYear ? 0 : 1);

    if (fullYear) {
      newYear -= 1;
    } else {
      if (newMonth < 1) {
        newYear -= 1;
        newMonth = 12;
      }
      dispatch(setPeriodMonth(newMonth));
    }

    const newStartDate = `${newYear}-${newMonth.toString().padStart(2, '0')}-01`;

    dispatch(setPeriod(newStartDate));
    dispatch(setPeriodYear(newYear));
  };

  const handleNextPeriod = () => {
    let newYear = periodYear;
    let newMonth = parseInt(periodMonth, 10) + (fullYear ? 0 : 1);

    if (fullYear) {
      newYear += 1;
    } else {
      if (newMonth > 12) {
        newYear += 1; // Move forward one year
        newMonth = 1; // Set to January
      }
      dispatch(setPeriodMonth(newMonth));
    }

    dispatch(setPeriodYear(newYear));
    dispatch(setPeriod(newYear + '-' + newMonth.toString().padStart(2, '0') + '-01'));
  };

  const handleYearChange = (year) => {
    dispatch(setPeriod(year + '-' + periodMonth.toString().padStart(2, '0') + '-01'));
    dispatch(setPeriodYear(year));
    setAnchorElYear(null);
  };

  const handleMonthChange = (month) => {
    dispatch(setPeriod(periodYear + '-' + month.toString().padStart(2, '0') + '-01'));
    dispatch(setPeriodMonth(month));
    setAnchorElMonth(null);
  };

  const handleCloseMonth = () => {
    setAnchorElMonth(null);
  };

  const handleCloseYear = () => {
    setAnchorElYear(null);
  };

  const handlePeriodFormClick = () => {
    setPeriodFormAnchorEl(cardRef.current);
  };

  const handlePeriodFormClose = () => {
    setPeriodFormAnchorEl(null);
  };

  const handleResetPeriod = () => {
    const newStartDate = `${currentYear}-01-01`;
    const newEndDate = `${currentYear}-12-01`;
    dispatch(setPeriod(newStartDate));
    dispatch(setPeriodYear(currentYear));
    dispatch(setPeriodMonth(1));
  };

  // 🚀 MAIN RENDER 🚀 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  return (
    <Stack direction="row" alignItems="center">
      <Card ref={cardRef} sx={{ background: bgColor, p: 0 }}>
        <Stack direction="row" alignItems="center" spacing={0}>
          <Button onClick={handlePreviousPeriod} sx={{ ...caretButtonStyle }}>
            <IconCaretLeftLight size={ICON_SIZE} />
          </Button>

          <Stack direction="column" justifyContent="center" spacing={0} sx={{ position: 'relative' }}>
            <Typography variant="labelXS" color="textLight.secondary" sx={{ position: 'absolute', top: '8px' }}>
              {fullYear ? 'Year' : 'Period'}
            </Typography>

            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={0}>
              <Stack direction="row" alignItems="center" style={{ width: '48px' }}>
                <Button disabled={isLoading} sx={{ ...buttonStyle }} onClick={(e) => setAnchorElYear(e.currentTarget)}>
                  <Typography variant="buttonXXL" color="textLight.primary">
                    {periodYear}
                  </Typography>
                </Button>

                {!fullYear && (
                  <Typography variant="buttonXXL" color="textLight.primary" sx={{ pt: TOP_OFFSET }}>
                    ,
                  </Typography>
                )}
              </Stack>

              {!fullYear && (
                <Stack sx={{ width: '38.5px' }}>
                  <Button
                    disabled={isLoading}
                    sx={{ ...buttonStyle, justifyContent: 'flex-end' }}
                    onClick={(e) => setAnchorElMonth(e.currentTarget)}
                  >
                    <Typography variant="buttonXXL" color="textLight.primary">
                      {fMonth(new Date(periodYear, periodMonth - 1), 'MMM')}
                    </Typography>
                  </Button>
                </Stack>
              )}
            </Stack>
          </Stack>

          <Button onClick={handleNextPeriod} sx={{ ...caretButtonStyle }}>
            <IconCaretRightLight size={ICON_SIZE} />
          </Button>

          {/* Reset Button */}
          <Collapse
            in={!isCurrentDate}
            timeout="auto"
            unmountOnExit
            sx={{
              ml: '-8px',
              '& .MuiCollapse-wrapperInner': { display: 'flex', flexDirection: 'row', alignItems: 'center' },
            }}
          >
            <Tooltip title="Reset period to current month" arrow>
              <Button
                disabled={isLoading}
                onClick={handleResetPeriod}
                sx={{ ...caretButtonStyle, ...resetButtonStyle }}
              >
                <IconReset size={'18px'} />
              </Button>
            </Tooltip>
          </Collapse>

          <CustomDivider orientation="vertical" flexItem />

          {/* Dropdown Form Toggle Button */}
          {!fullYear && (
            <Button
              aria-controls="period-form-menu"
              aria-haspopup="true"
              onClick={handlePeriodFormClick}
              disabled={isLoading}
              sx={{ ...caretButtonStyle, px: isLoading ? '3px!important' : '5px' }}
            >
              {isLoading ? <SpinnerLoader color="primary.main" /> : <IconCalendarBlankLight size="21px" />}
            </Button>
          )}

          {/* Dropdown Form */}
          {!fullYear && (
            <PeriodForm
              anchorEl={periodFormAnchorEl}
              open={Boolean(periodFormAnchorEl)}
              onClose={handlePeriodFormClose}
              years={years}
              theme={theme}
              fullYear={fullYear}
            />
          )}
        </Stack>

        <Menu
          anchorEl={anchorElYear}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={Boolean(anchorElYear)}
          onClose={handleCloseYear}
        >
          {years.map((year) => (
            <MenuItem
              key={year}
              selected={year === periodYear}
              onClick={() => handleYearChange(year)}
              sx={{ ...theme.typography.body2 }}
            >
              {year}
            </MenuItem>
          ))}
        </Menu>

        <Menu
          anchorEl={anchorElMonth}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={Boolean(anchorElMonth)}
          onClose={handleCloseMonth}
          PaperProps={{
            style: {
              width: 'fit-content',
            },
          }}
          sx={{ '& .MuiList-root': { display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' } }}
        >
          {months.map((month) => (
            <MenuItem
              key={month.value}
              selected={month.value === periodMonth}
              onClick={() => handleMonthChange(month.value)}
              style={{
                width: '100%',
                justifyContent: 'center',
                ...theme.typography.body2,
              }}
            >
              {month.name}
            </MenuItem>
          ))}
        </Menu>
      </Card>

      <Collapse
        in={periodMessage}
        timeout="auto"
        unmountOnExit
        sx={{
          ml: 1,
          '& .MuiCollapse-wrapperInner': { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 },
          color: theme.palette.text.secondary,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            p: 0.25,
            borderRadius: '50%',
            background: theme.palette.info.main,
          }}
        >
          <TooltipIconPH title={periodMessage} icon="IconInfoLight" color="primary.main" />
        </Box>
      </Collapse>

      <Collapse
        in={!isCurrentDate}
        timeout="auto"
        unmountOnExit
        sx={{
          ml: 1,
          '& .MuiCollapse-wrapperInner': { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 },
          color: theme.palette.text.secondary,
        }}
      >
        <Stack
          direction="row"
          gap={1}
          sx={{
            p: 1,
            background: theme.palette.info.lighter,
            color: theme.palette.info.darker,
            borderRadius: theme.shape.borderRadius,
          }}
        >
          {!isHandheldScreen ? (
            <>
              <IconInfoLight size={'19px'} /> <Typography variant="meta">{notThisMonthMessage}</Typography>
            </>
          ) : (
            <Tooltip title={notThisMonthMessage}>
              <IconInfoLight size={'19px'} />
            </Tooltip>
          )}
        </Stack>
      </Collapse>
    </Stack>
  );
}
