import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  channels: [],
  channel: null,
};

const slice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getChannelsSuccess(state, action) {
      state.isLoading = false;
      state.channels = action.payload;
    },

    // GET PRODUCT
    getChannelSuccess(state, action) {
      state.isLoading = false;
      state.channel = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getChannels() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/channels');
      dispatch(slice.actions.getChannelsSuccess(response.data));
      dispatch(slice.actions.hasError(null));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getChannel(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/channels/${id}`);
      dispatch(slice.actions.getChannelSuccess(response.data));
      dispatch(slice.actions.hasError(null));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createChannels(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.post('/channels/register', {}, { params: data }).then((response) => {
        dispatch(slice.actions.getChannelSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function updateChannels(data, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.put(`/channels/update/${id}`, {}, { params: data }).then((response) => {
        dispatch(slice.actions.getChannelSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteChannels(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.delete(`/channels/delete/${id}`).then((response) => {
        dispatch(slice.actions.getChannelsSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function getChannelServices(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/channels/${id}/services`);
      dispatch(slice.actions.getChannelSuccess(response.data));
      dispatch(slice.actions.hasError(null));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getClientChannels(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/clients/${id}/channels`);
      dispatch(slice.actions.getChannelsSuccess(response.data));
      dispatch(slice.actions.hasError(null));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addClientToChannel(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios
        .post(`/channels/${data.channel_id}/attach/client/${data.client_id}`, {}, { params: data })
        .then((response) => {
          dispatch(slice.actions.getChannelsSuccess(response.data));
          dispatch(slice.actions.hasError(null));

          return response;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function updateClientToChannel(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios
        .put(`/channels/${data.channel_id}/client/${data.client_id}/update`, {}, { params: data })
        .then((response) => {
          dispatch(slice.actions.getChannelsSuccess(response.data));
          dispatch(slice.actions.hasError(null));

          return response;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function detachClientChannel(data) {
  console.log(data, 'data');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios
        .post(`/channels/${data.channel_id}/detach/client/${data.client_id}`, {}, { params: data })
        .then((response) => {
          dispatch(slice.actions.getChannelsSuccess(response.data));
          dispatch(slice.actions.hasError(null));

          return response;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
