import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  contacts: [],
  contact: null,
};

const slice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET contants
    getContactsSuccess(state, action) {
      state.isLoading = false;
      state.contacts = action.payload;
    },

    // GET contant
    getContactSuccess(state, action) {
      state.isLoading = false;
      state.contact = action.payload;
    },
    getContactSuccessForm(state, action) {
      state.isLoading = false;
      state.contact = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getContacts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/contacts');
      dispatch(slice.actions.getContactsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createContact(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      return await axios.post('/contacts/register', {}, { params: data }).then((response) => {
        dispatch(slice.actions.getContactSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function getContact(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/contacts/${name}`);
      dispatch(slice.actions.getContactSuccessForm(response.data));
      //return response;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateContact(data, name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.put(`/contacts/update/${name}`, {}, { params: data }).then((response) => {
        dispatch(slice.actions.getContactSuccess(response.data.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteContact(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.delete(`/contacts/delete/${id}`).then((response) => {
        dispatch(slice.actions.getContactsSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
