import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, alpha } from '@mui/material';
//
import { StyledLabel } from './styles';

// ----------------------------------------------------------------------

const Label = forwardRef(
  ({ children, color = 'default', variant = 'soft', startIcon, endIcon, iconSize, size = 'md', sx, ...other }, ref) => {
    const theme = useTheme();

    const iconStyle = {
      width: iconSize,
      height: iconSize,
      '& svg, img': { width: 1, height: 1, objectFit: 'cover' },
    };

    return (
      <StyledLabel
        component="span"
        ownerState={{ color, variant }}
        ref={ref}
        sx={{
          ...(startIcon && { pl: size == 'xs' ? 0.25 : 0.75 }),
          ...(endIcon && { pr: size == 'xs' ? 0.25 : 0.75 }),
          ...(size == 'xs'
            ? theme.typography.labelXS
            : size == 'sm'
            ? theme.typography.labelSM
            : theme.typography.label),
          padding:
            variant == 'ghost'
              ? '0'
              : size == 'md'
              ? '0, 8px'
              : size == 'xs'
              ? '0.3rem 0.4rem'
              : size == 'sm'
              ? '0.4rem .7rem'
              : '0.5rem 1rem',
          height: size == 'xs' ? 'fit-content' : size == 'sm' ? 'fit-content' : 24,
          backgroundColor:
            variant === 'soft' && (color === 'primary' || color === 'warning')
              ? alpha(theme.palette[color]?.light, 0.7)
              : '',
          color: variant === 'soft' && (color === 'primary' || color === 'warning') ? theme.palette[color]?.darker : '',
          ...sx,
          // color: theme.palette[color]?.darker || theme.palette.text.primary,
          // backgroundColor: variant === 'ghost' ? 'transparent' : theme.palette[color]?.lighter,
        }}
        theme={theme}
        {...other}
      >
        {startIcon ? <Box sx={{ mr: size == 'xs' ? 0.25 : 0.75, ...iconStyle }}> {startIcon} </Box> : null}

        {children}

        {endIcon ? <Box sx={{ ml: size == 'xs' ? 0.25 : 0.75, ...iconStyle }}> {endIcon} </Box> : null}
      </StyledLabel>
    );
  }
);

Label.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'info', 'success', 'warning', 'error']),
  endIcon: PropTypes.node,
  startIcon: PropTypes.node,
  iconSize: PropTypes.number,
  sx: PropTypes.instanceOf(Object),
  variant: PropTypes.oneOf(['filled', 'outlined', 'ghost', 'soft']),
};

Label.defaultProps = {
  children: null,
  color: 'default',
  endIcon: null,
  startIcon: null,
  iconSize: 16,
  sx: {},
  variant: 'soft',
};

export default Label;
