import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const IconNoteDescriptionDocument = ({ size = 21, color = 'currentColor', style, ...restProps }) => {
  const theme = useTheme();

  const getColor = (colorName) => {
    const colorParts = colorName.split('.');
    let colorValue = theme.palette[colorParts[0]];
    for (let i = 1; i < colorParts.length; i++) {
      colorValue = colorValue[colorParts[i]];
    }
    return colorValue;
  };

  const strokeColor = color === 'currentColor' ? color : getColor(color);

  return (
    <Box sx={style} display="flex">
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 21 21">
        <defs>
          <path
            id="systemUiconsDocumentJustified0"
            d="M16.5 15.5v-10a2 2 0 0 0-2-2h-8a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zm-9-8h6m-6 3h6m-6 3h6"
          />
        </defs>
        <g fill="none" fillRule="evenodd" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round">
          <use href="#systemUiconsDocumentJustified0" />
          <use href="#systemUiconsDocumentJustified0" />
        </g>
      </svg>
    </Box>
  );
};

export default IconNoteDescriptionDocument;
