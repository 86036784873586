import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import {
  Box,
  Chip,
  Select,
  Checkbox,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  OutlinedInput,
  FormHelperText,
  Typography,
} from '@mui/material';
import React from 'react';

// ----------------------------------------------------------------------

const errorStyles = {
  '& .MuiFormLabel-root': {
    color: 'error.main',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'error.main',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'error.main',
  },
};

RHFSelect.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.node,
  maxHeight: PropTypes.number,
  native: PropTypes.bool,
  placeholder: PropTypes.string,
  showErrorStyleOnEmpty: PropTypes.bool,
  sx: PropTypes.instanceOf(Object),
};

RHFSelect.defaultProps = {
  children: null,
  name: '',
  label: '',
  helperText: '',
  maxHeight: 220,
  native: false,
  placeholder: 'Select an option',
  showErrorStyleOnEmpty: false,
  sx: {},
};

export function RHFSelect({
  name,
  label,
  native,
  children,
  helperText,
  maxHeight = 220,
  placeholder,
  required,
  showErrorStyleOnEmpty,
  sx,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          label={required ? `${label} *` : label}
          error={!!error}
          fullWidth
          helperText={error ? error?.message : helperText}
          select
          variant="standard"
          sx={{
            ...(!field.value && showErrorStyleOnEmpty ? errorStyles : null),
            ...sx,
          }}
          SelectProps={{
            native,
            displayEmpty: true, // This prop makes sure the placeholder is displayed even when the value is empty
            renderValue: (value) =>
              placeholder && !label && (value === undefined || value === null || value === '') ? (
                <Box component="span" sx={{ color: 'text.disabled' }}>
                  {placeholder}
                </Box>
              ) : (
                // Ensure children's labels are rendered correctly, including falsy values
                React.Children.toArray(children).find((child) => child.props.value === value)?.props.children || value
              ),
            MenuProps: {
              PaperProps: {
                sx: {
                  ...(!native && {
                    px: 1,
                    maxHeight: typeof maxHeight === 'number' ? maxHeight : 'unset',
                    '& .MuiMenuItem-root': {
                      px: 1,
                      borderRadius: 0.75,
                      typography: 'body2',
                      // textTransform: 'capitalize',
                    },
                  }),
                },
              },
            },
            // sx: {
            //   textTransform: 'capitalize',
            // },
          }}
          {...other}
        >
          {children}
        </TextField>
      )}
    />
  );
}

export function RHFSelectWithCustomRender({
  name,
  native,
  children,
  helperText,
  maxHeight = 220,
  renderValue,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          error={!!error}
          fullWidth
          helperText={error ? error?.message : helperText}
          variant="standard"
          select
          SelectProps={{
            native,
            renderValue,
            MenuProps: {
              PaperProps: {
                sx: {
                  ...(!native && {
                    px: 1,
                    maxHeight: typeof maxHeight === 'number' ? maxHeight : 'unset',
                    '& .MuiMenuItem-root': {
                      px: 1,
                      borderRadius: 0.75,
                      typography: 'body2',
                      // textTransform: 'capitalize',
                    },
                  }),
                },
              },
            },
            // sx: { textTransform: 'capitalize' },
          }}
          {...other}
        >
          {children}
        </TextField>
      )}
    />
  );
}
// ----------------------------------------------------------------------

RHFMultiSelect.propTypes = {
  checkbox: PropTypes.bool,
  chip: PropTypes.bool,
  helperText: PropTypes.node,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  placeholder: PropTypes.string,
  sx: PropTypes.instanceOf(Object),
};

RHFMultiSelect.defaultProps = {
  checkbox: false,
  chip: false,
  helperText: '',
  label: '',
  name: '',
  options: [],
  placeholder: '',
  sx: {},
};

export function RHFMultiSelect({ name, chip, label, options, checkbox, placeholder, helperText, sx, ...other }) {
  const { control } = useFormContext();

  const renderValues = (selectedIds) => {
    const selectedItems = options.filter((item) => selectedIds.includes(item.value));

    if (!selectedItems.length && placeholder) {
      return (
        <Box component="em" sx={{ color: 'text.disabled' }}>
          {placeholder}
        </Box>
      );
    }

    if (chip) {
      return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selectedItems.map((item) => (
            <Chip key={item.value} label={item.label} size="small" />
          ))}
        </Box>
      );
    }

    return selectedItems.map((item) => item.label).join(', ');
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl sx={sx} variant="standard">
          {label ? <InputLabel id={name}> {label} </InputLabel> : null}

          <Select
            {...field}
            MenuProps={{
              PaperProps: {
                sx: { px: 1, maxHeight: 280 },
              },
            }}
            displayEmpty={!!placeholder}
            input={<OutlinedInput error={!!error} fullWidth label={label} />}
            labelId={name}
            multiple
            renderValue={renderValues}
            {...other}
          >
            {placeholder ? (
              <MenuItem
                disabled
                sx={{
                  py: 1,
                  px: 2,
                  borderRadius: 0.75,
                  typography: 'body2',
                }}
                value=""
              >
                <em> {placeholder} </em>
              </MenuItem>
            ) : null}

            {options.map((option) => {
              const selected = field.value.includes(option.value);

              return (
                <MenuItem
                  key={option.value}
                  sx={{
                    py: 1,
                    px: 2,
                    borderRadius: 0.75,
                    typography: 'body2',
                    ...(selected && {
                      fontWeight: 'fontWeightMedium',
                    }),
                    ...(checkbox && {
                      p: 0.25,
                    }),
                  }}
                  value={option.value}
                >
                  {checkbox ? <Checkbox checked={selected} disableRipple size="small" /> : null}

                  {option.label}
                </MenuItem>
              );
            })}
          </Select>

          {!!error || helperText ? (
            <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
          ) : null}
        </FormControl>
      )}
    />
  );
}
