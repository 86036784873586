import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import { Box } from '@mui/material';
//
import { varFade } from './variants';

// ----------------------------------------------------------------------

TextAnimate.propTypes = {
  sx: PropTypes.instanceOf(Object),
  text: PropTypes.string,
  variants: PropTypes.instanceOf(Object),
};

TextAnimate.defaultProps = {
  sx: {},
  text: '',
  variants: null,
};

export default function TextAnimate({ text, variants, sx, ...other }) {
  return (
    <Box
      component={m.div}
      sx={{
        m: 0,
        typography: 'h1',
        overflow: 'hidden',
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      {text.split('').map((letter) => (
        <m.span key={Math.random() + 1} variants={variants || varFade().inUp}>
          {letter}
        </m.span>
      ))}
    </Box>
  );
}
