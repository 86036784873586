import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const IconInvoiceInvoicing = ({ size = 21, color = 'currentColor', style, ...restProps }) => {
  const theme = useTheme();

  const getColor = (colorName) => {
    const colorParts = colorName.split('.');
    let colorValue = theme.palette[colorParts[0]];
    for (let i = 1; i < colorParts.length; i++) {
      colorValue = colorValue[colorParts[i]];
    }
    return colorValue;
  };

  const strokeColor = color === 'currentColor' ? color : getColor(color);

  return (
    <Box sx={style} display="flex">
      <svg width={size} height={size} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clip-rule="evenodd"
          d="M16.5 15.5V5.5C16.5 4.96957 16.2893 4.46086 15.9142 4.08579C15.5391 3.71071 15.0304 3.5 14.5 3.5H6.5C5.96957 3.5 5.46086 3.71071 5.08579 4.08579C4.71071 4.46086 4.5 4.96957 4.5 5.5V15.5C4.5 16.0304 4.71071 16.5391 5.08579 16.9142C5.46086 17.2893 5.96957 17.5 6.5 17.5H14.5C15.0304 17.5 15.5391 17.2893 15.9142 16.9142C16.2893 16.5391 16.5 16.0304 16.5 15.5Z"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.9377 8.5H13.7273M12.9377 10.5H13.7273M12.9377 12.4999H13.7273"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.96759 14.4509V6.54907H9.6569V14.4509H8.96759ZM9.22818 13.5262C8.76865 13.5262 8.32872 13.4617 7.90842 13.3329C7.48811 13.1984 7.15466 13.0274 6.90808 12.8201L7.28636 11.9711C7.52173 12.156 7.81315 12.3101 8.1606 12.4334C8.50806 12.5567 8.86392 12.6183 9.22818 12.6183C9.53641 12.6183 9.78579 12.5847 9.97633 12.5175C10.1669 12.4502 10.307 12.3605 10.3966 12.2485C10.4863 12.1308 10.5311 11.9991 10.5311 11.8534C10.5311 11.674 10.4667 11.5311 10.3378 11.4247C10.2089 11.3126 10.0408 11.2257 9.83343 11.1641C9.63168 11.0968 9.40471 11.0352 9.15253 10.9791C8.90595 10.9231 8.65657 10.8586 8.40438 10.7858C8.1578 10.7073 7.93083 10.6093 7.72348 10.4916C7.52173 10.3683 7.35641 10.2058 7.22752 10.004C7.09862 9.80226 7.03417 9.54447 7.03417 9.23064C7.03417 8.91121 7.11824 8.61979 7.28636 8.3564C7.46009 8.0874 7.72068 7.87445 8.06813 7.71753C8.42119 7.55501 8.86672 7.47375 9.40471 7.47375C9.75777 7.47375 10.108 7.51858 10.4555 7.60825C10.8029 7.69792 11.1056 7.82681 11.3634 7.99493L11.0187 8.84396C10.7553 8.68704 10.4835 8.57216 10.2033 8.4993C9.9231 8.42085 9.6541 8.38162 9.39631 8.38162C9.09369 8.38162 8.84711 8.41804 8.65657 8.4909C8.47163 8.56375 8.33433 8.65902 8.24466 8.77671C8.1606 8.89439 8.11857 9.02889 8.11857 9.1802C8.11857 9.35954 8.18022 9.50524 8.30351 9.61733C8.4324 9.7238 8.59772 9.80787 8.79947 9.86951C9.00682 9.93116 9.23659 9.9928 9.48878 10.0544C9.74096 10.1105 9.99034 10.1749 10.2369 10.2478C10.4891 10.3206 10.7161 10.4159 10.9178 10.5336C11.1252 10.6513 11.2905 10.811 11.4138 11.0128C11.5427 11.2145 11.6071 11.4695 11.6071 11.7777C11.6071 12.0915 11.5203 12.383 11.3465 12.652C11.1784 12.9153 10.9178 13.1283 10.5648 13.2908C10.2117 13.4477 9.76618 13.5262 9.22818 13.5262Z"
          fill={strokeColor}
        />
      </svg>
    </Box>
  );
};

export default IconInvoiceInvoicing;
