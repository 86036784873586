import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const IconSneakerMoveLight = ({ size = 21, color = 'currentColor', style, ...restProps }) => {
  const theme = useTheme();

  const getColor = (colorName) => {
    const colorParts = colorName.split('.');
    let colorValue = theme.palette[colorParts[0]];
    for (let i = 1; i < colorParts.length; i++) {
      colorValue = colorValue[colorParts[i]];
    }
    return colorValue;
  };

  const strokeColor = color === 'currentColor' ? color : getColor(color);

  return (
    <Box sx={style} display="flex">
      <svg width={size} height={size} viewBox="0 0 256 256">
        <path
          d="M120.07,39.85a8,8,0,0,0-2.32-5.28l-8.23-8.23a8,8,0,0,0-11.17-.13l-64,64.13a8,8,0,0,0,0,11.32l107.32,104a8,8,0,0,0,5.65,2.34H240a8,8,0,0,0,8-8v-8a32,32,0,0,0-32-32h0a40,40,0,0,1-40-40V95.68a8,8,0,0,0-7.27-8C141.42,85.51,121.43,67.44,120.07,39.85Z"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
        <line
          x1="152"
          y1="120"
          x2="176"
          y2="120"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
        <line
          x1="32"
          y1="176"
          x2="64"
          y2="176"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
        <line
          x1="48"
          y1="208"
          x2="96"
          y2="208"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
        <line
          x1="160"
          y1="152"
          x2="192"
          y2="152"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="12"
        />
      </svg>
    </Box>
  );
};

export default IconSneakerMoveLight;
