import PropTypes from 'prop-types';
// @mui
import { Popover } from '@mui/material';
//
import getPosition from './getPosition';
import { StyledArrow } from './styles';

// ----------------------------------------------------------------------

MenuPopover.propTypes = {
  arrow: PropTypes.oneOf([
    'top-left',
    'top-center',
    'top-right',
    'bottom-left',
    'bottom-center',
    'bottom-right',
    'left-top',
    'left-center',
    'left-bottom',
    'right-top',
    'right-center',
    'right-bottom',
  ]),
  children: PropTypes.node,
  disabledArrow: PropTypes.bool,
  open: PropTypes.instanceOf(Object),
  sx: PropTypes.instanceOf(Object),
};

MenuPopover.defaultProps = {
  arrow: 'top-right',
  children: null,
  disabledArrow: false,
  open: null,
  sx: {},
};

export default function MenuPopover({ open, children, arrow = 'top-right', disabledArrow, sx, ...other }) {
  const { style, anchorOrigin, transformOrigin } = getPosition(arrow);

  return (
    <Popover
      PaperProps={{
        sx: {
          p: 1,
          width: 'auto',
          overflow: 'inherit',
          ...style,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
            '& svg': { mr: 2, width: 20, height: 20, flexShrink: 0 },
          },
          ...sx,
        },
      }}
      anchorEl={open}
      anchorOrigin={anchorOrigin}
      open={Boolean(open)}
      transformOrigin={transformOrigin}
      {...other}
    >
      {!disabledArrow && <StyledArrow arrow={arrow} />}

      {children}
    </Popover>
  );
}
