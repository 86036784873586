import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  files: [],
  file: null,
};

const slice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Files
    getFilesSuccess(state, action) {
      state.isLoading = false;
      state.files = action.payload;
    },

    // GET File
    getFileSuccess(state, action) {
      state.isLoading = false;
      state.file = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getFolders() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/folders');
      dispatch(slice.actions.getFilesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function createFolder(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.post('/folders/register', {}, { params: data }).then((response) => {
        dispatch(slice.actions.getFilesSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function getFoldersById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/folders/${id}`);

      dispatch(slice.actions.getFileSuccess(response.data));
      //return response;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateFolders(data, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.put(`/folders/update/${id}`, {}, { params: data }).then((response) => {
        dispatch(slice.actions.getFileSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteFolders(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.delete(`/folders/delete/${id}`).then((response) => {
        dispatch(slice.actions.getFileSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function uploadMedia(data, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { file, file_name } = data;
      var formData = new FormData();
      formData.append('file', file);
      formData.append('file_name', file_name);

      const response = await axios.post(`/folders/${id}/media/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(slice.actions.getFileSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.message;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteMediaFromFolder(folder, media) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.delete(`/folders/${folder}/media/delete/${media}`).then((response) => {
        dispatch(slice.actions.getFileSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
