import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Badge, Avatar } from '@mui/material';

// ----------------------------------------------------------------------

const getCharAtName = (name) => name && name.charAt(0).toUpperCase();

// const getColorByName = (name) => {
//   if (['A', 'N', 'H', 'L', 'Q'].includes(getCharAtName(name))) return 'primary';
//   if (['F', 'G', 'T', 'I', 'J'].includes(getCharAtName(name))) return 'info';
//   if (['K', 'D', 'Y', 'B', 'O'].includes(getCharAtName(name))) return 'success';
//   if (['P', 'E', 'R', 'S', 'U'].includes(getCharAtName(name))) return 'warning';
//   if (['V', 'W', 'X', 'M', 'Z'].includes(getCharAtName(name))) return 'error';
//   return 'default';
// };

// ----------------------------------------------------------------------

const CustomAvatar = forwardRef(({ color, name = '', BadgeProps, children, sx, ...other }, ref) => {
  const theme = useTheme();

  const charAtName = getCharAtName(name);

  // const colorByName = getColorByName(name);

  // const colr = color || colorByName;
  const colr = color || 'secondary';

  const renderContent =
    colr === 'default' ? (
      <Avatar ref={ref} sx={sx} {...other}>
        {name ? charAtName : null}
        {children}
      </Avatar>
    ) : (
      <Avatar
        ref={ref}
        sx={{
          color: theme.palette[colr]?.contrastText,
          backgroundColor: theme.palette[colr]?.main,
          fontWeight: theme.typography.fontWeightMedium,
          ...sx,
        }}
        {...other}
      >
        {name ? charAtName : null}
        {children}
      </Avatar>
    );

  return BadgeProps ? (
    <Badge anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} overlap="circular" {...BadgeProps}>
      {renderContent}
    </Badge>
  ) : (
    renderContent
  );
});

CustomAvatar.propTypes = {
  BadgeProps: PropTypes.instanceOf(Object),
  children: PropTypes.node,
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'info', 'success', 'warning', 'error']),
  name: PropTypes.string,
  sx: PropTypes.instanceOf(Object),
};

CustomAvatar.defaultProps = {
  BadgeProps: null,
  children: null,
  color: null,
  name: '',
  sx: {},
};

export default CustomAvatar;
