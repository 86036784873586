// prettier-ignore
// Align by RegEx (extension): value|label|teamId|reportTypes
const REPORTS = [
  { value: 'beet-revenue',        label: 'Beet Revenue',        teamId: '', reportType : 'agency' },
  { value: 'team-golden-revenue', label: 'Team Golden Revenue', teamId: '5', reportType : 'team' },
  { value: 'team-ruby-queen',     label: 'Team Ruby Queen',     teamId: '6', reportType : 'team' },
  { value: 'team-wonder',         label: 'Team Wonder',         teamId: '7', reportType : 'team' },
  { value: 'team-avalanche',      label: 'Team Avalanche',      teamId: '9', reportType : 'team' },
];

// prettier-ignore
// Align by RegEx (extension): value|label|itemsName|reportTypes|chartType|exclude
const MENU_ITEMS = [
  { value: 'organisation', label: 'Total',            itemsName: 'Agency',       reportTypes: ['agency', 'team'], chartType: 'single',   exclude: [''] },
  { value: 'consulting',   label: 'Consulting',       itemsName: 'Agency',       reportTypes: ['agency', 'team'], chartType: 'single',   exclude: ['goalAmount', 'goal_amount'] },
  { value: 'client_team',  label: 'Teams',            itemsName: 'Team',         reportTypes: ['agency'],         chartType: 'multiple', exclude: [] },
  { value: 'channel_team', label: 'Channel Teams',    itemsName: 'Team',         reportTypes: ['agency'],         chartType: 'multiple', exclude: [] },
  { value: 'tools',        label: 'Tools & Licenses', itemsName: 'Tool/License', reportTypes: ['agency', 'team'], chartType: 'none',     exclude: ['goalAmount', 'reported'] },
  { value: 'channel',      label: 'Channels',         itemsName: 'Channel',      reportTypes: ['agency', 'team'], chartType: 'none',     exclude: ['goalAmount'] },
  { value: 'service',      label: 'Services',         itemsName: 'Service',      reportTypes: ['agency', 'team'], chartType: 'none',     exclude: ['goalAmount'] },
  { value: 'client',       label: 'Clients',          itemsName: 'Client',       reportTypes: ['agency', 'team'], chartType: 'none',     exclude: ['goalAmount'] },
  { value: 'consultant',   label: 'Consultants',      itemsName: 'Consultant',   reportTypes: ['agency', 'team'], chartType: 'none',     exclude: ['goalAmount'] },
];

const DEFAULT_CHART_OPTIONS = {
  dot: 'goal_amount',
  bar: 'billable_amount',
  line: 'potential_amount',
};

export { REPORTS, MENU_ITEMS, DEFAULT_CHART_OPTIONS };
