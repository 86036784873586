import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  services: [],
  service: null,
};

const slice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET SERVICE
    getServicesSuccess(state, action) {
      state.isLoading = false;
      state.services = action.payload;
    },

    // GET SERVICE
    getServiceSuccess(state, action) {
      state.isLoading = false;
      state.service = action.payload;
    },

    // DELETE SERVICE
    deleteServiceSuccess(state, action) {
      state.isLoading = false;
      state.service = state.services.filter((service) => service.id !== action.payload);
    },

    // Reset service data
    resetService(state) {
      state.service = null;
    },
    resetServices(state) {
      state.services = [];
    },
    resetServiceError(state) {
      state.error = null;
    },
  },
});

// Reset actions
export const { resetService, resetServices, resetServiceError } = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getServices() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/services');
      dispatch(slice.actions.getServicesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getServiceById(service_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/services/${service_id}`);
      dispatch(slice.actions.getServiceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createService(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.post('/services/register/', {}, { params: data }).then((response) => {
        dispatch(slice.actions.getServicesSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function updateService({ values, id }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.put(`/services/update/${id}`, {}, { params: values }).then((response) => {
        dispatch(slice.actions.getServiceSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteService(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/services/delete/${id}`);
      console.log('deleteService response:', response);
      dispatch(slice.actions.deleteServiceSuccess(id));
    } catch (error) {
      console.error('deleteService error:', error);
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function getChannelServices(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/channels/${id}/services`);
      dispatch(slice.actions.getServicesSuccess(response.data));
      dispatch(slice.actions.hasError(null));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getClientChannels(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/clients/${id}/channels`);
      dispatch(slice.actions.getChannelsSuccess(response.data));
      dispatch(slice.actions.hasError(null));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addClientToChannel(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios
        .post(`/channels/${data.channel_id}/attach/client/${data.client_id}`, {}, { params: data })
        .then((response) => {
          dispatch(slice.actions.getChannelsSuccess(response.data));
          dispatch(slice.actions.hasError(null));

          return response;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function updateClientToChannel(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios
        .put(`/channels/${data.channel_id}/client/${data.client_id}/update`, {}, { params: data })
        .then((response) => {
          dispatch(slice.actions.getChannelsSuccess(response.data));
          dispatch(slice.actions.hasError(null));

          return response;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function detachClientChannel(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios
        .post(`/channels/${data.channel_id}/detach/client/${data.client_id}`, {}, { params: data })
        .then((response) => {
          dispatch(slice.actions.getChannelsSuccess(response.data));
          dispatch(slice.actions.hasError(null));

          return response;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
