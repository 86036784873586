// src/redux/slices/report.js

import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  reports: [],
  _consultantReports: [],
  report: null,
};

const slice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CANCEL LOADING
    cancelLoading(state) {
      state.isLoading = false;
    },

    // GET PRODUCTS
    getReportSuccess(state, action) {
      state.isLoading = false;
      state.reports = action.payload;
    },

    // GET PRODUCTS
    getConsultantReportSuccess(state, action) {
      state.isLoading = false;
      state._consultantReports = action.payload;
    },

    getReportsSuccess(state, action) {
      state.isLoading = false;
      state.reports = [...state.reports, action.payload];
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getReports(period, consultant_status, cancelToken) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/reports', {
        params: {
          consultant_status: consultant_status,
          period: period,
        },
        cancelToken: cancelToken ? cancelToken.token : null,
      });
      dispatch(slice.actions.getReportSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.cancelLoading());
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getConsultantReports(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/consultants/${id}/reports`);
      dispatch(slice.actions.getConsultantReportSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createReport(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.post('/reports/register', {}, { params: data }).then((response) => {
        dispatch(slice.actions.getReportsSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function updateReports(data, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.put(`/reports/update/${id}`, {}, { params: data }).then((response) => {
        dispatch(slice.actions.getReportsSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteReports(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.delete(`/reports/delete/${id}`).then((response) => {
        dispatch(slice.actions.getReportSuccess(response.data));
        return response;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
