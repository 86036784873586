import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { isValidToken, setSession } from './utils';
import { getConsultantsTeams } from 'src/redux/slices/consultant';

// ----------------------------------------------------------------------
const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  userConsultantId: null,
  role: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
      userConsultantId: action.payload.user?.consultant?.id || null,
      role: action.payload.role,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
      userConsultantId: action.payload.user?.consultant?.id || null,
      role: action.payload.role,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
      userConsultantId: action.payload.user?.consultant?.id || null,
      role: action.payload.role,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
      userConsultantId: null,
      role: null,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

AuthProvider.defaultProps = {
  children: null,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

      const user = storageAvailable ? localStorage.getItem('user') : '';

      const role = storageAvailable ? localStorage.getItem('role') : '';

      if (accessToken && isValidToken(accessToken) && user && user !== 'undefined') {
        setSession(accessToken);

        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
            user: JSON.parse(user),
            role: JSON.parse(role),
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null,
            role: null,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
          role: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    const response = await axios.post('/auth/login', { email, password });
    const { token, user, role } = response.data.data;
    const accessToken = token;
    setSession(accessToken);

    // Add teams to user object and update user in local storage
    if (user && user.consultant && user.consultant.id) {
      try {
        const teamsResponse = await axios.get(`/consultants/${user.consultant.id}/teams`);
        const teams = teamsResponse?.data?.data;

        if (!teams) {
          console.error('Error fetching consultant teams:', teamsResponse);
          return;
        }

        const updatedUser = {
          ...user,
          consultant: {
            ...user.consultant,
            teams: teams,
          },
        };

        console.log('LOGIN Updated User:', updatedUser); // Debug: Check the updated user object

        localStorage.setItem('user', JSON.stringify(updatedUser));
        localStorage.setItem('role', JSON.stringify(role));

        dispatch({
          type: 'LOGIN',
          payload: {
            user: updatedUser,
          },
        });
      } catch (error) {
        console.error('Error fetching consultant teams:', error);
      }
    } else {
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('role', JSON.stringify(role));

      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });
    }
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName, password_confirmation) => {
    const response = await axios.post('/auth/register', {
      email,
      password,
      first_name: firstName,
      last_name: lastName,
      password_confirmation,
    });
    const { token, user, role } = response.data.data;
    // Fetch teams for the consultant and update user object
    if (user && user.consultant && user.consultant.id) {
      try {
        const teams = await getConsultantsTeams(user?.consultant?.id);
        user.consultant.teams = teams;
      } catch (error) {
        console.error('Error fetching consultant teams:', error);
      }
    }

    localStorage.setItem('user', JSON.stringify(user));

    localStorage.setItem('role', JSON.stringify(role));

    const accessToken = token;

    setSession(accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
        token,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      userConsultantId: state.userConsultantId,
      role: state.role,
      method: 'jwt',
      login,
      register,
      logout,
    }),
    [state.isAuthenticated, state.isInitialized, state.user, state.role, login, logout, register]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
