import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import { isValidToken } from 'src/auth/utils';
// utils
import axios from '../../../utils/axios';
import localStorageAvailable from '../../../utils/localStorageAvailable';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  currencies: null,
  currency: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      currencies: action.payload.currencies,
    };
  }
  if (action.type === 'GET_CURRENCIES') {
    return {
      ...state,
      currencies: action.payload.currencies,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const CurrencyContext = createContext(null);

// ----------------------------------------------------------------------

CurrencyProvider.propTypes = {
  children: PropTypes.node,
};

CurrencyProvider.defaultProps = {
  children: null,
};

export function CurrencyProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const currencies = storageAvailable ? localStorage.getItem('currencies') : '';
      const currency = storageAvailable ? localStorage.getItem('currency') : '';

      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

      const user = storageAvailable ? localStorage.getItem('user') : '';

      if (accessToken && isValidToken(accessToken) && !user && !currencies) {
        const response = await axios.get('/currencies');

        const currencies = response.data;
        localStorage.setItem('currencies', JSON.stringify(currencies));

        dispatch({
          type: 'GET_CURRENCIES',
          payload: {
            currencies,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            currencies: JSON.parse(currencies),
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          currencies: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // Get currencies
  const getCurrencies = useCallback(async () => {
    const response = await axios.get('/currencies');

    const currencies = response.data;
    localStorage.setItem('currencies', JSON.stringify(currencies));

    dispatch({
      type: 'GET_CURRENCIES',
      payload: {
        currencies,
      },
    });
  }, []);

  const getCurrency = useCallback(async (name) => {
    const response = await axios.get(`/currencies/${id}`);

    const currency = response.data;

    localStorage.setItem('currency', JSON.stringify(currency));
    dispatch({
      type: 'GET_CURRENCY',
      payload: {
        currency,
      },
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      currencies: state.currencies,
      currency: state.currency,
      getCurrencies,
      getCurrency,
    }),
    [state.isInitialized, state.currencies, state.currency, getCurrencies, getCurrency]
  );

  return <CurrencyContext.Provider value={memoizedValue}>{children}</CurrencyContext.Provider>;
}
