// ----------------------------------------------------------------------

export default function Link(theme) {
  return {
    MuiLink: {
      defaultProps: {
        underline: 'always',
      },
      styleOverrides: {
        root: {
          textDecoration: 'none',
          '&:not(.MuiLink-underlineNone)': {
            color: theme.palette.text.primary,
            textDecoration: 'none',
            paddingBottom: 1.5,
            fontStyle: 'italic',
            '&:not(.MuiLink-underlineHover)': {
              borderBottom: '0.5px solid ' + theme.palette.underline,
              paddingBottom: 1,
            },
            '&:hover, &.MuiLink-underlineHover:hover': {
              color: theme.palette.primary.darker,
              borderBottom: '1.5px solid ' + theme.palette.primary.dark,
              paddingBottom: 0,
              textDecoration: 'none',
            },
          },
        },
      },
    },
  };
}
