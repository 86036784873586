import { useDispatch, useSelector } from '../../redux/store';
import { getOrganisations } from '../../redux/slices/organisation';
import { useState, useEffect } from 'react';
const OrganisationsObj = () => {
  const dispatch = useDispatch();
  const { organisations } = useSelector((state) => state.organisation);
  const [selectList, setSelectData] = useState([]);
  useEffect(() => {
    dispatch(getOrganisations());
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      if (organisations.data?.length) {
        setSelectData(organisations.data);
      }
    }, 1000);
  }, [organisations]);

  return { selectList };
};

export default OrganisationsObj;
