import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Switch, FormControlLabel, FormHelperText } from '@mui/material';

// ----------------------------------------------------------------------

RHFSwitch.propTypes = {
  helperText: PropTypes.node,
  name: PropTypes.string,
};

RHFSwitch.defaultProps = {
  helperText: '',
  name: '',
};

export default function RHFSwitch({ name, helperText, color, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <div>
          <FormControlLabel control={<Switch {...field} checked={field.value} color={color} />} {...other} />

          {!!error || helperText ? (
            <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
          ) : null}
        </div>
      )}
    />
  );
}
