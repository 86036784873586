import PropTypes from 'prop-types';
//
import { StyledEditorToolbar } from './styles';

// ----------------------------------------------------------------------

const HEADINGS = [
  { display: 'Heading', value: '3' }, // h3
  // { display: 'Heading 2', value: '4' }, // h4
];

export const formats = [
  'align',
  'background',
  'blockquote',
  'bold',
  'bullet',
  'code',
  'code-block',
  'color',
  'direction',
  'font',
  'formula',
  'header',
  'image',
  'indent',
  'italic',
  'link',
  'list',
  'script',
  'size',
  'strike',
  'table',
  'underline',
  'video',
];

EditorToolbar.propTypes = {
  id: PropTypes.string,
  isSimple: PropTypes.bool,
};

EditorToolbar.defaultProps = {
  id: 'toolbar',
  isSimple: false,
};

export default function EditorToolbar({ id, isSimple, ...other }) {
  return (
    <StyledEditorToolbar {...other}>
      <div id={id}>
        <div className="ql-formats">
          <select className="ql-header" defaultValue="" variant="standard">
            {HEADINGS.map((heading) => (
              <option key={heading.value} value={heading.value}>
                {heading.display}
              </option>
            ))}
            <option value="">Normal</option>
          </select>
        </div>

        <div className="ql-formats">
          <button className="ql-bold" type="button" />
          <button className="ql-italic" type="button" />
          <button className="ql-underline" type="button" />
          <button className="ql-strike" type="button" />
        </div>

        {!isSimple && (
          <div className="ql-formats">
            <select className="ql-color" />
            <select className="ql-background" />
          </div>
        )}

        <div className="ql-formats">
          <button className="ql-list" type="button" value="ordered" />
          <button className="ql-list" type="button" value="bullet" />
          {!isSimple && <button className="ql-indent" type="button" value="-1" />}
          {!isSimple && <button className="ql-indent" type="button" value="+1" />}
        </div>

        {!isSimple && (
          <div className="ql-formats">
            <button className="ql-script" type="button" value="super" />
            <button className="ql-script" type="button" value="sub" />
          </div>
        )}

        {!isSimple && (
          <div className="ql-formats">
            <button className="ql-code-block" type="button" />
            <button className="ql-blockquote" type="button" />
          </div>
        )}

        {/* <div className="ql-formats">
          <button className="ql-direction" type="button" value="rtl" />
          <select className="ql-align" />
        </div> */}

        <div className="ql-formats">
          <button className="ql-link" type="button" />
          {/* <button className="ql-clean" type="button" /> */}
          {/* <button className="ql-image" type="button" />
          <button className="ql-video" type="button" /> */}
        </div>

        <div className="ql-formats">
          {!isSimple && <button className="ql-formula" type="button" />}
          <button className="ql-clean" type="button" />
        </div>
      </div>
    </StyledEditorToolbar>
  );
}
