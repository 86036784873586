import { useAuthContext } from 'src/auth/useAuthContext';

/**
 * Custom hook to check if the current user has one of the required roles.
 *
 * @param {string|string[]} requiredRoles - A role or list of roles to check against.
 * @returns {boolean} - True if the user has one of the required roles, false otherwise.
 */
export const useUserHaveRole = (requiredRoles) => {
  const { user } = useAuthContext();

  const rolesArray = typeof requiredRoles === 'string' ? [requiredRoles] : requiredRoles;
  if (!Array.isArray(rolesArray)) {
    console.warn('The "useHasRole" hook expects "requiredRoles" to be a string or an array.');
    return false;
  }

  const role = user?.role;
  return role && Array.isArray(role) && role.some((roleItem) => rolesArray.includes(roleItem));
};
