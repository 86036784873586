import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const IconInvoices = ({ size = 21, color = 'currentColor', style, ...restProps }) => {
  const theme = useTheme();

  const getColor = (colorName) => {
    const colorParts = colorName.split('.');
    let colorValue = theme.palette[colorParts[0]];
    for (let i = 1; i < colorParts.length; i++) {
      colorValue = colorValue[colorParts[i]];
    }
    return colorValue;
  };

  const strokeColor = color === 'currentColor' ? color : getColor(color);

  return (
    <Box sx={style} display="flex">
      <svg width={size} height={size} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clip-rule="evenodd"
          d="M17.5 14.5V4.5C17.5 3.96957 17.2893 3.46086 16.9142 3.08579C16.5391 2.71071 16.0304 2.5 15.5 2.5H7.5C6.96957 2.5 6.46086 2.71071 6.08579 3.08579C5.71071 3.46086 5.5 3.96957 5.5 4.5V14.5C5.5 15.0304 5.71071 15.5391 6.08579 15.9142C6.46086 16.2893 6.96957 16.5 7.5 16.5H15.5C16.0304 16.5 16.5391 16.2893 16.9142 15.9142C17.2893 15.5391 17.5 15.0304 17.5 14.5Z"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.305 4.93506L3.301 5.66506C2.80272 5.84655 2.39692 6.2185 2.17283 6.69913C1.94873 7.17976 1.9247 7.72971 2.106 8.22806L5.526 17.6251C5.61586 17.8719 5.75347 18.0986 5.93096 18.2923C6.10846 18.4859 6.32236 18.6427 6.56046 18.7537C6.79856 18.8647 7.05619 18.9276 7.31863 18.939C7.58107 18.9504 7.84318 18.91 8.09 18.8201L13.658 16.6221M14.5517 7.50006H14.7273M14.5517 9.50006H14.7273M14.5517 13.5654H14.7273M14.5517 11.5H14.7273M12.527 7.50006H12.6908M12.527 9.50006H12.6908M8.56307 13.5654H12.6908M8.56307 11.5L12.6908 11.5"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.07329 10.0849V4.00806H9.60339V10.0849H9.07329ZM9.27369 9.37377C8.92029 9.37377 8.58197 9.32421 8.25873 9.22508C7.9355 9.12164 7.67906 8.9902 7.48943 8.83073L7.78035 8.1778C7.96136 8.32002 8.18547 8.43854 8.45268 8.53336C8.71988 8.62817 8.99356 8.67558 9.27369 8.67558C9.51073 8.67558 9.70252 8.64972 9.84905 8.598C9.99559 8.54629 10.1033 8.47733 10.1723 8.39113C10.2412 8.30063 10.2757 8.19935 10.2757 8.08729C10.2757 7.94938 10.2262 7.83948 10.127 7.75759C10.0279 7.67139 9.89862 7.60459 9.73915 7.55718C9.584 7.50547 9.40945 7.45806 9.21551 7.41496C9.02588 7.37186 8.83409 7.3223 8.64015 7.26627C8.45052 7.20594 8.27597 7.13051 8.11651 7.04001C7.96136 6.94519 7.83422 6.82021 7.73509 6.66505C7.63597 6.5099 7.5864 6.31165 7.5864 6.0703C7.5864 5.82464 7.65105 5.60053 7.78035 5.39797C7.91395 5.1911 8.11436 5.02733 8.38156 4.90665C8.65308 4.78167 8.99571 4.71918 9.40945 4.71918C9.68097 4.71918 9.95033 4.75365 10.2175 4.82261C10.4848 4.89157 10.7175 4.99069 10.9157 5.11999L10.6507 5.77292C10.4481 5.65225 10.2391 5.5639 10.0236 5.50787C9.80811 5.44753 9.60124 5.41736 9.40299 5.41736C9.17026 5.41736 8.98063 5.44538 8.83409 5.50141C8.69187 5.55743 8.58628 5.6307 8.51732 5.72121C8.45268 5.81171 8.42035 5.91515 8.42035 6.03151C8.42035 6.16943 8.46776 6.28148 8.56258 6.36768C8.6617 6.44956 8.78884 6.51421 8.94399 6.56162C9.10346 6.60903 9.28016 6.65643 9.4741 6.70384C9.66804 6.74694 9.85983 6.7965 10.0495 6.85253C10.2434 6.90856 10.4179 6.98182 10.5731 7.07233C10.7326 7.16284 10.8597 7.28567 10.9545 7.44082C11.0536 7.59597 11.1032 7.79207 11.1032 8.02911C11.1032 8.27046 11.0364 8.49457 10.9028 8.70144C10.7735 8.904 10.5731 9.06777 10.3016 9.19276C10.0301 9.31343 9.68744 9.37377 9.27369 9.37377Z"
          fill={strokeColor}
        />
      </svg>
    </Box>
  );
};

export default IconInvoices;
