import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import { isValidToken } from 'src/auth/utils';
// utils
import axios from '../../../utils/axios';
import localStorageAvailable from '../../../utils/localStorageAvailable';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  license: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      license: action.payload.license,
    };
  }
  if (action.type === 'SET_LICENSE') {
    return {
      ...state,
      license: action.payload.license,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const LicenseContext = createContext(null);

// ----------------------------------------------------------------------

LicenseProvider.propTypes = {
  children: PropTypes.node,
};

LicenseProvider.defaultProps = {
  children: null,
};

export function LicenseProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const license = storageAvailable ? localStorage.getItem('license') : '';

      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

      const user = storageAvailable ? localStorage.getItem('user') : '';

      if (accessToken && isValidToken(accessToken) && !user && !license) {
        const response = await axios.get('/licenses');

        const license = response.data;

        localStorage.setItem('license', JSON.stringify(license));

        dispatch({
          type: 'SET_LICENSE',
          payload: {
            license,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            license: JSON.parse(license),
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          license: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const getLicenses = useCallback(async () => {
    const response = await axios.get('/licenses');

    const license = response.data;

    localStorage.setItem('license', JSON.stringify(license));

    dispatch({
      type: 'SET_LICENSE',
      payload: {
        license,
      },
    });
  }, []);

  const getLicense = useCallback(async (name) => {
    const response = await axios.get(`/licenses/${name}`);

    const license = response.data;

    localStorage.setItem('license', JSON.stringify(license));

    dispatch({
      type: 'SET_LICENSE',
      payload: {
        license,
      },
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      license: state.license,
      getLicenses,
      getLicense,
    }),
    [state.isInitialized, state.license, getLicenses, getLicense]
  );

  return <LicenseContext.Provider value={memoizedValue}>{children}</LicenseContext.Provider>;
}
